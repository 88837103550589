import React from 'react';
import PageHeader from '../InnerPageHeader';
import PageDetail from '../PageDetail';
import useStyles from './styles';
import { useNavigate } from 'react-router-dom'

const Header = (props) => {
  const classes = useStyles();
  let navigate = useNavigate();
  return (
    <div className={classes.headerContainer}>
      <PageDetail {...props}/>
      {/* <PageHeader hasBackBtn goBackClick={() => navigate(-1)} title={'Boost'} style={{position: 'absolute'}}/> */}
    </div>
  );
};

export default Header;
