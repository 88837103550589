import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import useStyles from './styles';
import useWindowDimensions from '../../../utils/windowDimention';
import Loader from "../../../Components/Common/Loader";
import InnerPageHeader from '../../../Components/InnerPageHeader';
import Placeholder from '../../../assets/images/common-images/user_placeholder.png';
import ReactTimeAgo from 'react-time-ago';
import {formatNumberWithComma, getPlaceHolder} from '../../../utils/utils';
// import Header from '../../../assets/images/header-images/Messenger_1.png';
import footerMessenger from '../../../assets/images/common-images/messenger.png';
import Done from '../../../assets/images/common-images/done.png'
import moment from 'moment';
import { Carousel } from 'react-responsive-carousel';
import leftArrow from '../../../assets/images/common-images/leftArrow.png';
import leftArrowDisabled from '../../../assets/images/common-images/leftArrow_disabled.png';
import rightArrow from '../../../assets/images/common-images/rightArrow.png';
import rightArrowDisabled from '../../../assets/images/common-images/rightArrow_disabled.png';

import {
  getPreviousReseta, getPreviousBought, getPreviousQuestionnaire, getPreviousTaken
} from "../../../services/ApiService";
import { ids, routes } from '../../../constants';
import { isMobile } from 'react-device-detect';

let firstClientX = null;
let firstClientY = null;
let clientX = null;
let clientY = null;

const Chart = ({ history}) => {
  const classes = useStyles();
  let navigate = useNavigate();
  const { height, width } = useWindowDimensions();
  const [selectedSlideBought, setSelectedSlideBought] = useState(0);
  const [selectedSlideTaken, setSelectedSlideTaken] = useState(0);
  const [selectedSlideQuestionnaire, setSelectedSlideQuestionnaire] = useState(0);
  const [loader, setLoader] = useState(false);
  const [resetaData, setResetaData] = useState([]);
  const [boughtData, setBoughtData] = useState([]);
  const [questionnaireData, setQuestionnaireData] = useState([]);
  const [takenData, setTakenData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const props = useLocation().state;

  const fetchData = async () => {
    try {
      setLoader(true);
      const response = await getPreviousReseta();
      // console.log(`response2=${JSON.stringify(response)}`)
      if (response) {
        setResetaData(response.data.data);
        setLoader(false);
      }
      setLoader(true);
      const response1 = await getPreviousBought();
      // console.log(`response2=${JSON.stringify(response)}`)
      if (response1) {
        setBoughtData(response1.data.data);
        setLoader(false);
      }
      setLoader(true);
      const response2 = await getPreviousQuestionnaire();
      // console.log(`response2=${JSON.stringify(response)}`)
      if (response2) {
        setQuestionnaireData(response2.data.data);
        setLoader(false);
      }
      setLoader(true);
      const response3 = await getPreviousTaken();
      // console.log(`response2=${JSON.stringify(response)}`)
      if (response3) {
        setTakenData(response3.data.data);
        setLoader(false);
      }
    } catch (e) {
      setLoader(false);
      console.log(e);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const disablePinchZoom = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault()
      }
    }
    document.addEventListener("touchmove", disablePinchZoom, { passive: false })
    return () => {
      document.removeEventListener("touchmove", disablePinchZoom)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('touchstart', touchStart);
    window.addEventListener('touchmove', preventTouch, { passive: false });
    return () => {
      window.removeEventListener('touchstart', touchStart);
      window.removeEventListener('touchmove', preventTouch, {
        passive: false,
      });
    };
  }, []);

  const touchStart = (e) => {
    firstClientX = e.touches[0].clientX;
    firstClientY = e.touches[0].clientY;
  };

  const preventTouch = (e) => {
    const minValue = 5; // threshold

    clientX = e.touches[0].clientX - firstClientX;
    clientY = e.touches[0].clientY - firstClientY;

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(clientX) > minValue) {
      e.preventDefault();
      e.returnValue = false;
      return false;
    }
  };

  const onLeftClick = (index) => {
    switch (index) {
      case 1:
        if (selectedSlideBought > 0) {
          setSelectedSlideBought(selectedSlideBought - 1);
        }
        break;
      case 2:
        if (selectedSlideTaken > 0) {
          setSelectedSlideTaken(selectedSlideTaken - 1);
        }
        break;
      case 3:
        if (selectedSlideQuestionnaire > 0) {
          setSelectedSlideQuestionnaire(selectedSlideQuestionnaire - 1);
        }
        break;
      default:
    }
  }

  const onRightClick = (index) => {
    switch (index) {
      case 1:
        if (selectedSlideBought < boughtData.length - 1) {
          setSelectedSlideBought(selectedSlideBought + 1);
        }
        break;
      case 2:
        if (selectedSlideTaken < takenData.length - 1) {
          setSelectedSlideTaken(selectedSlideTaken + 1);
        }
        break;  
      case 3:
        if (selectedSlideQuestionnaire < takenData.length - 1) {
          setSelectedSlideQuestionnaire(selectedSlideQuestionnaire + 1);
        }
        break;  
      default:
    }
  }

  const w = 370;
  const h = 480;
  
  return (
    <div className={classes.homeContainer}>
      {loader && <Loader />}
      <div className={classes.homeContainer}>
        <div style={{ width: '100%', alignItems: 'center', justifyContent: 'center', minWidth: 400, maxWidth: 400, marginTop: '40px' }}>
          <>
          <div style={{marginTop: 10, marginLeft: 0, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <div style={{textDecoration: 'none', flexDirection: 'column', width: w, height: h, justifyContent: 'center', alignItems: 'center'}} >
              <div
                style={{display: 'flex', paddingLeft: 15, flexDirection: 'column', width: w, backgroundColor: '#5aa3df70', borderRadius: 12, justifyContent: 'flex-start', alignItems: 'flex-start'}}
              >
                <div style={{marginTop: 10, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <p style={{color: '#00599d', fontWeight: '500', fontSize: 20}}>Patient Code: </p>
                  <p style={{fontWeight: '500', color: 'black', fontSize: 20, marginLeft: 5}}>{props.userData.displayName}</p>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <p style={{color: '#00599d', fontWeight: '500', fontSize: 20}}>Name: </p>
                  <p style={{fontWeight: '500', color: 'black', fontSize: 20, marginLeft: 5}}>{props.userData.firstname} {props.userData.lastname}</p>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <p style={{color: '#00599d', fontWeight: '500', fontSize: 20}}>Birth Date: </p>
                  <p style={{fontWeight: '500', color: 'black', fontSize: 20, marginLeft: 5}}>{moment(props.userData.birthDate).format("MMMM D, YYYY")}</p>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <p style={{color: '#00599d', fontWeight: '500', fontSize: 20}}>Address: </p>
                  <p style={{fontWeight: '500', color: 'black', fontSize: 20, marginLeft: 5}}>{props.userData.address}</p>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <p style={{color: '#00599d', fontWeight: '500', fontSize: 20}}>Contact #: </p>
                  <p style={{fontWeight: '500', color: 'black', fontSize: 20, marginLeft: 5}}>{props.userData.phoneNumber}</p>
                </div>
              </div>
              <p style={{textAlign: 'left', marginTop: 10, color: '#00599d', fontWeight: '500', fontSize: 20}}>Listahan ng mga Naunang Reseta:</p>
              {resetaData.map((reseta,index) => 
                <div key={index}
                  style={{display: 'flex', marginBottom: 10, paddingTop: 10, paddingLeft: 15, flexDirection: 'column', width: w, backgroundColor: '#B0E0E6', borderRadius: 12, justifyContent: 'flex-start', alignItems: 'flex-start'}}
                >
                  <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <p style={{color: '#00599d', fontWeight: '500', fontSize: 20}}>Doctor: </p>
                    <p style={{fontWeight: '500', color: 'black', fontSize: 18, marginLeft: 5}}>{reseta.doctorName}</p>
                  </div>
                  <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <p style={{color: '#00599d', fontWeight: '500', fontSize: 20}}>Clinic: </p>
                    <p style={{fontWeight: '500', color: 'black', fontSize: 18, marginLeft: 5}}>{reseta.clinic}</p>
                  </div>
                  <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <p style={{color: '#00599d', fontWeight: '500', fontSize: 20}}>Date: </p>
                    <p style={{fontWeight: '500', color: 'black', fontSize: 18, marginLeft: 5}}>{moment(reseta.prescriptionDate).format("MMMM D, YYYY")}</p>
                  </div>
                  <p style={{color: '#00599d', fontWeight: '500', fontSize: 20}}>Mga Gamot: </p>
                  {reseta.details.map((item, index) => {
                    return <p key={item.id} style={{textAlign: 'left', fontWeight: '500', color: 'black', fontSize: 18, marginLeft: 10}}>{item.generic.name} {item.dosage.name} ({item.umaga}-{item.tanghali}-{item.gabi})</p>
                  })}
                </div>
              )}
              <p style={{textAlign: 'left', marginTop: 10, color: '#00599d', fontWeight: '500', fontSize: 20}}>Listahan ng mga Naunang Nabili:</p>
              <>
              <Carousel
                preventMovementUntilSwipeScrollTolerance
                transitionTime={100}
                emulateTouch={true}
                showArrows={false}
                showThumbs={false}
                showStatus={false}
                infiniteLoop={false}
                axis={"horizontal"}
                showIndicators={false}
                dynamicHeight={true}
                selectedItem={selectedSlideBought}
                onChange={(item) => {
                  setSelectedSlideBought(item);
                }}
              >
                {boughtData.map((bought,index) => 
                  <div key={index}
                    style={{display: 'flex', marginBottom: 10, paddingTop: 10, paddingLeft: 15, flexDirection: 'column', width: w, backgroundColor: '#B0E0E6', borderRadius: 12, justifyContent: 'flex-start', alignItems: 'flex-start'}}
                  >
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                      <p style={{color: '#00599d', fontWeight: '500', fontSize: 20}}>Team Leader: </p>
                      <p style={{fontWeight: '500', color: 'black', fontSize: 18, marginLeft: 5}}>{bought.teamLeaderName}</p>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                      <p style={{color: '#00599d', fontWeight: '500', fontSize: 20}}>Date: </p>
                      <p style={{fontWeight: '500', color: 'black', fontSize: 18, marginLeft: 5}}>{bought.deliveryDate}</p>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                      <p style={{color: '#00599d', fontWeight: '500', fontSize: 20}}>Total: </p>
                      <p style={{fontWeight: '500', color: 'black', fontSize: 18, marginLeft: 5}}>₱{formatNumberWithComma(bought.total, 2)}</p>
                    </div>
                    <p style={{color: '#00599d', fontWeight: '500', fontSize: 20}}>Mga Gamot: </p>
                    {bought.details.map((item, index) => {
                      return <p key={item.id} style={{textAlign: 'left', fontWeight: '500', color: 'black', fontSize: 17, marginLeft: 10}}>{(item.umaga+item.tanghali+item.gabi) * item.days}pcs. {item.generic.name} {item.dosage.name}, ₱{formatNumberWithComma(item.brand.price, 2)} bawat isa</p>
                    })}
                  </div>
                )}
              </Carousel>
              {boughtData.length > 0 && <div className={classes.arrowContainer}>
                <img src={selectedSlideBought == 0 ? leftArrowDisabled : leftArrow} className={classes.arrow} style={{position: 'absolute', left: 0}} onClick={() => onLeftClick(1)}/>
                <img src={selectedSlideBought == (boughtData.length - 1) ? rightArrowDisabled : rightArrow} className={classes.arrow} style={{position: 'absolute', right: 0}} onClick={() => onRightClick(1)}/>
              </div>}
              </>
              <p style={{textAlign: 'left', marginTop: 10, color: '#00599d', fontWeight: '500', fontSize: 20}}>Listahan ng mga Naunang Nainom:</p>
              <>
              <Carousel
                preventMovementUntilSwipeScrollTolerance
                transitionTime={100}
                emulateTouch={true}
                showArrows={false}
                showThumbs={false}
                showStatus={false}
                infiniteLoop={false}
                axis={"horizontal"}
                showIndicators={false}
                dynamicHeight={true}
                selectedItem={selectedSlideTaken}
                onChange={(item) => {
                  setSelectedSlideTaken(item);
                }}
              >
                {takenData.map((taken,index) => 
                  <div key={index}
                    style={{display: 'flex', marginBottom: 10, paddingTop: 10, paddingLeft: 15, flexDirection: 'column', width: w, backgroundColor: '#B0E0E6', borderRadius: 12, justifyContent: 'flex-start', alignItems: 'flex-start'}}
                  >
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                      <p style={{color: '#00599d', fontWeight: '500', fontSize: 20}}>Date: </p>
                      <p style={{fontWeight: '500', color: 'black', fontSize: 18, marginLeft: 5}}>{moment(taken.takenDate).format("MMMM D, YYYY")}</p>
                    </div>
                    <p style={{color: '#00599d', fontWeight: '500', fontSize: 20}}>Mga Gamot: </p>
                    {taken.details.map((item, index) => {
                      return <p key={item.id} style={{textAlign: 'left', fontWeight: '500', color: 'black', fontSize: 17, marginLeft: 10}}>{item.generic.name} {item.dosage.name} ({item.umaga}-{item.tanghali}-{item.gabi})</p>
                    })}
                  </div>
                )}
              </Carousel>
              {takenData.length > 0 && <div className={classes.arrowContainer}>
                <img src={selectedSlideTaken == 0 ? leftArrowDisabled : leftArrow} className={classes.arrow} style={{position: 'absolute', left: 0}} onClick={() => onLeftClick(2)}/>
                <img src={selectedSlideTaken == (takenData.length - 1) ? rightArrowDisabled : rightArrow} className={classes.arrow} style={{position: 'absolute', right: 0}} onClick={() => onRightClick(2)}/>
              </div>}
              </>
              <p style={{textAlign: 'left', marginTop: 10, color: '#00599d', fontWeight: '500', fontSize: 20}}>Listahan ng mga Naunang Nasagutan:</p>
              <>
              <Carousel
                preventMovementUntilSwipeScrollTolerance
                transitionTime={100}
                emulateTouch={true}
                showArrows={false}
                showThumbs={false}
                showStatus={false}
                infiniteLoop={false}
                axis={"horizontal"}
                showIndicators={false}
                dynamicHeight={true}
                selectedItem={selectedSlideQuestionnaire}
                onChange={(item) => {
                  setSelectedSlideQuestionnaire(item);
                }}
              >
                {questionnaireData.map((questionnaire,index) => 
                  <div key={index}
                    style={{display: 'flex', marginBottom: 10, paddingTop: 10, paddingLeft: 15, flexDirection: 'column', width: w, backgroundColor: '#B0E0E6', borderRadius: 12, justifyContent: 'flex-start', alignItems: 'flex-start'}}
                  >
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                      <p style={{color: '#00599d', fontWeight: '500', fontSize: 20}}>Date: </p>
                      <p style={{fontWeight: '500', color: 'black', fontSize: 18, marginLeft: 5}}>{moment(questionnaire.createDate).format("MMMM D, YYYY")}</p>
                    </div>
                    <p style={{color: '#00599d', fontWeight: '500', fontSize: 20}}>Mga Katanungan: </p>
                    {questionnaire.details.map((item, index) => {
                      return <p key={item.id} style={{textAlign: 'left', fontWeight: '500', color: 'black', fontSize: 16, marginLeft: 10, marginRight: 10}}>{item.question.sortOrder}. {item.question.name} - {item.answer.name}</p>
                    })}
                  </div>
                )}
              </Carousel>
              {questionnaireData.length > 0 && <div className={classes.arrowContainer}>
                <img src={selectedSlideQuestionnaire == 0 ? leftArrowDisabled : leftArrow} className={classes.arrow} style={{position: 'absolute', left: 0}} onClick={() => onLeftClick(3)}/>
                <img src={selectedSlideQuestionnaire == (questionnaireData.length - 1) ? rightArrowDisabled : rightArrow} className={classes.arrow} style={{position: 'absolute', right: 0}} onClick={() => onRightClick(3)}/>
              </div>}
              </>
              <div
                  style={{display: 'flex', height: 50, backgroundColor: 'transparent'}}
              />
            </div>
          </div>
          </>
        </div>
        <div style={{position: 'absolute', top: 0, paddingLeft: 0, paddingRight: 0, backgroundColor: 'black', alignItems: 'center', width: '100%'}}>
          <InnerPageHeader hasBackBtn goBackClick={() => navigate(-1)} title="PATIENT CHART" style={{position: 'absolute'}}/>
        </div>
      </div>
    </div>
  );
};

export default Chart;
