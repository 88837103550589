import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import useStyles from './styles';
import useWindowDimensions from '../../../../utils/windowDimention';
import Loader from "../../../../Components/Common/Loader";
import InnerPageHeader from '../../../../Components/InnerPageHeader';
import Placeholder from '../../../../assets/images/common-images/user_placeholder.png';
import ReactTimeAgo from 'react-time-ago';
import {getPlaceHolder} from '../../../../utils/utils';
// import Upload from '../../../../assets/images/header-images/0.png';
import innerTranscribed from '../../../../assets/images/common-images/transcribedStamp.png';
import Done from '../../../../assets/images/common-images/done.png'
import { Carousel } from "react-responsive-carousel";
import { createWorker } from "tesseract.js";

import {
  getTLReceipt, uploadMedia, uploadTLReceipt
} from "../../../../services/ApiService";
import { ids, routes } from '../../../../constants';

let firstClientX = null;
let firstClientY = null;
let clientX = null;
let clientY = null;

const TLDelivery = ({ history}) => {
  const classes = useStyles();
  let navigate = useNavigate();
  const { height, width } = useWindowDimensions();
  const [selectedSlide, setSelectedSlide] = useState(0);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [hideGo, setHideGo] = useState(true);
  const [selectedId, setSelectedId] = useState();
  const props = useLocation().state;
  const hiddenFileInput = React.useRef(null);

  const [ocr, setOcr] = useState("");
  const [imageData, setImageData] = useState(null);
  const [progress, setProgress] = useState(0);

  const worker = createWorker({
    logger: (m) => {
      console.log(m);
      setProgress(parseInt(m.progress * 100));
    },
  });
  const convertImageToText = async () => {
    if (!imageData) return;
    await worker.load();
    await worker.loadLanguage("eng");
    await worker.initialize("eng");
    const {
      data: { text },
    } = await worker.recognize(imageData);
    setOcr(text);
  };

  useEffect(() => {
    convertImageToText();
  }, [imageData]);

  const fetchData = async () => {
    try {
      setLoader(true);
      const response = await getTLReceipt({notificationId: props.notificationId});
      if (response) {
        setData(response.data);
        setIsLoaded(true);
        setLoader(false);
      }

      // console.log("response", response);
    } catch (e) {
      setIsLoaded(true);
      setLoader(false);
      console.log(e);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const disablePinchZoom = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault()
      }
    }
    document.addEventListener("touchmove", disablePinchZoom, { passive: false })
    return () => {
      document.removeEventListener("touchmove", disablePinchZoom)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('touchstart', touchStart);
    window.addEventListener('touchmove', preventTouch, { passive: false });
    return () => {
      window.removeEventListener('touchstart', touchStart);
      window.removeEventListener('touchmove', preventTouch, {
        passive: false,
      });
    };
  }, []);

  const touchStart = (e) => {
    firstClientX = e.touches[0].clientX;
    firstClientY = e.touches[0].clientY;
  };

  const preventTouch = (e) => {
    const minValue = 5; // threshold

    clientX = e.touches[0].clientX - firstClientX;
    clientY = e.touches[0].clientY - firstClientY;

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(clientX) > minValue) {
      e.preventDefault();
      e.returnValue = false;
      return false;
    }
  };

  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file 
  const handleChange = e => {
    setOcr("");
    setProgress(0);
    const file = e.target.files[0];
    if(!file)return;
    const reader = new FileReader();
    reader.onloadend = () => {
      const imageDataUri = reader.result;
      console.log({ imageDataUri });
      setImageData(imageDataUri);
    };
    reader.readAsDataURL(file);
  };

  const handleClick = event => {
    hiddenFileInput.current.click();
  };
  
  return (
    <div className={classes.homeContainer}>
      <div className={classes.homeContainer}>
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', justifyContent: 'center', minWidth: 400, maxWidth: 400, marginTop: '40px' }}>
        <div style={{ display: 'flex', backgroundColor: 'transparent', top: 40, alignItems: 'center', flexDirection: 'column', height: 650 }}>
          <div style={{ display: "flex", backgroundColor: 'transparent', top: 30, alignItems: 'center', flexDirection: 'column' }}>
            <img src='https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1659946555326.png' style={{width: 375, height: 157, resizeMode: 'contain'}} />
          </div>
          <div style={{width: 300, height: 300, cursor: 'pointer', marginTop: 20}} onClick={handleClick}>
            <img src={!imageData ? 'https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1659949915337.png' : imageData} style={{width: 300, height: 300, resizeMode: 'contain'}} />
          </div>
          {progress < 100 && progress > 0 && <div>
              <div className={classes.progressLabel}>Progress ({progress}%)</div>
              <div className={classes.progressBar}>
                <div className={classes.progress} style={{width: `${progress}%`}} ></div>
              </div>
            </div>
          }
          <div style={{width: 300, height: 300, marginTop: 20}} onClick={handleClick}>
            {ocr}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={handleChange}
              style={{display: 'none'}} // Make the file input element invisible 
              accept="image/*"
            />
          </div>
        </div>
        <div style={{position: 'fixed', top: 0, paddingLeft: 0, paddingRight: 0, backgroundColor: 'black', alignItems: 'center', minWidth: 400}}>
          <InnerPageHeader hasBackBtn goBackClick={() => navigate(-1)} title="" style={{position: 'absolute'}}/>
        </div>
      </div>
    </div>
  </div> 
  );
};

export default TLDelivery;