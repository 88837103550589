import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import useStyles from './styles';
import useWindowDimensions from '../../../utils/windowDimention';
import Loader from "../../../Components/Common/Loader";
import InnerPageHeader from '../../../Components/InnerPageHeader';
import Placeholder from '../../../assets/images/common-images/user_placeholder.png';
import ReactTimeAgo from 'react-time-ago';
import {getPlaceHolder} from '../../../utils/utils';
// import Header from '../../../assets/images/header-images/Reseta_1.png';
// import innerHeaderUpload from '../../../assets/images/header-images/3_4.png';
// import Upload from '../../../assets/images/header-images/0.png';
import innerTranscribed from '../../../assets/images/common-images/transcribedStamp.png';
import Done from '../../../assets/images/common-images/done.png'
import { Carousel } from "react-responsive-carousel";

import {
  getResetaImages, uploadMedia, uploadResetaImage
} from "../../../services/ApiService";
import { ids, routes } from '../../../constants';

let firstClientX = null;
let firstClientY = null;
let clientX = null;
let clientY = null;

const Reseta = ({ history}) => {
  const classes = useStyles();
  let navigate = useNavigate();
  const { height, width } = useWindowDimensions();
  const [selectedSlide, setSelectedSlide] = useState(0);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [hideGo, setHideGo] = useState(true);
  const [selectedId, setSelectedId] = useState();
  const props = useLocation().state;
  const hiddenFileInput = React.useRef(null);

  const fetchData = async () => {
    try {
      setLoader(true);
      const response = await getResetaImages();
      if (response) {
        setData(response.data);
        setIsLoaded(true);
        setLoader(false);
      }

      // console.log("response", response);
    } catch (e) {
      setIsLoaded(true);
      setLoader(false);
      console.log(e);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const disablePinchZoom = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault()
      }
    }
    document.addEventListener("touchmove", disablePinchZoom, { passive: false })
    return () => {
      document.removeEventListener("touchmove", disablePinchZoom)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('touchstart', touchStart);
    window.addEventListener('touchmove', preventTouch, { passive: false });
    return () => {
      window.removeEventListener('touchstart', touchStart);
      window.removeEventListener('touchmove', preventTouch, {
        passive: false,
      });
    };
  }, []);

  const touchStart = (e) => {
    firstClientX = e.touches[0].clientX;
    firstClientY = e.touches[0].clientY;
  };

  const preventTouch = (e) => {
    const minValue = 5; // threshold

    clientX = e.touches[0].clientX - firstClientX;
    clientY = e.touches[0].clientY - firstClientY;

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(clientX) > minValue) {
      e.preventDefault();
      e.returnValue = false;
      return false;
    }
  };

  const onCheckPress = (rowData,answerId) => {
    if (rowData.isAnswered !== 1) {
      let tempData = [...data
      .map(item => {
        if (item.id == rowData.id) {
          return {
            ...item,
            answerId
          }
        }
        else {
          return item
        }
      })];
      setData([...tempData]);
    }
  }

  const handleFile = async (file) => {
    const formData = new FormData();
    if (!file) {
      window.alert(`!File`);
      return;
    }
    formData.append("file", file);

    const response = await uploadMedia(formData);
    // console.log("response", JSON.stringify(response));
    try {
      const upload = await uploadResetaImage({imageBig: response.data.data[0].imageUrlBigUrl})
      if (upload) {
        // console.log(`upload.response=${JSON.stringify(upload)}`)
        await fetchData();
      }
    } catch (e) {
      console.log(e);
    }
  }

  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file 
  const handleChange = event => {
    const fileUploaded = event.target.files[0];
    const mArray = fileUploaded.name.split(".");
    const fileExtension = mArray[mArray.length - 1];
    const allowedFileTypes = ["jpg", "png", "jpeg"];
    if (!allowedFileTypes.includes(fileExtension)) {
        window.alert(`File type not supported.`);
    }
    else {
      handleFile(fileUploaded);
    }
  };

  const handleClick = event => {
    hiddenFileInput.current.click();
  };
  
  return (
    (data.length == 0 && isLoaded) ? <div className={classes.homeContainer}>
      <div className={classes.homeContainer}>
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', justifyContent: 'center', minWidth: 400, maxWidth: 400, marginTop: '40px' }}>
        <div style={{ display: 'flex', backgroundColor: 'transparent', top: 40, alignItems: 'center', flexDirection: 'column', height: 650 }}>
          <div style={{ display: "flex", backgroundColor: 'transparent', top: 30, alignItems: 'center', flexDirection: 'column' }}>
            <img src='https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1659950056967.png' style={{width: 375, height: 157, resizeMode: 'contain'}} />
          </div>
          <div style={{width: 300, height: 300, cursor: 'pointer', marginTop: 20}} onClick={handleClick}>
            <img src='https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1659949915337.png' style={{width: 300, height: 300, resizeMode: 'contain'}} />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={handleChange}
              style={{display: 'none'}} // Make the file input element invisible 
            />
          </div>
        </div>
        <div style={{position: 'fixed', top: 0, paddingLeft: 0, paddingRight: 0, backgroundColor: 'black', alignItems: 'center', minWidth: 400}}>
          <InnerPageHeader hasBackBtn goBackClick={() => navigate(-1)} title="" style={{position: 'absolute'}}/>
        </div>
      </div>
    </div>
  </div> 
  :
    <div className={classes.homeContainer}>
      {loader && <Loader />}
      <div className={classes.homeContainer}>
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', justifyContent: 'center', minWidth: 400, maxWidth: 400, marginTop: '40px' }}>
          
          <>
          <div style={{ display: "flex", backgroundColor: 'transparent', top: 30, alignItems: 'center', flexDirection: 'column' }}>
            <img src='https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1659949682790.png' style={{width: 375, height: 157, resizeMode: 'contain'}} />
          </div>
          <Carousel
            preventMovementUntilSwipeScrollTolerance
            transitionTime={100}
            emulateTouch={true}
            showArrows={false}
            showThumbs={false}
            showStatus={false}
            infiniteLoop={false}
            axis={"vertical"}
            showIndicators={false}
            dynamicHeight={true}
            selectedItem={selectedSlide}
            onChange={(item) => {
              setSelectedSlide(item);
            }}
          >
            {data &&
              data.map((item, index) => (
                <div
                  key={index}
                  style={{ display: 'block', width: '100vw', height: '60vh', maxWidth: 400 }}
                >
                  <img
                    style={{ width: '100vw', height: '60vh', objectFit: 'contain', maxWidth: 400 }}
                    src={item.image_big}
                  />
                  {item.is_transcribed == 1 && <img src={innerTranscribed} style={{zIndex: 1, position: 'absolute', left: 0, width: '60vh', height: '60vh', objectFit: 'contain', maxWidth: 400}} />}
              </div>
            ))}
          </Carousel>
          <div className={classes.sliderIndicatorContainer}>
            {data && data.length > 1 &&
              data.map((slide, index) => (
                <div
                  key={`item${index}`}
                  style={{
                    width: selectedSlide == index ? 10 : 7,
                    height: selectedSlide == index ? 10 : 7,
                    background: selectedSlide == index ? "#00599d" : "transparent",
                  }}
                  className={classes.sliderIndicator}
                  onClick={() => setSelectedSlide(index)}
                />
              ))}
          </div>
          </>
        </div>
        <input
          type="file"
          ref={hiddenFileInput}
          onChange={handleChange}
          style={{display: 'none'}} // Make the file input element invisible 
        />
        <div style={{position: 'absolute', top: 0, paddingLeft: 0, paddingRight: 0, backgroundColor: 'black', alignItems: 'center', width: '100%'}}>
          <InnerPageHeader hasBackBtn goBackClick={() => navigate(-1)} title="" style={{position: 'absolute'}}
            rightButtonLabel="UPLOAD"
            onRightPress={() => hiddenFileInput.current.click()}
          />
        </div>
      </div>
    </div>
  );
};

export default Reseta;