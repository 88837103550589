import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import useStyles from './styles';
import useWindowDimensions from '../../../utils/windowDimention';
import Loader from "../../../Components/Common/Loader";
import InnerPageHeader from '../../../Components/InnerPageHeader';
import Placeholder from '../../../assets/images/common-images/user_placeholder.png';
import ReactTimeAgo from 'react-time-ago';
import {getPlaceHolder} from '../../../utils/utils';
// import Header from '../../../assets/images/header-images/Save_1.png';
// import Header2 from '../../../assets/images/header-images/Save_2.png';
import innerGo from '../../../assets/images/common-images/go.png';
import Kailan from '../../../assets/images/common-images/kailan.png';
import Okay from '../../../assets/images/common-images/okay.png'
import CheckBoxCheck from '../../../assets/images/common-images/checkBoxCheck.png';
import {formatNumberWithComma} from '../../../utils/utils';
import Moment from 'moment';

import {
  getSavings, getReseta, uploadBought, postNotification
} from "../../../services/ApiService";
import { ids, routes } from '../../../constants';
import { isMobile } from 'react-device-detect';

let firstClientX = null;
let firstClientY = null;
let clientX = null;
let clientY = null;

const Bumili = ({ history}) => {
  const classes = useStyles();
  let navigate = useNavigate();
  const { height, width } = useWindowDimensions();
  const [selectedSlide, setSelectedSlide] = useState(0);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [reseta, setReseta] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [dayId, setDayId] = useState(7);
  const [level, setLevel] = useState(0);
  const props = useLocation().state;
  const [tabArr, setTabArr] = useState([
    { tabId: 7, tabName: '7 days', isSelect: true },
    { tabId: 14, tabName: '14 days', isSelect: false },
    { tabId: 28, tabName: '28 days', isSelect: false },
  ])

  const fetchData = async () => {
    try {
      setLoader(true);
      const response = await getSavings();
      // console.log(`response2=${JSON.stringify(response)}`)
      if (response) {
        setData(response.data.data);
      }

      const response1 = await getReseta();
      // console.log(`response1=${JSON.stringify(response1)}`)
      if (response1) {
        setReseta(response1.data.data);
        setIsLoaded(true);
        setLoader(false);
      }

      // console.log("response", response);
    } catch (e) {
      setIsLoaded(true);
      setLoader(false);
      console.log(e);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const disablePinchZoom = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault()
      }
    }
    document.addEventListener("touchmove", disablePinchZoom, { passive: false })
    return () => {
      document.removeEventListener("touchmove", disablePinchZoom)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('touchstart', touchStart);
    window.addEventListener('touchmove', preventTouch, { passive: false });
    return () => {
      window.removeEventListener('touchstart', touchStart);
      window.removeEventListener('touchmove', preventTouch, {
        passive: false,
      });
    };
  }, []);

  const touchStart = (e) => {
    firstClientX = e.touches[0].clientX;
    firstClientY = e.touches[0].clientY;
  };

  const preventTouch = (e) => {
    const minValue = 5; // threshold

    clientX = e.touches[0].clientX - firstClientX;
    clientY = e.touches[0].clientY - firstClientY;

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(clientX) > minValue) {
      e.preventDefault();
      e.returnValue = false;
      return false;
    }
  };

  const tabAction = (tabId) => {
    let tempData = [...tabArr
    .map(item => {
      if (item.tabId == tabId) {
        return {
          ...item,
          isSelect: true
        }
      }
      else {
        return {
          ...item,
          isSelect: false
        }
      }
    })];
    setTabArr([...tempData]);
    setDayId(tabId);
  }

  const computePrice = (rowData) => {
    const qty = rowData.umaga + rowData.tanghali + rowData.gabi;
    const price = rowData.brand.price;
    // console.log(`${rowData.brand.name} qty=${qty}; price=${price}; subtotal=${dayId * qty * price}`)
    return dayId * qty * price;
  }

  const computeTotal = () => {
    let total = 0;
    data.map(item => {
      total = total + computePrice(item)
    })
    return total;
  }

  const computeDati = () => {
    let total = 0;
    reseta.map(item => {
      total = total + computePrice(item)
    })
    return total;
  }

  const totalSavings = () => {
    const dati = computeDati();
    const ngayon = computeTotal();
    return dati - ngayon;
  }

  const computeSavings = () => {
    const dati = computeDati();
    const ngayon = computeTotal();
    const savings = (dati - ngayon) * 100 / dati;
    if (savings >= 50) {
      return '50%';
    }
    else if (savings >= 30) {
      return '30%'
    }
    else if (savings >= 20) {
      return '20%'
    }
    else if (savings >= 10) {
      return '10%'
    }
    else {
      return ''
    }
  }

  const nextWeek = () => {
    var today = new Date();
    var nextweek = new Date(today.getFullYear(), today.getMonth(), today.getDate()+7-today.getDay());
    return Moment(nextweek).format('MMM. DD, YYYY');
  }

  const onDoneClick = () => {
    const total = computeTotal();
    const param = {
      paymentOptionId: 40, //COD
      locationId: data[0].locationId,
      total,
      teamLeaderId: data[0].teamLeaderId,
      teamLeaderName: `${data[0].teamLeaderFirstname} ${data[0].teamLeaderLastname}`,
      teamLeaderAddress: data[0].teamLeaderAddress,
      teamLeaderPhoneNumber: data[0].teamLeaderPhoneNumber,
      deliveryDate: `${nextWeek()} - 10:00AM`,
      sponsorId: data[0].sponsorId,
      sponsor: data[0].sponsorDisplayName,
      details: data.map((item) => {
        return {
          savingsId: item.id,
          genericsId: item.generic.id,
          brandedId: item.brand.id,
          dosageId: item.dosage.id,
          discountId: item.brand.discountId,
          umaga: item.umaga,
          tanghali: item.tanghali,
          gabi: item.gabi,
          remarks: item.remarks,
          quantity: (item.umaga + item.tanghali + item.gabi) * dayId,
          price: item.brand.price,
          days: dayId
        }
      })
    }

    uploadBought(param)
    .then((data) => {
    })
    .catch(e => {
        console.warn('upload bought ERROR', e.message);
    });

    const notifParam = {
      "destinationUserId": props.userData.id,
      "notificationTypeId": ids.notificationTypeBoughtFromStore,
      "textFormat": `TANDAAN: Ihahatid kay Team Leader %NAME% ang mga order mong gamot sa %DATE%.`,
      "textValues": { "DATE": nextWeek(), "NAME": `${data[0].teamLeaderFirstname} ${data[0].teamLeaderLastname}`},
      "expiryDate": Moment(new Date()).add(1, 'w')
    }
    // console.log(`notifParam=${JSON.stringify(notifParam)}`)
    postNotification(notifParam)
    .then(({data}) => {
      
    })
    .catch(e => {
      console.warn('post notification ERROR', e.message);
    });

    navigate(-1);
  }

  const w = 370;
  const h = data.length <= 3 ? 320 : 370;

  return (
    (typeof props.dashData !== 'undefined' && props.dashData.length > 0 && props.dashData[0].saved == 1) ? 
      <div className={classes.homeContainer}>
        {loader && <Loader />}
        {level == 0 && 
          <div className={classes.homeContainer}>
            <div style={{ width: '100%', alignItems: 'center', justifyContent: 'center', minWidth: 400, maxWidth: 400, marginTop: '50px' }}>
              <>
              <div style={{ display: "flex", backgroundColor: 'transparent', top: 30, alignItems: 'center', flexDirection: 'column' }}>
                <img src='https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1660890730684.png' style={{width: 375, height: 93, resizeMode: 'contain'}} />
                <div style={{display: "flex", alignItems: 'flex-start', flexDirection:'row',width:'100%', borderRadius: 3, 
                  backgroundColor: 'transparent', height: 40, marginTop: 15, zIndex: 100}}>
                  {
                      tabArr.map((item,index)=> {
                        return <div key={index} style={{cursor: 'pointer', display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '33%'}} onClick={() => tabAction(item.tabId)} >
                          <div style={{display: 'flex', flexDirection: 'column', marginTop: 3, justifyContent: 'center', alignItems: 'center', width: 24, height: 24, borderWidth: 2, border: '3px solid #000000', borderRadius: 3, marginRight: 5}}>
                            {item.isSelect && <img src={CheckBoxCheck} style={{width: 20, height: 20}}/>}
                          </div>
                          <p style={{color: '#000000', fontSize: 20}} >{item.tabName}</p>
                        </div>
                      })
                  }
                </div>
              </div>
              <div style={{marginTop: 10, marginLeft: 18, height: 480, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <div style={{textDecoration: 'none', flexDirection: 'column', width: w, height: h, justifyContent: 'center', alignItems: 'center'}} >
                  <div
                    style={{width: w * 0.95, height: h * 0.9, backgroundColor: '#B0E0E6', borderRadius: 6, justifyContent: 'center', alignItems: 'center'}}
                  >
                    <div style={{display: "flex", width: w * 0.95, height: h * 0.9, alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <p style={{color: 'black', fontStyle: 'italic', fontSize: 22}}>Dati</p>
                        <p style={{fontWeight: '500', color: 'red', fontSize: 22, marginLeft: 5, fontStyle: 'italic', textDecoration: 'line-through', textDecorationColor: 'black'}}>₱{formatNumberWithComma(computeDati(), 2)}</p>
                      </div>
                      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                        <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: 'black', fontSize: 26, fontStyle: 'italic', backgroundColor: 'transparent', marginTop: 10, height: 14}}>Ngayon</p>
                        <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: '#00599d', fontSize: 50, backgroundColor: 'transparent'}}>₱{formatNumberWithComma(computeTotal(), 2)}</p>
                      </div>
                      <div style={{top: 20, width: w * 0.9, alignItems: 'center'}}>
                          { data.map((item,index) => 
                            <p key={index} style={{textAlign: 'center', height: 20, fontWeight: '500', width: w * 0.9, color: 'black', fontSize: 22}}>{`${item.generic.name} ${item.dosage.name}`}</p>
                          ) }
                      </div>
                    </div>   
                    { totalSavings() > 0 &&
                      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'center', marginTop: 5}}>
                        <p style={{textAlign: 'center', fontWeight: '500', color: 'black', fontSize: 14, paddingBottom: 5}}>{computeSavings() == '' ? '' : 'Nakatipid ka ng higit pa sa'}</p>
                        <p style={{textAlign: 'center', fontWeight: '500', color: 'red', fontSize: 22, marginLeft: 5}}>{computeSavings()}</p>
                        <p style={{textAlign: 'center', fontWeight: '500', color: 'black', fontSize: 14, marginLeft: 5, paddingBottom: 5}}>{` o `}</p>
                        <p style={{textAlign: 'center', fontWeight: '500', color: 'red', fontSize: 22, marginLeft: 5}}>₱{formatNumberWithComma(totalSavings(), 2)}</p>
                      </div>       
                    }
                  </div> 
                </div>       
              </div>
              </>
            </div>
            <div 
              style={{display: 'flex', backgroundColor: 'transparent', width: w, position: 'absolute', bottom: 60, alignItems: 'center', justifyContent: 'center', cursor: 'pointer'}}
              onClick={() => setLevel(1)}
            >
              <img src={Kailan} style={{width: 150, height: 75, resizeMode: 'contain'}}/>
            </div>  
            <div style={{position: 'absolute', top: 0, paddingLeft: 0, paddingRight: 0, backgroundColor: 'black', alignItems: 'center', width: '100%'}}>
              <InnerPageHeader hasBackBtn goBackClick={() => navigate(-1)} title="" style={{position: 'absolute'}}/>
            </div>
          </div>
        }
        {level == 1 &&
          <div className={classes.homeContainer}>
            <div style={{ width: '100%', alignItems: 'center', justifyContent: 'center', minWidth: 400, maxWidth: 400, marginTop: '50px' }}>
              <>
              <div style={{ display: "flex", backgroundColor: 'transparent', top: 30, alignItems: 'center', flexDirection: 'column' }}>
                <img src='https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1660892303143.png' style={{width: 375, height: 93, resizeMode: 'contain'}} />
              </div>
              <div style={{marginTop: 10, marginLeft: 0, height: 480, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <div style={{textDecoration: 'none', flexDirection: 'column', width: w, height: h, justifyContent: 'center', alignItems: 'center'}} >
                  <div
                    style={{width: w, height: h * 0.9, backgroundColor: '#B0E0E6', borderRadius: 6, justifyContent: 'center', alignItems: 'center'}}
                  >
                    <div style={{display: "flex", width: w * 0.9, marginLeft: 20, height: h * 0.95, alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column'}}>
                      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <p style={{color: '#00599d', fontWeight: '500', fontSize: 22}}>Date: </p>
                        <p style={{fontWeight: '500', color: 'black', fontSize: 20, marginLeft: 5}}>{nextWeek()} - 10:00AM</p>
                      </div>
                      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                          <p style={{color: '#00599d', fontWeight: '500', fontSize: 22}}>Team Leader: </p>
                          <p style={{fontWeight: '500', color: 'black', fontSize: 20, marginLeft: 5}}>{data[0].teamLeaderFirstname} {data[0].teamLeaderLastname}</p>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', height: 20, width: w * 0.8, justifyContent: 'flex-start'}}>
                          <p style={{color: '#00599d', fontWeight: '500', fontSize: 22}}>Address: </p>
                          <p style={{fontWeight: '500', color: 'black', fontSize: 20, marginLeft: 5}}>{data[0].teamLeaderAddress}</p>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', height: 40, justifyContent: 'flex-start'}}>
                          <p style={{color: '#00599d', fontWeight: '500', fontSize: 22}}>Contact #: </p>
                          <p style={{fontWeight: '500', color: 'black', fontSize: 20, marginLeft: 5}}>{data[0].teamLeaderPhoneNumber}</p>
                        </div>
                      </div>
                      {data[0].sponsorDisplayName !== null && <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <p style={{color: '#00599d', fontWeight: '500', fontSize: 22}}>Sponsor: </p>
                        <p style={{fontWeight: '500', color: 'black', fontSize: 20, marginLeft: 5}}>{data[0].sponsorDisplayName}</p>
                      </div>}
                    </div>
                  </div>
                </div>
              </div>
              </>
            </div>
            <div 
              style={{display: 'flex', backgroundColor: 'transparent', width: w, position: 'absolute', bottom: 40, alignItems: 'center', justifyContent: 'center', cursor: 'pointer'}}
              onClick={() => onDoneClick()}
            >
              <img src={Okay} style={{width: 150, height: 75, resizeMode: 'contain'}}/>
            </div> 
            <div style={{position: 'absolute', top: 0, paddingLeft: 0, paddingRight: 0, backgroundColor: 'black', alignItems: 'center', width: '100%'}}>
              <InnerPageHeader hasBackBtn goBackClick={() => setLevel(0)} title="" style={{position: 'absolute'}}/>
            </div>
          </div>
        } 
      </div>
    : isLoaded ? 
      <div
        style={{
          display: "flex",
          alignItems: "center",
          minHeight: "100vh",
          flexDirection: "column",
        }}
      >
        <img src={getPlaceHolder(props.dashData)} style={{width: 300, height: 591, marginTop: 50}} onClick={() => navigate(-1)}/>
        <div style={{position: 'fixed', top: 0, paddingLeft: 0, paddingRight: 0, backgroundColor: 'black', alignItems: 'center', minWidth: 400}}>
          <InnerPageHeader hasBackBtn goBackClick={() => navigate(-1)} title="" style={{position: 'absolute'}}/>
        </div>
      </div>
    : null
  );
};

export default Bumili;
