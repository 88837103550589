import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'
import useStyles from './styles';
import DetailBrand from '../../../Components/DetailBrand';
import InnerPageHeader from '../../../Components/InnerPageHeader';
import DetailReceive from '../../../Components/DetailReceive';
import DetailJoin from '../../../Components/DetailJoin';
import useWindowDimensions from '../../../utils/windowDimention';
import routes from '../../../routes';

let firstClientX = null;
let firstClientY = null;
let clientX = null;
let clientY = null;

const BoostDetails = () => {
  const classes = useStyles();
  let navigate = useNavigate();
  const props = useLocation().state;
  if (!props) {
    localStorage.removeItem("token");
    localStorage.removeItem("name");
    navigate(routes.login);
  }
  const { height, width } = useWindowDimensions();

  useEffect(() => {
    window.addEventListener('touchstart', touchStart);
    window.addEventListener('touchmove', preventTouch, { passive: false });

    return () => {
      window.removeEventListener('touchstart', touchStart);
      window.removeEventListener('touchmove', preventTouch, {
        passive: false,
      });
    };
  }, []);

  const touchStart = (e) => {
    firstClientX = e.touches[0].clientX;
    firstClientY = e.touches[0].clientY;
  };

  const preventTouch = (e) => {
    const minValue = 5; // threshold

    clientX = e.touches[0].clientX - firstClientX;
    clientY = e.touches[0].clientY - firstClientY;

    if (Math.abs(clientX) > minValue) {
      e.preventDefault();
      e.returnValue = false;
      return false;
    }
  };

  
  useEffect(() => {
    const disablePinchZoom = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault()
      }
    }
    document.addEventListener("touchmove", disablePinchZoom, { passive: false })
    return () => {
      document.removeEventListener("touchmove", disablePinchZoom)
    }
  }, [])

  const item = props.boostDetails;

  return (
    <div className={classes.homeContainer} >
      <div className={classes.homeContainer}>
        <div style={{position: 'absolute', top: 0, paddingLeft: 0, paddingRight: 0, backgroundColor: 'white', alignItems: 'center', width: '100%'}}>
          <InnerPageHeader hasBackBtn goBackClick={() => navigate(-1)} title={item.MitApp.User.display_name} style={{position: 'absolute'}}/>
        </div>
        <div style={{marginTop: '0px', alignItems: 'center'}}>
          <DetailBrand title={item.MitApp.title} description={item.MitApp.description} image={item.MitApp.User.avatar_big}/>
          <DetailReceive expiryDate={item.expiry_date} mitappId={item.MitApp.id} isAccepted={item.is_accepted} prize={item.MitApp.crypto_description} image={item.MitApp.MitAppPrize.campaign_image_big}/>
        </div>
        <DetailJoin image={item.MitApp.image_big} />
      </div>
    </div>
  );
};

export default BoostDetails;
