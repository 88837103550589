import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  staticLabel: {
    marginTop: 10,
    color: '#7b7373',
  },
  displayDetailData: {
    color: 'black',
    fontWeight: 600,
    marginTop: 5
  },
  displayDetailDate: {
    color: '#7b7373',
    fontSize: 20
  },
  receiveImage: {
    width: 200,
  },
  displayDetailButton : {
    background: "#00ACB8",
    color: 'white',
    fontSize: 18,
    margin: '10px',
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 40,
    paddingRight: 40,
    borderRadius: 30,
    border: 'none',
    cursor: 'pointer'
  },
  displayDetailText: {
    color: '#db3eb1',
    fontSize: 18,
    margin: '10px',
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 40,
    paddingRight: 40,
    borderRadius: 30,
    border: 'none',
  },
  pageDetailContainer: {
    display: 'flex',
    alignItems:'center',
    padding: 5,
    flexDirection: 'column',
    backgroundColor: 'white'
  },
}));
