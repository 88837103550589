import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import useStyles from './styles';
import useWindowDimensions from '../../../utils/windowDimention';
import Loader from "../../../Components/Common/Loader";
import InnerPageHeader from '../../../Components/InnerPageHeader';
import Placeholder from '../../../assets/images/common-images/user_placeholder.png';
import ReactTimeAgo from 'react-time-ago';
import {getPlaceHolder} from '../../../utils/utils';
// import Header from '../../../assets/images/header-images/Sumali_1.png';
import CheckButton from '../../../assets/images/common-images/checkIcon.png';
import CheckBoxCheck from '../../../assets/images/common-images/checkBoxCheck.png';
import Tapos from '../../../assets/images/common-images/taposNa.png'

import {
  getQuestionnaire, uploadAnswer
} from "../../../services/ApiService";
import { ids, routes } from '../../../constants';

let firstClientX = null;
let firstClientY = null;
let clientX = null;
let clientY = null;

const Sumagot = ({ history}) => {
  const classes = useStyles();
  let navigate = useNavigate();
  const { height, width } = useWindowDimensions();
  const [selectedSlide, setSelectedSlide] = useState(0);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const props = useLocation().state;

  const fetchData = async () => {
    try {
      setLoader(true);
      const response = await getQuestionnaire();
      // console.log(`response2=${JSON.stringify(response)}`)
      if (response) {
        setData(response.data.data.map((item) => {
          return {
            ...item,
            line1: {
              text: `${item.question}`,
              color: '#00599d',
              fontSize: 18
            },
            isSelected: false,
            answerId: item.answerId
          }}
        ));
        setIsLoaded(true);
        setLoader(false);
      }

      // console.log("response", response);
    } catch (e) {
      setIsLoaded(true);
      setLoader(false);
      console.log(e);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const disablePinchZoom = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault()
      }
    }
    document.addEventListener("touchmove", disablePinchZoom, { passive: false })
    return () => {
      document.removeEventListener("touchmove", disablePinchZoom)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('touchstart', touchStart);
    window.addEventListener('touchmove', preventTouch, { passive: false });
    return () => {
      window.removeEventListener('touchstart', touchStart);
      window.removeEventListener('touchmove', preventTouch, {
        passive: false,
      });
    };
  }, []);

  const touchStart = (e) => {
    firstClientX = e.touches[0].clientX;
    firstClientY = e.touches[0].clientY;
  };

  const preventTouch = (e) => {
    const minValue = 5; // threshold

    clientX = e.touches[0].clientX - firstClientX;
    clientY = e.touches[0].clientY - firstClientY;

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(clientX) > minValue) {
      e.preventDefault();
      e.returnValue = false;
      return false;
    }
  };

  const onCheckPress = (rowData,answerId) => {
    if (rowData.isAnswered !== 1) {
      let tempData = [...data
      .map(item => {
        if (item.id == rowData.id) {
          return {
            ...item,
            answerId
          }
        }
        else {
          return item
        }
      })];
      setData([...tempData]);
    }
  }

  const onDonePress = async () => {
    await uploadAnswer(data.map((item) => {
      return {
        questionId: item.id,
        answerId: item.answerId
      }
    }))
    
    if (props.onBackPress) {
      props.onBackPress();
    }
    navigate(-1);
  }
  
  return (
    (typeof props.dashData !== 'undefined' && props.dashData.length > 0 && props.dashData[0].received == 1 && data.length > 0) ? 
      <div className={classes.sumagotContainer}>
        {loader && <Loader />}
        <div className={classes.sumagotContainer}>
          <div style={{ width: '100%', alignItems: 'center', justifyContent: 'center', minWidth: 400, maxWidth: 400, marginTop: '50px' }}>
            
            <>
            <div style={{ display: "flex", backgroundColor: 'transparent', top: 30, alignItems: 'center', flexDirection: 'column' }}>
              <img src='https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1660892904154.png' style={{width: 375, height: 93, resizeMode: 'contain'}} />
            </div>
            <div style={{marginTop: 10, marginLeft: 50, minHeight: 200}}>
              {data.map((rowData,index) => {
                const w = 300;
                const h = 160;
                return <div key={rowData.id} style={{width: w, height: h, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}} >
                  <div
                    style={{display: 'flex', flexDirection: 'column', width: w, height: h * 0.9, position: 'absolute', backgroundColor: rowData.id == selectedId ? '#B0E0E6' : '#B0E0E670', borderRadius: 6, justifyContent: 'center', alignItems: 'center'}}
                  >
                    <p style={{textAlign: 'center', fontWeight: '500', marginTop: 10, width: w * 0.8, color: rowData.line1.color, fontSize: rowData.line1.fontSize}} >{rowData.line1.text}</p>
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', width: w * 0.8}}>
                      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.8}}>
                        { rowData.answers.map(item => {
                          return <div key={item.id} style={{cursor: 'pointer', display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.4}} onClick={() => onCheckPress(rowData, item.id)} disabled={rowData.isAnswered == 1}>
                            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: 20, height: 20, borderWidth: 2, border: '3px solid #000000', borderRadius: 3, marginRight: 5}}>
                              {rowData.answerId == item.id && <img src={CheckBoxCheck} style={{width: 20, height: 20}}/>}
                            </div>
                            <p style={{color: '#000000', fontSize: 16}} >{item.name}</p>
                          </div>
                        }) }
                      </div>
                    </div>
                    {rowData.isSelected && <img
                        style={{width: 20, height: 20, position: 'absolute', right: 8, top: '5%'}}
                        src={CheckButton}
                      />
                    }
                  </div>
                </div>
              })}
            </div>
            <div style={{display: 'flex', backgroundColor: 'transparent', maxWidth: 400, marginTop: 30, flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
              { data.length > 0 &&
                data.filter(item => typeof item.answerId !== 'undefined' && item.answerId && !item.isAnswered).length == data.length &&
                <div onClick={() => onDonePress()} style={{cursor: 'pointer'}} >
                  <img src={Tapos} style={{width: 150, height: 75, resizeMode: 'contain'}} />
                </div>
              }
            </div>
            </>
          </div>
          <div style={{position: 'absolute', top: 0, paddingLeft: 0, paddingRight: 0, backgroundColor: 'black', alignItems: 'center', width: '100%'}}>
            {/* {props.userData.isTeamLeader ? 
              <InnerPageHeader hasBackBtn goBackClick={() => navigate(-1)} title="" style={{position: 'absolute'}}
                rightButtonLabel="TEAM"
                onRightPress={() => navigate(routes.tlSumagot, { state: {userData: props.userData, dashData: props.dashData} })}
              /> : */}
              <InnerPageHeader hasBackBtn goBackClick={() => navigate(-1)} title="" style={{position: 'absolute'}}/>
            {/* } */}
          </div>
        </div>
      </div>
    : isLoaded ? 
      <div
        style={{
          display: "flex",
          alignItems: "center",
          minHeight: "100vh",
          flexDirection: "column",
        }}
      >
        <img src={getPlaceHolder(props.dashData)} style={{width: 300, height: 591, marginTop: 50}} onClick={() => navigate(-1)}/>
        <div style={{position: 'fixed', top: 0, paddingLeft: 0, paddingRight: 0, backgroundColor: 'black', alignItems: 'center', minWidth: 400}}>
          {/* {props.userData.isTeamLeader ? 
            <InnerPageHeader hasBackBtn goBackClick={() => navigate(-1)} title="" style={{position: 'absolute'}}
              rightButtonLabel="TEAM"
              onRightPress={() => navigate(routes.tlSumagot, { state: {userData: props.userData, dashData: props.dashData} })}
            /> : */}
            <InnerPageHeader hasBackBtn goBackClick={() => navigate(-1)} title="" style={{position: 'absolute'}}/>
          {/* } */}
        </div>
      </div>
    : null
  );
};

export default Sumagot;