import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useStyles from "./styles";
import useWindowDimensions from "../../utils/windowDimention";
import Loader from "../../Components/Common/Loader";
import InnerPageHeader from "../../Components/InnerPageHeader";
import { Grid } from "@mui/material";
import { routes } from "../../constants";

import { getStoreItemsBought } from "../../services/ApiService";

let firstClientX = null;
let firstClientY = null;
let clientX = null;
let clientY = null;

const Collection = ({ history }) => {
  const classes = useStyles();
  let navigate = useNavigate();
  const props = useLocation().state;
  const { height, width } = useWindowDimensions();
  const [selectedSlide, setSelectedSlide] = useState(0);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [claimedCount, setClaimedCount] = useState(0);

  const fetchData = async () => {
    try {
      setLoader(true);
      const response = await getStoreItemsBought();
      // console.log(`response2=${JSON.stringify(response)}`)
      if (response) {
        let count = 0;
        if (
          typeof response.data.data !== "undefined" &&
          response.data.data.length > 0
        ) {
          count = response.data.data[0].itemCount;
        }
        setClaimedCount(count);
        setData(response.data.data);
        setLoader(false);
      }

      // console.log("response", response);
    } catch (e) {
      setLoader(false);
      console.log(e);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    window.addEventListener("touchstart", touchStart);
    window.addEventListener("touchmove", preventTouch, { passive: false });
    return () => {
      window.removeEventListener("touchstart", touchStart);
      window.removeEventListener("touchmove", preventTouch, {
        passive: false,
      });
    };
  }, []);

  useEffect(() => {
    const disablePinchZoom = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault();
      }
    };
    document.addEventListener("touchmove", disablePinchZoom, {
      passive: false,
    });
    return () => {
      document.removeEventListener("touchmove", disablePinchZoom);
    };
  }, []);

  const touchStart = (e) => {
    firstClientX = e.touches[0].clientX;
    firstClientY = e.touches[0].clientY;
  };

  const preventTouch = (e) => {
    const minValue = 5; // threshold

    clientX = e.touches[0].clientX - firstClientX;
    clientY = e.touches[0].clientY - firstClientY;

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(clientX) > minValue) {
      e.preventDefault();
      e.returnValue = false;
      return false;
    }
  };

  const onImageClick = (item) => {
    navigate(routes.collectionDetails, { state: { collectionData: item, userData: props.userData } });
  };

  return (
    <div className={classes.homeContainer}>
      {loader && <Loader />}
      <div className={classes.homeContainer}>
        <div className={classes.titleContainer}>
          <p className={classes.name}>{props.userData.displayName}</p>
          <p className={classes.title}>Total items: {claimedCount}</p>
        </div>
        <div
          style={{
            minWidth: 480,
            justifyContent: "center",
            maxWidth: 480,
            marginTop: "0px",
          }}
        >
          <Grid
            container
            style={{ marginLeft: 42, maxWidth: 360 }}
            rowSpacing={2}
            columnSpacing={-5}
            columns={{ xs: 6, sm: 12, md: 12 }}
          >
            {data.length > 0 &&
              data.map((item, index) => (
                <Grid item xs={2} sm={4} md={4} key={index}>
                  <img
                    src={item.imageBig}
                    className={classes.image}
                    onClick={() => onImageClick(item)}
                  />
                </Grid>
              ))}
          </Grid>
          {/* {data.length > 0 && data.map((item,index) => {
              return (<div className={classes.historyContainer} key={item.lastModifiedDate} >
                <img src={item.playerAvatar ? item.playerAvatar : Placeholder} className={classes.profileImage} />
              </div>)}
            )
            } */}
        </div>
        <div
          style={{
            position: "absolute",
            top: 0,
            paddingLeft: 0,
            paddingRight: 0,
            backgroundColor: "black",
            alignItems: "center",
            width: "100%",
          }}
        >
          <InnerPageHeader
            hasBackBtn
            goBackClick={() => navigate(-1)}
            title={`Earnings`}
            style={{ position: "absolute" }}
          />
        </div>
      </div>
    </div>
  );
};

export default Collection;
