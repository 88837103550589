import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  homeContainer: {
    height: "100%",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "100%",
    },
  },
  homeContent: {
    width: "100%",
    height: "100%",
    objectFit: "fill",
  },
  sliderIndicatorContainer: {
    position: "absolute",
    top: "50%",
    left: 15,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  sliderIndicator: {
    border: "2px solid white",
    width: 7,
    height: 7,
    border: "1px solid white",
    borderRadius: "50%",
    marginBottom: 8,
    marginLeft: "auto",
    marginRight: "auto",
    cursor: "pointer",
  },
  headerContainer: {
    backgroundColor: "black",
    position: "absolute",
    boxSizing: "border-box",
    width: "100%",
    zIndex: 1,
  },
  dataLabel: {
    color: "white",
    fontSize: 16,
    fontFamily: "canaroBook",
  },
}));
