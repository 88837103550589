import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import useStyles from './styles';
import useWindowDimensions from '../../../utils/windowDimention';
import Loader from "../../../Components/Common/Loader";
import InnerPageHeader from '../../../Components/InnerPageHeader';
import Placeholder from '../../../assets/images/common-images/user_placeholder.png';
import ReactTimeAgo from 'react-time-ago';
import {getPlaceHolder} from '../../../utils/utils';
// import Header from '../../../assets/images/header-images/Uminom_1.png';
import checkButton from '../../../assets/images/common-images/checkIcon.png';
import innerYes from '../../../assets/images/common-images/oo_inom.png';
import Balik from '../../../assets/images/common-images/balik.png'

import {
  getReseta
} from "../../../services/ApiService";
import { ids, routes } from '../../../constants';

let firstClientX = null;
let firstClientY = null;
let clientX = null;
let clientY = null;

const Makatipid = ({ history}) => {
  const classes = useStyles();
  let navigate = useNavigate();
  const { height, width } = useWindowDimensions();
  const [selectedSlide, setSelectedSlide] = useState(0);
  const [loader, setLoader] = useState(false);
  const [sku, setSku] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [timeId, setTimeId] = useState(1);
  const props = useLocation().state;

  const fetchData = async () => {
    try {
      setLoader(true);
      const response = await getReseta();
      // console.log(`response2=${JSON.stringify(response)}`)
      if (response) {
        setSku(response.data.data.map(item => {
          return {
            ...item,
            isSelected: true
          }
        }));       
        setIsLoaded(true);
        setLoader(false);
      }

      // console.log("response", response);
    } catch (e) {
      setIsLoaded(true);
      setLoader(false);
      console.log(e);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const disablePinchZoom = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault()
      }
    }
    document.addEventListener("touchmove", disablePinchZoom, { passive: false })
    return () => {
      document.removeEventListener("touchmove", disablePinchZoom)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('touchstart', touchStart);
    window.addEventListener('touchmove', preventTouch, { passive: false });
    return () => {
      window.removeEventListener('touchstart', touchStart);
      window.removeEventListener('touchmove', preventTouch, {
        passive: false,
      });
    };
  }, []);

  const touchStart = (e) => {
    firstClientX = e.touches[0].clientX;
    firstClientY = e.touches[0].clientY;
  };

  const preventTouch = (e) => {
    const minValue = 5; // threshold

    clientX = e.touches[0].clientX - firstClientX;
    clientY = e.touches[0].clientY - firstClientY;

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(clientX) > minValue) {
      e.preventDefault();
      e.returnValue = false;
      return false;
    }
  };

  const onDonePress = async () => {
    // const selected = data.filter(item => item.isSelected);
    // const param = selected.map((item) => {
    //   return {
    //     boughtDetailsId: item.id,
    //     umaga: timeId == 1 ? item.umaga : 0,
    //     tanghali: timeId == 2 ? item.tanghali : 0,
    //     gabi: timeId == 3 ? item.gabi : 0,
    //   }
    // })

    // await uploadTaken(param)
    
    if (props.onBackPress) {
      props.onBackPress();
    }
    navigate(-1);
  }

  const onRowPress = (rowData) => {
    let tempGamot = [...sku
    .map(item => {
      if (item.id == rowData.id) {
        return {
          ...item,
          isSelected: !rowData.isSelected
        }
      }
      else {
        return item
      }
    })];
    setSku(tempGamot);
  }
  
  return (
    <div className={classes.makatipidContainer}>
      {loader && <Loader />}
      <div className={classes.makatipidContainer}>
        <div style={{ width: '100%', alignItems: 'center', justifyContent: 'center', minWidth: 400, maxWidth: 400, marginTop: '50px' }}>
          
          <>
          <div style={{ display: "flex", backgroundColor: 'transparent', top: 30, alignItems: 'center', flexDirection: 'column' }}>
            <img src='https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1660876816288.png' style={{width: 375, height: 93, resizeMode: 'contain'}} />
          </div>
          <div style={{marginTop: 10, marginLeft: 50, minHeight: 200}}>
            {sku.map((rowData,index) => {
              const w = 300;
              const h = 180;
              return <div key={rowData.id} style={{display: "flex", width: w, height: h, flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}} >
                <div
                  style={{display: 'flex', width: w * 0.9, height: h * 0.8, position: 'absolute', backgroundColor: rowData.isSelected ? '#B0E0E6' : '#B0E0E670', borderRadius: 6, justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}
                  // onClick={() => onRowPress(rowData)} 
                >
                  <img src={rowData.generic.image} style={{width: 120, height: 120, position: 'absolute', marginBottom: 20}} />
                  <p style={{marginTop: 60, textAlign: 'center', fontWeight: '500', width: w * 0.8, color: '#00599d', fontSize: 24}} >{rowData.generic.name} {rowData.dosage.name}</p>
                  {/* {rowData.isSelected && <img
                      style={{width: 20, height: 20, position: 'absolute', right: 3, top: '5%'}}
                      src={checkButton}
                    />
                  } */}
                </div>
              </div>
            })}
          </div>
          </>
        </div>
        <div style={{display: 'flex', backgroundColor: 'transparent', maxWidth: 400, marginTop: 0, flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
          { sku.length > 0 &&
            sku.filter(item => item.isSelected).length > 0 &&
            <div onClick={() => onDonePress()} style={{cursor: 'pointer'}} >
              <img src={Balik} style={{width: 150, height: 75, resizeMode: 'contain'}} />
            </div>
          }
        </div>
        <div style={{position: 'absolute', top: 0, paddingLeft: 0, paddingRight: 0, backgroundColor: 'black', alignItems: 'center', width: '100%'}}>
          <InnerPageHeader hasBackBtn goBackClick={() => navigate(-1)} title="" style={{position: 'absolute'}}
            hasDagdaganBtn
            onDagdaganPress={() => navigate(routes.reseta, { state: {userData: props.userData, dashData: props.dashData} })}
          />
        </div>
      </div>
    </div>
  );
};

export default Makatipid;