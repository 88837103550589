import React from 'react';
import useStyles from './styles'; 

const DetailBrand = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.detailBrandContainer}>
      <img className={classes.inviteImage} src={props.image} alt='invite' />
      <div style={{flexDirection: 'column', marginLeft: 20, marginRight: 20}}>
        <p className={classes.detailBrandTitle}>{props.title}</p>
        <p className={classes.displayBrandData}>{props.description}</p>
      </div>
    </div>
  );
};

export default DetailBrand;