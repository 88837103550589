import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import useStyles from './styles';
import useWindowDimensions from '../../../utils/windowDimention';
import Loader from "../../../Components/Common/Loader";
import InnerPageHeader from '../../../Components/InnerPageHeader';
import Placeholder from '../../../assets/images/common-images/user_placeholder.png';
import ReactTimeAgo from 'react-time-ago';
import {getPlaceHolder} from '../../../utils/utils';
// import Header from '../../../assets/images/header-images/Messenger_1.png';
import footerMessenger from '../../../assets/images/common-images/messenger.png';
import Done from '../../../assets/images/common-images/done.png'

import {
  getQuestionnaire, uploadAnswer
} from "../../../services/ApiService";
import { ids, routes } from '../../../constants';
import { isMobile } from 'react-device-detect';

let firstClientX = null;
let firstClientY = null;
let clientX = null;
let clientY = null;

const MagChat = ({ history}) => {
  const classes = useStyles();
  let navigate = useNavigate();
  const { height, width } = useWindowDimensions();
  const [selectedSlide, setSelectedSlide] = useState(0);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const props = useLocation().state;

  const fetchData = async () => {
    try {
      setLoader(true);
      const response = await getQuestionnaire();
      // console.log(`response2=${JSON.stringify(response)}`)
      if (response) {
        setData(response.data.data.map((item) => {
          return {
            ...item,
            line1: {
              text: `${item.question}`,
              color: '#00599d',
              fontSize: 18
            },
            isSelected: false,
            answerId: item.answerId
          }}
        ));
        setIsLoaded(true);
        setLoader(false);
      }

      // console.log("response", response);
    } catch (e) {
      setIsLoaded(true);
      setLoader(false);
      console.log(e);
    }
  };

  useEffect(() => {
    // fetchData();
    setData([{
      id: 1,
      image: "https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1659090136621.png",
      name: `M2 MEDICINE MISSION`,
      link: `https://m.me/j/AbZ4dOLYgRS5gcwV`
    }])
  }, []);

  useEffect(() => {
    const disablePinchZoom = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault()
      }
    }
    document.addEventListener("touchmove", disablePinchZoom, { passive: false })
    return () => {
      document.removeEventListener("touchmove", disablePinchZoom)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('touchstart', touchStart);
    window.addEventListener('touchmove', preventTouch, { passive: false });
    return () => {
      window.removeEventListener('touchstart', touchStart);
      window.removeEventListener('touchmove', preventTouch, {
        passive: false,
      });
    };
  }, []);

  const touchStart = (e) => {
    firstClientX = e.touches[0].clientX;
    firstClientY = e.touches[0].clientY;
  };

  const preventTouch = (e) => {
    const minValue = 5; // threshold

    clientX = e.touches[0].clientX - firstClientX;
    clientY = e.touches[0].clientY - firstClientY;

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(clientX) > minValue) {
      e.preventDefault();
      e.returnValue = false;
      return false;
    }
  };

  const onDonePress = async () => {
    await uploadAnswer(data.map((item) => {
      return {
        questionId: item.id,
        answerId: item.answerId
      }
    }))
    
    if (props.onBackPress) {
      props.onBackPress();
    }
    navigate(-1);
  }

  const getContent = (w,h,rowData) => <div
    style={{width: w * 0.9, height: h * 0.9, backgroundColor: '#B0E0E6', borderRadius: 6, justifyContent: 'center', alignItems: 'center'}}
  >
    <div style={{display: "flex", width: w * 0.9, height: h * 0.9, alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
      <img
        style={{width: isMobile ? 185 : 220, height: isMobile ? 185 : 220, marginTop: 20}}
        src={rowData.image}
      />
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}>
        <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: '#00599d', fontSize: 20}}>{rowData.name}</p>
        <p style={{lexShrink: 1, textAlign: 'center', fontWeight: '500', width: w * 0.7, color: 'black', fontSize: 20, fontStyle: 'italic'}}>{rowData.link}</p>
      </div>
      <div 
          style={{top: 20, width: 60, height: 60, alignItems: 'center'}}>
          <img src={footerMessenger} style={{width: 50, height: 50, resizeMode: 'contain'}}/>
      </div>
    </div>          
  </div>
  
  return (
    <div className={classes.homeContainer}>
      {loader && <Loader />}
      <div className={classes.homeContainer}>
        <div style={{ width: '100%', alignItems: 'center', justifyContent: 'center', minWidth: 400, maxWidth: 400, marginTop: '50px' }}>
          <>
          <div style={{ display: "flex", backgroundColor: 'transparent', top: 30, alignItems: 'center', flexDirection: 'column' }}>
            <img src='https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1660897295195.png' style={{width: 375, height: 93, resizeMode: 'contain'}} />
          </div>
          <div style={{marginTop: 10, marginLeft: 60, height: 480, cursor: 'pointer'}}>
            {data.map((rowData,index) => {
              const w = 300;
              const h = isMobile ? 420 : 470;
              if (isMobile) {
                return <a key={rowData.id} style={{textDecoration: 'none', flexDirection: 'column', width: w, height: h, justifyContent: 'center', alignItems: 'center'}} 
                  href={`${rowData.link}`} disabled={typeof rowData.saved !== 'undefined' && rowData.saved}
                >
                  {getContent(w,h,rowData)}
                </a>
              }
              else {
                return <div key={rowData.id} style={{textDecoration: 'none', flexDirection: 'column', width: w, height: h, justifyContent: 'center', alignItems: 'center'}} 
                  onClick={()=> window.open(`${rowData.link}`, "_blank")} 
                >
                  {getContent(w,h,rowData)}
                </div>
              }
            })}
          </div>
          </>
        </div>
        <div style={{position: 'absolute', top: 0, paddingLeft: 0, paddingRight: 0, backgroundColor: 'black', alignItems: 'center', width: '100%'}}>
          <InnerPageHeader hasBackBtn goBackClick={() => navigate(-1)} title="" style={{position: 'absolute'}}/>
        </div>
      </div>
    </div>
  );
};

export default MagChat;
