import React, { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { useLocation, useNavigate } from 'react-router-dom'
import useWindowDimensions from '../../../../utils/windowDimention';
import useStyles from './styles';
// import { useGeolocated } from "react-geolocated";
import InnerPageHeader from '../../../../Components/InnerPageHeader';
import moment from 'moment';

let firstClientX = null;
let firstClientY = null;
let clientX = null;
let clientY = null;
const google = window.google;

const DeliveryDetails = ({ history}) => {
  const classes = useStyles();
  let navigate = useNavigate();
  const { height, width } = useWindowDimensions();
  const props = useLocation().state;

  //  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
  //   useGeolocated({
  //       positionOptions: {
  //           enableHighAccuracy: false,
  //       },
  //       userDecisionTimeout: 5000,
  // });

  useEffect(() => {
    const disablePinchZoom = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault()
      }
    }
    document.addEventListener("touchmove", disablePinchZoom, { passive: false })
    return () => {
      document.removeEventListener("touchmove", disablePinchZoom)
    }
  }, [])

  useEffect(() => {    
    window.addEventListener('touchstart', touchStart);
    window.addEventListener('touchmove', preventTouch, { passive: false });
    return () => {
      window.removeEventListener('touchstart', touchStart);
      window.removeEventListener('touchmove', preventTouch, {
        passive: false,
      });
    };
  }, []);

  const touchStart = (e) => {
    firstClientX = e.touches[0].clientX;
    firstClientY = e.touches[0].clientY;
  };

  const preventTouch = (e) => {
    const minValue = 5; // threshold

    clientX = e.touches[0].clientX - firstClientX;
    clientY = e.touches[0].clientY - firstClientY;

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(clientX) > minValue) {
      e.preventDefault();
      e.returnValue = false;
      return false;
    }
  };

  const mapStyles = {
    width: '100%',
    height: '100%',
  }

  const markerStyle = {
    height: '50px',
    width: '50px',
    marginTop: "-50px"
  }
  
  const imgStyle = {
    height: '100%'
  }

  const textStyle = {
    fontSize: 14,
    fontStyle: "bold",
    textAlign: 'center',
    fontWeight: '600',
    color: 'blue'
  }

  const Marker = ({ title }) => (
    <div style={markerStyle}>
      <img style={imgStyle} alt={title} src="https://res.cloudinary.com/og-tech/image/upload/s--OpSJXuvZ--/v1545236805/map-marker_hfipes.png" />
      <h3 style={textStyle}>{title}</h3>
    </div>
  );

  const apiIsLoaded = (map, maps) => {
    const directionsService = new google.maps.DirectionsService();
    const directionsRenderer = new google.maps.DirectionsRenderer();
    directionsRenderer.setMap(map);
    const origin = { lat: 14.58988, lng: 120.98086 };
    const destination = { lat: 14.6167408664, lng: 120.976317761 };

    directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: google.maps.TravelMode.DRIVING
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          directionsRenderer.setDirections(result);
        } else {
          console.error(`error fetching directions ${result}`);
        }
      }
    );
  };

  return <div className={classes.makatipidContainer}>
    <div className={classes.makatipidContainer}>
      <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center', minWidth: 400, maxWidth: 400, marginTop: '40px' }}>
        <div style={{ height: '90vh', width: '100%' }}>
          {/* <GoogleMapReact
            style={mapStyles}
            bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_APIKEY }}
            defaultCenter={{ lat: 14.619236, lng: 121.007189 }}
            defaultZoom={10}
            yesIWantToUseGoogleMapApiInternals={true}
            onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps)}
          >
          </GoogleMapReact> */}
          <iframe
            src={`https://test.map.m2.bengga.co?var=${moment(new Date)}`}
            frameborder="0"
            style={mapStyles}
            title="video"
          />
        </div>
        <div style={{position: 'absolute', top: 0, paddingLeft: 0, paddingRight: 0, backgroundColor: 'black', alignItems: 'center', width: '100%'}}>
          <InnerPageHeader hasBackBtn goBackClick={() => navigate(-1)} title="" style={{position: 'absolute'}} />
        </div>
      </div>
    </div>
  </div>
}

export default DeliveryDetails;