import React, { useState } from 'react';
import useStyles from './style';
import Winners from '../../assets/images/header-images/winners.png';
import Prizes from '../../assets/images/footer-images/prizes.png';
import { ids } from '../../constants';
import BackArrow from '../../assets/images/common-images/backArrow.png';
import Dagdagan from '../../assets/images/header-images/dagdagan.png';

const InnerPageHeader = (props) => {
    const [soundClick, setSoundClick] = useState(true);
    const classes = useStyles();
    const [isClicked, setIsClicked] = useState(false);

    return (
        <div className={classes.headerContainer}>
            {props.leftButtonLabel &&
                <div className={classes.leftContainer} onClick={() => {
                    // if (!isClicked) {
                    //     setIsClicked(true);
                        if (props.onLeftPress) {
                            props.onLeftPress();
                            // setTimeout(() => setIsClicked(false), 1000)
                        }
                    // }
                }}>
                    <p className={classes.left}>{props.leftButtonLabel}</p>
                </div>
            }
            {props.rightButtonLabel &&
                <div className={classes.rightContainer} onClick={() => {
                    // if (!isClicked) {
                    //     setIsClicked(true);
                        if (props.onRightPress) {
                            props.onRightPress();
                            // setTimeout(() => setIsClicked(false), 1000)
                        }
                    // }
                }}>
                    <p className={classes.right}>{props.rightButtonLabel.toUpperCase()}</p>
                </div>
            }
            {props.hasBackBtn &&
                <button className={classes.backBtn} onClick={() => {
                    if (props.goBackClick){
                        props.goBackClick();
                    }
                }}>
                    {/* <svg className={classes.backBtnIcon} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                    </svg> */}
                    <img src={BackArrow} style={{width: 32, height: 32, position: 'absolute', left: 0, top: 0}} />
                </button>
            }
            <p className={classes.headerTitle}>{props.title.toUpperCase()}</p>
            {props.hasSoundBtn &&
                <button
                    className={classes.soundBtn}
                    onClick={() => {
                        setSoundClick(!soundClick);
                        if (props.onSoundPress) {
                            props.onSoundPress(!soundClick);
                        }
                    }}>
                    <svg className={classes.soundBtnIcon} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        {soundClick ? (
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.536 8.464a5 5 0 010 7.072m2.828-9.9a9 9 0 010 12.728M5.586 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h1.586l4.707-4.707C10.923 3.663 12 4.109 12 5v14c0 .891-1.077 1.337-1.707.707L5.586 15z" />
                        ) : (
                            <>
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5.586 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h1.586l4.707-4.707C10.923 3.663 12 4.109 12 5v14c0 .891-1.077 1.337-1.707.707L5.586 15z" clipRule="evenodd" />
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2" />
                            </>
                        )}
                    </svg>
                </button>
            }
            {props.hasWinnersBtn &&
                <button
                    className={classes.winnerBtn}
                    onClick={() => {
                        if (props.onWinnerPress) {
                            props.onWinnerPress();
                        }
                    }}>
                    <img className={classes.winnerBtnIcon} src={Winners}>
                    </img>
                </button>
            }
            {props.hasQuitBtn &&
                <div className={classes.quitContainer} onClick={() => {
                    if (!isClicked) {
                        setIsClicked(true);
                        if (props.onQuitPress) {
                            props.onQuitPress();
                        }
                        setIsClicked(false);
                    }
                }}>
                    <p className={classes.quit}>{Number(process.env.REACT_APP_RONDLE_TYPE) === ids.rondleTypeDobleKahol ? 'SUKO' : 'QUIT'}</p>
                </div>
            }
            {props.hasTourneyPrizeBtn &&
                <div className={classes.winnerBtn} onClick={() => {
                    if (props.onTourneyPrizePress) {
                        props.onTourneyPrizePress();
                    }
                }}>
                    <img className={classes.winnerBtnIcon} src={Prizes}/>
                </div>
            }
            {props.hasDagdaganBtn &&
                <button
                    className={classes.dagdaganBtn}
                    onClick={() => {
                        if (props.onDagdaganPress) {
                            props.onDagdaganPress();
                        }
                    }}>
                    <img className={classes.dagdaganBtnIcon} src={Dagdagan}>
                    </img>
                </button>
            }
        </div>
    );
};

export default InnerPageHeader;
