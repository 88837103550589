import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import useStyles from './styles';
import useWindowDimensions from '../../../../utils/windowDimention';
import Loader from "../../../../Components/Common/Loader";
import InnerPageHeader from '../../../../Components/InnerPageHeader';
import {getPlaceHolder} from '../../../../utils/utils';
// import Header from '../../../../assets/images/header-tl/Result_10.png';
import CheckBoxCheck from '../../../../assets/images/common-images/checkBoxCheck.png';
import Done from '../../../../assets/images/common-images/taposNa.png'
import moment from "moment";

import {
  getTLMonitoring, uploadTLMonitoring
} from "../../../../services/ApiService";
import { ids } from '../../../../constants';

let firstClientX = null;
let firstClientY = null;
let clientX = null;
let clientY = null;

const TLSumagot = ({ history}) => {
  const classes = useStyles();
  let navigate = useNavigate();
  const { height, width } = useWindowDimensions();
  const [selectedSlide, setSelectedSlide] = useState(0);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const props = useLocation().state;
  const activityType = ids.activityTypeSumagot;

  const fetchData = async () => {
    try {
      setLoader(true);
      const response = await getTLMonitoring({activityType: activityType});
      // console.log(`response2=${JSON.stringify(response)}`)
      if (response) {
        setData(response.data);
        setIsLoaded(true);
        setLoader(false);
      }

      // console.log("response", response);
    } catch (e) {
      setIsLoaded(true);
      setLoader(false);
      console.log(e);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const disablePinchZoom = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault()
      }
    }
    document.addEventListener("touchmove", disablePinchZoom, { passive: false })
    return () => {
      document.removeEventListener("touchmove", disablePinchZoom)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('touchstart', touchStart);
    window.addEventListener('touchmove', preventTouch, { passive: false });
    return () => {
      window.removeEventListener('touchstart', touchStart);
      window.removeEventListener('touchmove', preventTouch, {
        passive: false,
      });
    };
  }, []);

  const touchStart = (e) => {
    firstClientX = e.touches[0].clientX;
    firstClientY = e.touches[0].clientY;
  };

  const preventTouch = (e) => {
    const minValue = 5; // threshold

    clientX = e.touches[0].clientX - firstClientX;
    clientY = e.touches[0].clientY - firstClientY;

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(clientX) > minValue) {
      e.preventDefault();
      e.returnValue = false;
      return false;
    }
  };

  const onDonePress = async () => {
    await uploadTLMonitoring({
      recordDate: moment(new Date()).format("YYYY-MM-DD"),
      activityType: activityType,
      details: data.filter(item => item.is_oo !== null).map((item) => {
        return {
          userId: item.user_id,
          isOo: item.is_oo
        }
      })
    }); 
    navigate(-1);
  }

  const onCheckPress = (rowData, selectedId) => {
    let tempData = [...data
    .map(item => {
      if (item.user_id == rowData.user_id) {
        return {
          ...item,
          is_oo: (item.is_oo == selectedId) ? null : selectedId
        }
      }
      else {
        return item
      }
    })];
    setData([...tempData]);
  }

  const w = 360;
  const h = 100;
  
  return (
    <div className={classes.homeContainer}>
      {loader && <Loader />}
      <div className={classes.homeContainer}>
        <div style={{ width: '100%', alignItems: 'center', justifyContent: 'center', minWidth: 400, maxWidth: 400, marginTop: '40px' }}>
          
          <>
          <div style={{ display: "flex", backgroundColor: 'transparent', top: 30, alignItems: 'center', flexDirection: 'column' }}>
            <img src='https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1659715135479.png' style={{width: 375, height: 157, resizeMode: 'contain'}} />
          </div>
          <div style={{marginTop: 20, marginLeft: 50, width: w, height: h * 0.3, display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'center'}} >
            <div
              style={{display: 'flex', flexDirection: 'row', width: w, height: h * 0.2, position: 'absolute', backgroundColor: 'transparent', borderRadius: 6, justifyContent: 'flex-start', alignItems: 'center'}}
            >
              <div style={{width: w * 0.4, borderRadius: 8, backgroundColor: 'transparent'}}>
              </div>
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 30, justifyContent: 'center', width: w * 0.4}}>
                { ["Oo","Hindi"].map(item => {
                  return <div key={item} style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.4}}>
                    <p style={{color: '#000000', fontWeight: '500', fontSize: 18}} >{item}</p>
                  </div>
                }) }
              </div>
            </div>
          </div>
          <div style={{marginTop: 0, marginLeft: 50, minHeight: 200}}>
            {data.map((rowData,index) => {
              return <div key={rowData.user_id} style={{width: w, height: h, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}} >
                <div
                  style={{display: 'flex', flexDirection: 'row', width: w, height: h * 0.9, position: 'absolute', backgroundColor: 'transparent', borderRadius: 6, justifyContent: 'flex-start', alignItems: 'center'}}
                >
                  <div style={{width: w * 0.4, borderRadius: 8, backgroundColor: '#44729d'}}>
                    <p style={{textAlign: 'center', fontWeight: '500', marginTop: 10, width: w * 0.4, color: 'white', fontSize: 16}} >{rowData.firstname} {rowData.lastname}</p>
                  </div>
                  <div style={{cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 30, justifyContent: 'center', width: w * 0.4}}>
                    { [1,0].map(item => {
                      return <div key={item} style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.4}} onClick={() => onCheckPress(rowData, item)}>
                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: 20, height: 20, borderWidth: 2, border: '3px solid #000000', borderRadius: 3, marginRight: 5}}>
                          {((rowData.is_oo == 1 && item == 1) || (rowData.is_oo == 0 && item == 0)) && <img src={CheckBoxCheck} style={{width: 20, height: 20}}/>}
                        </div>
                      </div>
                    }) }
                  </div>
                </div>
              </div>
            })}
          </div>
          <div style={{cursor: 'pointer', display: 'flex', backgroundColor: 'transparent', maxWidth: 400, marginTop: 20, flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}} onClick={() => onDonePress()}>
            <img src={Done} style={{width: 150, height: 75, resizeMode: 'contain'}} />
          </div>
          </>
        </div>
        <div style={{position: 'absolute', top: 0, paddingLeft: 0, paddingRight: 0, backgroundColor: 'black', alignItems: 'center', width: '100%'}}>
          <InnerPageHeader hasBackBtn goBackClick={() => navigate(-1)} title="" style={{position: 'absolute'}}/>
        </div>
      </div>
    </div>
  );
};

export default TLSumagot;