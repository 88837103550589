import { nftHelper } from '../helpers/nftHelper';
// import placeholderSend from '../assets/images/common-images/placeHolder_Send.png';
// import placeholderTranscribe from '../assets/images/common-images/placeHolder_Transcribe.png';
// // import placeholderSave from '../assets/images/common-images/placeHolder_Save.png';
// import placeholderBuy from '../assets/images/common-images/placeHolder_Buy.png';
// // import placeholderDeliver from '../assets/images/common-images/placeHolder_Deliver.png';
// import placeHolderDosage from '../assets/images/common-images/placeHolder_Dosage.png';
// import placeholderMedicine from '../assets/images/common-images/placeHolder_Medicines.png';
// import placeholderDistribute from '../assets/images/common-images/placeHolder_Distribute.png';

export const formatNumberWithComma = (number: number, fractionDigits: number) => {
  // console.log(`###number=${number};`)
  if (typeof number !== 'undefined') {      
      var parts;
      try {
        parts = Number(number).toFixed(fractionDigits).toString().split(".");
      }
      catch {
        // console.log(`###error`)
        parts = number.toString().split(".");
      }
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      if (fractionDigits === 0) {
        // console.log(`###parts[0]=${parts[0]};`)
        return parts[0];
      }
      else if (parts.length > 1) {
        parts[1] = parts[1].substring(0, fractionDigits);
      }
      // console.log(`###parts.join(".")=${parts.join(".")};`)
      return parts.join(".");
    // }
  }
  else {
    // console.log(`###`)
    return '';
  }
}

export const msToShortenedTimeLeft = (ms_diff, expiredText: ?string) => {
  let expired = 'Expired';
  if (typeof expiredText !== undefined && expiredText) { expired = expiredText };
  if (isNaN(ms_diff)) {
    return expired;
  }

  if (ms_diff <= 0) {
    return expiredText;
  }
  else {
    let days = Math.floor(ms_diff / 1000 / 60 / (60 * 24));
    let hours = Math.floor(ms_diff / 1000 / 60 / 60);
    let minutes = Math.floor(ms_diff / 1000 / 60);
    let seconds = Math.floor(ms_diff / 1000);
    // let date_diff = new Date(ms_diff);
    if (days === 0) {
      if (hours === 0) {
        if (minutes === 0) {
          if (seconds === 0) {
            return expiredText;
          }
          else {
            const second = seconds > 1 ? 'Secs' : 'Sec';
            return `${seconds} ${second}`;
          }
        }
        else {
          const minute = minutes > 1 ? 'Mins' : 'Min';
          return `${minutes} ${minute}`;
        }
      }
      else {
        const hour = hours > 1 ? 'Hrs' : 'Hr';
        return `${hours} ${hour}`;
      }
    }
    else {
      const day = days > 1 ? 'Days' : 'Day';
      return `${days} ${day}`;
    }
  }
}

export const getTimeLeft = (datetime): number => {
  // if (isNaN(datetime)) {
  //   console.log(`here`)
  //   return 0;
  // }

  let time = new Date(datetime).getTime();
  let now = new Date().getTime();
  // console.log(`date=${datetime};time=${time};now=${now}`);

  var ms_diff;
  if (time < now) {
    ms_diff = 0;
  }
  else {
    ms_diff = time - now;
  }
  return ms_diff;
}

export const getPlaceHolder = (dashData) => {
  if (typeof dashData !== 'undefined' && dashData.length > 0) {
    const data = dashData[0];
    if (data.send === 0) {
      return 'https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1659715383420.png';
    }
    else if (data.reseta === 0) {
      return 'https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1659776637535.png'; //placeHolder_Transcribe
    }
    else if (data.saved === 0) {
      return 'https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1659715439246.png'; //placeholderSave
    }
    else if (data.bought === 0) {
      return 'https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1659775813868.png'; // placeHolder_Medicines
    }
    else if (data.delivered === 0) {
      return 'https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1659715463642.png'; //placeHolder_Deliver
    }
    else if (data.taken === 0) {
      return 'https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1659715475651.png'; // placeHolder_Dosage
    }
    else {
      return 'https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1659715489352.png'; // placeHolder_Send
    }
  }
  else {
    return 'https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1659715489352.png'; // placeHolder_Send
  }
}