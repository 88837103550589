import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import useStyles from './styles';
import useWindowDimensions from '../../../utils/windowDimention';
import Loader from "../../../Components/Common/Loader";
import InnerPageHeader from '../../../Components/InnerPageHeader';
import {formatNumberWithComma} from '../../../utils/utils';
import { io } from "socket.io-client";
import rxSearch from '../../../assets/images/common-images/search.png';
import SwipeToDelete from 'react-swipe-to-delete-component';
import Moment from 'moment';
import CheckButton from '../../../assets/images/common-images/checkIcon.png';
import MyButton from '../../../Components/Common/MyButton';
import RecordOn from '../../../assets/images/common-images/recordOn.png';
import RecordOff from '../../../assets/images/common-images/recordOff.png';
import JsonData from '../../../data.json';
import { Grid } from "@mui/material";

import { OTSession, OTPublisher, OTSubscriber, OTStreams } from 'opentok-react';

import Api from '../../../services/Api';
import './style.css';

import {
  updateReferror, sendSocketCommand, getActiveCall, getDeliveryTypes, searchBrandOrGeneric, getCart, getDiscountTypesPerDrugstoreRx, getDrugstoreRxNeutral,
  updateCart, endCall, updateRxInfo, getDrugstoreRx, uploadBought
} from "../../../services/ApiService";
import { routes } from '../../../constants';

let firstClientX = null;
let firstClientY = null;
let clientX = null;
let clientY = null;

const socket = io.connect(process.env.REACT_APP_SOCKET_URL);

socket.on("connect", (data) => {
  // console.log(`socket.data=${JSON.stringify(data)}`)  
})

const GetOrder = ({ }) => {
  const classes = useStyles();
  let navigate = useNavigate();
  const { height, width } = useWindowDimensions();
  const [loader, setLoader] = useState(false);
  const [isAudioEnabled, setIsAudioEnabled] = useState(true);
  const [isVideoEnabled, setIsVideoEnabled] = useState(true);
  const [user, setUser] = useState({firstname: "", lastname: "", address: ""});
  const [isSearchReferror, setIsSearchReferror] = useState(false);
  const [referror, setReferror] = useState();
  const [showSearchReferror, setShowSearchReferror] = useState();
  const [showSearchMember, setShowSearchMember] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [isEdited, setIsEdited] = useState(false);
  const [enableSave, setEnableSave] = useState(false);
  const [deliveryTypes, setDeliveryTypes] = useState();
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [selectedDeliveryType, setSelectedDeliveryType] = useState();
  const [showSearchProduct, setShowSearchProduct] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [products, setProducts] = useState([]);
  const [selectedSkus, setSelectedSkus] = useState([]);
  const [isFinalize, setIsFinalize] = useState(false);
  const [discountTypes, setDiscountTypes] = useState([]);
  const [dsData, setDsData] = useState([]);
  const [showSms, setShowSms] = useState(false);
  const [showMayWe, setShowMayWe] = useState(true);
  const [showText, setShowText] = useState(false);
  const [textToShow, setTextToShow] = useState("");
  const [subTextToShow, setSubTextToShow] = useState("");
  const [currentItem, setCurrentItem] = useState();
  const [showNewCart, setShowNewCart] = useState(false);
  const [showFinalCost, setShowFinalCost] = useState(false);
  const [showDiscount, setShowDiscount] = useState(false);
  const [showUserDetails, setShowUserDetails] = useState(false);
  const [showFinalize, setShowFinalize] = useState(false);
  const [addedItem, setAddedItem] = useState(false);
  const [showSelectDelivery, setShowSelectDelivery] = useState(false);
  const [shownDelivery, setShownDelivery] = useState(false);
  const [updatedUserDetails, setUpdatedUserDetails] = useState(false);
  const [showUpdateUserDetails, setShowUpdateUserDetails] = useState(false);
  const [shownDiscount, setShownDiscount] = useState(false);
  const [selectedDs, setSelectedDs] = useState();
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [discountTypesData, setDiscountTypesData] = useState();
  const [showConfirm, setShowConfirm] = useState(false);
  const [alreadyBought, setAlreadyBought] = useState(false);
  const [isRecordOn, setIsRecordOn] = useState(false);
  const [selectedScriptIndex, setSelectedScriptIndex] = useState(0);
  const [showEditItem, setShowEditItem] = useState(false);
  const [showCapture, setShowCapture] = useState(false);
  const [showScreenCapture, setShowScreenCapture] = useState(false);
  const [screenCapture, setScreenCapture] = useState();

  const [level, setLevel] = useState();
  const [selectedLevel0Id, setSelectedLevel0Id] = useState(-1);
  const [selectedLevel1Id, setSelectedLevel1Id] = useState(-1);
  const [selectedLevel2Id, setSelectedLevel2Id] = useState(-1);
  const [selectedLevel0Label, setSelectedLevel0Label] = useState("");
  const [selectedLevel1Label, setSelectedLevel1Label] = useState("");
  const [selectedLevel2Label, setSelectedLevel2Label] = useState("");
  const [selectedData, setSelectedData] = useState([])
  const [script, setScript] = useState([
    {
      id: 0,
      title: `Objective:`,
      text: ``
    },
    {
      id: 1,
      title: `Notes:`,
      text: ``
    },
  ])
  const [actionData, setActionData] = useState([]);
  const [showActionData, setShowActionData] = useState(false);
  const [controlLabel, setControlLabel] = useState("CONCEPTS");

  const date = new Date(Moment(new Date()).add(1, 'hour'));
  const props = useLocation().state;

  const [data,setData]=useState([]);
  const getData=()=>{
    setData(JsonData)
    setLevel(0);
  }

  useEffect(() => {
    _getSelectedData();
  }, [level])

  useEffect(()=>{
    getData()
  },[])

  const _getSelectedData = () => {
    console.log(`level=${level}; selectedLevel0Id=${selectedLevel0Id}; selectedLevel1Id=${selectedLevel1Id}; selectedLevel2Id=${selectedLevel2Id}`)
    if (typeof level !== 'undefined' && level == 2) {
      console.log(`here=${JSON.stringify(data.map(item => item.layer1).flat().filter(item => item.id == selectedLevel1Id))}`)
      setSelectedData([
        ...data.map(item => item.layer1).flat().filter(item => item.id == selectedLevel1Id).map(item => item.layer2).flat().map(item => {
          return {
            id: item.id,
            code: item.code,
            name: item.name,
            script: item.script,
            action: item.action,
          }
        }),
        ...[
          {
            id: 0,
            name: selectedLevel0Label.toUpperCase()
          }
        ]
      ])
    }
    else if (typeof level !== 'undefined' && level == 0) {
      // console.log('there')
      setSelectedData([...data.map(item => {
          return {
            id: item.id,
            code: item.code,
            name: item.name,
            script: item.script,
            action: []
          }
        })
      ])
    }
    else if (typeof level !== 'undefined' && level == 1) {
      setSelectedData([...data.filter(item => item.id == selectedLevel0Id)[0].layer1.map(item => {
          console.log(`item=${JSON.stringify(item)}`)
          return {
            id: item.id,
            code: item.code,
            name: item.name,
            script: item.script,
            action: []
          }
        }),
        ...[
          {
            id: 0,
            name: 'CONCEPTS'
          }
        ]
      ])
    }
  }

  const apiKey = '476097011';
  const sessionId = '1_MX40NzYwOTcwMX5-MTY2ODg0MjIxODM2OX55emJXZ2JYNmI1Nk5DaU5uUi9jQkNVVlZ-fg1';
  const token = 'T1==cGFydG5lcl9pZD00NzYwOTcwMSZzaWc9ZmNhZjlmMmJiNDhmOTIyMDFjMGJiN2RmMmQzNWFiNGRmODI2OGZlMTpzZXNzaW9uX2lkPTFfTVg0ME56WXdPVGN3TVg1LU1UWTJPRGcwTWpJeE9ETTJPWDU1ZW1KWFoySllObUkxTms1RGFVNXVVaTlqUWtOVlZsWi1mZyZjcmVhdGVfdGltZT0xNjY4ODQyMjY2Jm5vbmNlPTAuOTM3Mjg3NTA1NTc5MzgzJnJvbGU9cHVibGlzaGVyJmV4cGlyZV90aW1lPTE2NzE0MzQyNjUmaW5pdGlhbF9sYXlvdXRfY2xhc3NfbGlzdD0=1';

  const w = 400;
  const h = 470;

  useEffect(() => {
    const disablePinchZoom = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault()
      }
    }
    document.addEventListener("touchmove", disablePinchZoom, { passive: false })
    return () => {
      document.removeEventListener("touchmove", disablePinchZoom)
    }
  }, [])
  
  useEffect(() => {
    _loadData()
    window.history.pushState(null, null, document.URL);
    window.addEventListener('popstate', function(event) {
      console.log('hello')
      _onBackPress();
    });
    // console.log(`getorder.userData=${JSON.stringify(props.userData)}`)
    socket.emit('online',  {userId : props.userData.userId});
    socket.on('memberEndCall', function (data){
      _onBackPress();
    });
    socket.on('sendFile', function (data) {
      console.log(`sendFile; data=${data}`)
      setShowScreenCapture(true)
      setScreenCapture(data)
    })
  }, [])

  const _loadData = () => {
    getActiveCall({})
    .then(({data}) => {
      _onSaveButtonPress(data.data.userId || data.data.id);
      setTimeout(() => {
        _onSelectPress(data.data)
      }, 1000)
    })
    .catch(e => {
      console.warn('get active call ERROR', e.message);
    });
    getDeliveryTypes({})
    .then(({data}) => {
      const selectedDeliveryType = data.data.filter(item => item.isSelected)[0];
      setDeliveryTypes(data.data);
      setDeliveryFee(selectedDeliveryType.price);
      setSelectedDeliveryType(selectedDeliveryType);
    })
    .catch(e => {
      console.warn('get delivery types ERROR', e.message);
    });
  }

  const _renderScriptItem = (item,index) => {
    return <div style={{display: 'flex', flexDirection: 'column', height: h / 1.5, alignItems: 'center'}}>
      <p key={`script_title_${index}`} style={{width: 300, fontSize: 40, color: '#dc2828', marginTop: 20, textAlign: 'center'}}>{item.title}</p>
      <p key={`script_${index}`} style={{width: 300, fontSize: 40, color: '#404040', marginTop: 20, textAlign: 'center', height: h / 1.5}}><div className="new-line">{item.text}</div></p>
      {index < script.length - 1 && <div style={{height: 2, backgroundColor: '#dc2828', width: 360, position: 'relative', bottom: 0}}></div>}
    </div>
  }

  const _renderActionData = (isShort) => {
    console.log(`actionData=${JSON.stringify(actionData)}`)
    return actionData.map((a, ai) => <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <p key={`action_q_${a.id}`} style={{width: isShort ? w * 0.85 : w * 1.5, fontSize: 24, color: '#dc2828', marginTop: 20, textAlign: 'center'}}>{ai+1}. {isShort ? a.shortQuestion : a.question}</p>
        {
          a.choices.map((item, index) => {
            return <div style={{display: "flex", flexDirection: 'row', alignItems: 'center', height: 40, width: 140}}>
              <input type="checkbox" style={{transform: 'scale(2)', marginBottom: 5}}/>
              {/* <p key={`choice_${index}`} style={{fontSize: 18, color: '#404040', marginTop: 20, textAlign: 'left', height: 40, marginLeft: 20}}>{String.fromCharCode(index + 'A'.charCodeAt(0))}.</p> */}
              <p key={`choice_${index}`} style={{fontSize: 18, color: '#404040', marginTop: 20, textAlign: 'left', height: 40, marginLeft: 20}}>{item.answer}</p>
            </div>
          })
        }
      </div>
    )
  }

  const _onSendSocket = (text, subText, command, data) => {
    console.log(`command=${command}, data=${data}`)
    const params = data ?  {
      userId: user.id,
      command: command,
      data: data
    } : { 
      userId: user.id,
      command: command 
    }
    
    sendSocketCommand(params)
    .then(({data}) => {
    })
    .catch(e => {
      console.warn('send socket ERROR', e.message);
    });
  }

  useEffect(() => {
    if (showFinalCost) {
      _onDrugstorePress(true);
    }
  }, [showFinalCost])

  const _getCart = (userId) => {
    getCart({userId})
    .then(({data}) => {
      setSelectedSkus(data.data.map((item) => {
        return {
          ...item,
          isSelected: true
        }
      }))
      getDiscountTypesPerDrugstoreRx({userId: userId})
      .then(({data}) => {
        setDiscountTypes(data);
        getDrugstoreRxNeutral({})
        .then(({data}) => {
          setDsData(data.data);
          _onDrugstorePress(false);
        })
        .catch(e => {
          console.warn('get drugstore ERROR', e.message);
        });
      })
      .catch(e => {
        console.warn('get discount types ERROR', e.message);
      });
    })
    .catch(e => {
      console.warn('get cart ERROR', e.message);
    });
  }

  const _onSelectPress = (rowData) => {
    let result = {id: rowData.userId || rowData.id, 
      firstname: rowData.firstname,
      lastname: rowData.lastname,
      displayName: rowData.displayName, 
      avatarBig: rowData.avatarBig, 
      avatarSmall: rowData.avatarSmall,
      address: rowData.address,
      phoneNumber: rowData.phoneNumber,
      scPwdId: rowData.scPwdId
    };
    // console.log(`rowData=${JSON.stringify(rowData)}`)
    if (isSearchReferror) {
      setReferror(result);
      setShowSearchReferror(false);
      setShowSearchMember(false);
      setDataSource([]); 
      if (typeof referror !== 'undefined' && typeof referror.id !== 'undefined') {
        updateReferror({
          userId: user.id,
          referredById: referror.id
        })
        .then(({data}) => {
          setIsEdited(false);
          setEnableSave(false);

          _onSendSocket('Info', 'saved', "updateUserDetails", user.phoneNumber)        
        })
        .catch(e => {
          console.warn('update referror ERROR', e.message);
        });
      }
    }
    else {
      setUser(result);
      setShowSearchMember(false);
      setDataSource([]);
      setReferror(rowData.Referror);
      setIsEdited(false);
      _getCart(rowData.userId);
    }
  }

  const onSearchChange = async (searchText) => {
    if (searchText.trim() == "") {
      setProducts([]);
      setSearchText(searchText);
    }
    else {
      const response = await searchBrandOrGeneric({search: encodeURI(searchText.replace(' ', '+'))});
      // console.log(`response=${JSON.stringify(response)}`)
      if (response) {
        setProducts(response.data.data);
        setSearchText(searchText);
      }
    }
  }

  const renderProductItem = (rowData,index) => {
    const width = 500;
    return <div onClick={() => _onSelectProductPress(rowData)}>
      <div style={{flex: 1,
        flexDirection: 'row',
        height: 70,
        alignItems: 'center',
        marginLeft: 10,
        width: width - 30, height: 40,
        cursor: 'pointer'
        }} 
      >
        <p style={{ width: width - 100, fontFamily: 'CanaroBook',
          fontSize: 16,
          color: '#dc2828',
          backgroundColor: 'transparent',
          paddingLeft: 10,
          paddingRight: 10,
          textAlign: 'left',
          marginLeft: 5
        }}>{rowData.brand.name} {rowData.dosage.name} ({rowData.generic.name})</p>
      </div>
    </div>
  }

  const _onSelectProductPress = (rowData) => {
    let result = {
      ...rowData,
      quantity: 1
    };
    if (selectedSkus.filter(item => item.brand.id == rowData.brand.id).length == 0) {
      const selectedSkus1 = [...selectedSkus];
      selectedSkus1.push(result)
      setSelectedSkus(selectedSkus1)
      setShowSearchProduct(false);
      setSearchText("");
      setProducts([]);
      setIsFinalize(false);
      setEnableSave(true);
      setTextToShow(`${rowData.brand.name} ${rowData.dosage.name} (${rowData.generic.name})`);
      setSubTextToShow(`₱${formatNumberWithComma(rowData.brand.price,2)}`);
      setShowText(true);
      setShowMayWe(false);
      setCurrentItem(result);
      _onSendSocket('Medicine', 'added', 'addMedicine', rowData.brand.id);
      _onDrugstorePress(false);
    }
  }

  const _onDrugstorePress = async (showFinalCost) => {
    const response = await getDrugstoreRx();
    if (response) {
      if (response.data.data.length > 0) {
        setDsData(response.data.data);
        let data = [];
        // console.log(`dsData=${JSON.stringify(response.data.data)}`)
        // console.log(`sku=${JSON.stringify(sku)}`)
        response.data.data.map(store => {
          let total = 0;
          let details = [];      
          selectedSkus.filter(item => item.quantity > 0).map(selected => {
            // const storeItem = store.details.find(i => i.generic.id == selected.generic.id && i.dosage.id == selected.dosage.id)
            // const storeItem = this.state.isGeneric ? store.details.find(i => i.generic.id == selected.generic.id && i.dosage.id == selected.dosage.id) : store.details.find(i => i.brand.id == selected.brand.id)
            const storeItem = store.details.find(i => i.brand.id == selected.brand.id)
            if (storeItem) {
              total += storeItem.brand.price * selected.quantity;
              details.push({
                ...storeItem,
                quantity: selected.quantity
              })
            }
          })
          data.push({
            ...store,
            total,
            details: [...details]
          })
        })
        ///// buy now
        const selected = data.filter(item => !item.hasBorder)
        console.log(`selected=${JSON.stringify(selected)}`)
        if (selected.length > 0) {
          _onDsRowPress(selected[0], showFinalCost);
        }   
      }
    } 
  }

  const _onDsRowPress = (rowData, showFinalCost) => {
    // console.log(`rowData=${JSON.stringify(rowData)}`)
    // console.log(`discountTypes=${JSON.stringify(discountTypes)}`)
    const totalPriceHere = rowData.details.reduce((a,v) =>  a = a + v.quantity * v.brand.price, 0);
    // console.log(`totalPriceHere=${totalPriceHere}`)
    let totalDiscountHere = 0;
    const tempDT2 = discountTypes.filter(item => item.drugstore_id == rowData.id && item.is_volume == 1 && totalPriceHere >= item.volume)
      .sort((a, b) => a.volume < b.volume ? 1 : -1)
    let tempDT3 = [...tempDT2.slice(0,1), ...discountTypes.filter(item => item.drugstore_id == rowData.id && item.is_volume == 0)].sort((a, b) => a.sort_order > b.sort_order ? 1 : -1);
    // console.log(`tempDT3=${JSON.stringify(tempDT3)}`)
    const tempDT = tempDT3.map(item => {
      if (rowData.discount == 0 && item.id == 1) { //no member discount
        //force amount to 0 for later filtering
        return {
          ...item,
          amount: 0
        }
      }
      else {
        if (item.is_percentage == 1 && totalPriceHere > totalDiscountHere + (totalPriceHere - totalDiscountHere) * item.value) { //percentage
          const seniorDiscount = (totalPriceHere - totalDiscountHere) * item.value;
          totalDiscountHere += seniorDiscount;
          return {
            ...item,
            discountValue: seniorDiscount,
            subTotal: totalPriceHere - totalDiscountHere,
            isUsed: true
          }
        }
        else if (item.is_percentage == 0 && totalPriceHere > totalDiscountHere + item.amount) {
          totalDiscountHere += item.amount;
          return {
            ...item,
            discountValue: item.amount,
            subTotal: totalPriceHere - totalDiscountHere,
            isUsed: true
          }
        }
        else {
          return {
            ...item,
            isUsed: false
          }
        }
      }
    }).filter(item => item.isUsed);
    
    // console.log(`tempDT=${JSON.stringify(tempDT)}; totalDiscount=${totalDiscountHere}`)
    console.log(`shownDiscount=${shownDiscount}; showFinalCost=${showFinalCost}`)
    if (shownDiscount) {
      setSelectedDs(rowData);
      setTotalPrice(totalPriceHere);
      setTotalDiscount(totalDiscountHere);
      setDiscountTypesData(tempDT);
      setShowFinalCost(true);
      setShowDiscount(false);
      setShowNewCart(false);
    }
    else {
      setSelectedDs(rowData);
      setTotalPrice(totalPriceHere);
      setTotalDiscount(totalDiscountHere);
      setDiscountTypesData(tempDT);
      setShowFinalCost(false);
    }
  }

  const onQuantityChange = (rowData, quantity) => {
    if (Number(quantity) < 1000) {
      let tempGamot = [...selectedSkus
      .map(item => {
        // if (item.generic.id == rowData.generic.id && item.dosage.id == rowData.dosage.id && item.quantity < 100) {
        if (item.brand.id == rowData.brand.id) {
          return {
            ...item,
            quantity: Number(quantity)
          }
        }
        else {
          return item
        }
      })];
      setSelectedSkus(tempGamot);
      let c = {...currentItem}
      c.quantity = Number(quantity)
      setCurrentItem(c);
    }
  }

  const onDelete = (customProps) => {
    console.log(`selectedSkus=${JSON.stringify(selectedSkus)}; customerProps=${JSON.stringify(customProps)}`)
    const rowData = customProps.item;
    const selectedSkus1 = [...selectedSkus].filter(item => item.brand.id !== rowData.brand.id);
    setSelectedSkus(selectedSkus1);
  }

  const _onSaveButtonPress = (userId) => {
    updateCart({
      userId: userId,
      details: selectedSkus.filter(item => item.quantity > 0).map((item, index) => {
        return {
          brandedId: item.brand.id,
          quantity: item.quantity,
          sortOrder: index
        }
      }),
      eta: new Date(date).toISOString().slice(0,-5)
    })
    .then(({data}) => {
      setSelectedSkus(selectedSkus.filter(item => item.quantity > 0))
      setEnableSave(false);  
      setShowText(false);
      setShowNewCart(true);    
      setAddedItem(true);
    })
    .catch(e => {
      console.warn('update cart ERROR', e.message);
    });
  }

  const _renderCurrentItem = (item, index) => {
    const maxText = 35;
    const truncate = (input) => input.length > maxText ? `${input.substring(0, maxText)}...` : input;
    console.log(`item=${JSON.stringify(item)}`)
    let name = truncate(`${item.brand.name} ${item.dosage.name} (${item.generic.name})`);
    return <div style={{marginTop: 230}}>
      <div key={`currentItem_${index}`} >
        <>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 1.25, height: 30, marginBottom: 5}}>
            <p style={{maxHeight: 20, left: 0, color: '#404040', fontSize: 18, width: w * 1.25, textAlign: 'left', fontWeight: '600'}} >{name}</p>
            {/* <p style={{fontWeight: '500', fontSize: 18, width: '35%', marginLeft: 5, color: '#404040', textAlign: 'right'}}>₱{formatNumberWithComma(item.brand.price * item.quantity, 2)}</p>
            <p style={{fontWeight: '500', fontSize: 11, marginLeft: 1, color: '#404040', textAlign: 'right'}}>*</p> */}
          </div>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: w * 1.25, height: 30, marginBottom: 15}}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', width: w * 0.95}}>
              {/* <p style={{color: '#dc2828', fontSize: 18, textAlign: 'left', marginTop: 5}} >Brand:</p> */}
              <form style={{marginBottom: 5}}>
                <input type="numeric" style={{width: 50, fontSize: 16, textAlign: 'center'}} value={item.quantity} 
                  onChange={(event) => onQuantityChange(item, event.target.value)}
                  maxLength="3"
                />
              </form>
              <p style={{color: '#404040', fontSize: 18, textAlign: 'left', marginTop: 5, marginLeft: 5}} >@ ₱{formatNumberWithComma(item.brand.price, 2)}</p>
            </div>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', width: w * 0.35, height: 30, marginTop: 0}}>
              <p style={{color: '#404040', fontSize: 18, textAlign: 'right'}} >₱{formatNumberWithComma(item.brand.price * item.quantity, 2)}</p>
            </div>
          </div> 
        </>
      </div>
    </div>
  }

  const _onBackPress = () => {
    _onSendSocket("End", "Call", "endCall", null);
    endCall({})
    .then(({data}) => {
    })
    .catch(e => {
      console.warn('end call ERROR', e.message);
    });
    navigate(routes.home)
  }

  const sendFinalize = (show) => {
    console.log(`addedItem=${addedItem}`)
    if (addedItem) {
      _onDrugstorePress(true);
      setShowFinalize(true);
      setShowScreenCapture(false);
      _onSendSocket('Finalize', '', 'finalCart', null)
      setTimeout(() => {
        setShowFinalize(false);
      }, 2000)
    }
  }

  const sendDiscount = () => {
    console.log(`updatedUserDetails=${updatedUserDetails}`)
    if (updatedUserDetails) {
      setShowDiscount(true);
      setShownDiscount(true);
      setShowNewCart(false);
      setShowUpdateUserDetails(false);
      _onSendSocket('Show', 'Discount', 'showDiscount', null)
    }
  }

  const _onDeliveryTypePress = (rowData) => {
    let tempGamot = [...deliveryTypes
    .map(item => {
      if (item.id == rowData.id) {
        return {
          ...item,
          isSelected: true
        }
      }
      else {
        return {
          ...item,
          isSelected: false
        }
      }
    })];
    setDeliveryTypes([...tempGamot]);
  }

  const _onSelectDelFee = () => {
    const selected = deliveryTypes.filter(item => item.isSelected)[0]
    setShowSelectDelivery(false);
    setShownDelivery(true);
    setDeliveryFee(selected.price);
    setSelectedDeliveryType(selected);
    setTextToShow(`DELIVERY FEE: (${selected.name})`);
    setSubTextToShow(`₱${formatNumberWithComma(selected.price,2)}`);
    setShowMayWe(false);
    setShowText(true);
    setShowUserDetails(false);
    setShowDiscount(false);
    _onSendSocket('Delivery Fee', 'saved', 'setDelivery', selected.id);
  }
  const setFirstName = (firstname) => {
    const tempUser = {...user}
    tempUser.firstname = firstname
    setUser(tempUser)
  }

  const setLastName = (lastname) => {
    const tempUser = {...user}
    tempUser.lastname = lastname
    setUser(tempUser)
  }

  const setAddress = (address) => {
    const tempUser = {...user}
    tempUser.address = address
    setUser(tempUser)
  }

  const setPhoneNumber = (phoneNumber) => {
    const tempUser = {...user}
    tempUser.phoneNumber = phoneNumber
    setUser(tempUser)
  }

  const setScPwdId = (scPwdId) => {
    const tempUser = {...user}
    tempUser.scPwdId = scPwdId
    setUser(tempUser)
  }

  const updateUser = () => {
    updateRxInfo({
      userId: user.id,
      firstName: user.firstname,
      lastName: user.lastname,
      address: user.address,
      phoneNumber: user.phoneNumber,
      scPwdId: user.scPwdId
    })
    .then(({data}) => {
      getDiscountTypesPerDrugstoreRx({userId: user.id})
      .then(({data}) => {
        console.log(`getDiscountTypesPerDrugstoreRx.data=${JSON.stringify(data)}`)
        setDiscountTypes(data);
        setUpdatedUserDetails(true);
        setShowMayWe(false);
        setShowDiscount(false);
        setShowUserDetails(true);
        setShowText(false);
        setShowNewCart(false);  
        _onSendSocket('Info', 'saved', "updateUserDetails", user.phoneNumber)     
        setTimeout(() => {
          _onDrugstorePress(false);
        }, 1000)
      })
      .catch(e => {
        console.warn('get discount types ERROR', e.message);
      });
    })
    .catch(e => {
      console.warn('update info ERROR', e.message);
    });
  }

  const renderItem = (item, index) => {
    // console.log('here')
    const maxText = 28;
    const truncate = (input) => input.length > maxText ? `${input.substring(0, maxText)}...` : input;
    let name = truncate(`${item.brand.name} ${item.dosage.name} (${item.generic.name})`);
    return <div className="list-group">
      {/* <SwipeToDelete key={index} onDelete={onDelete} item={item}> */}
        <div key={index}>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.9, height: 30, marginBottom: 5}}>
            <p style={{maxHeight: 20, left: 0, color: '#404040', fontSize: 18, width: '100%', textAlign: 'left', fontWeight: '600'}} >{name}</p>
            {/* <p style={{fontWeight: '500', fontSize: 18, width: '35%', marginLeft: 5, color: '#404040', textAlign: 'right'}}>₱{formatNumberWithComma(item.brand.price * item.quantity, 2)}</p>
            <p style={{fontWeight: '500', fontSize: 11, marginLeft: 1, color: '#404040', textAlign: 'right'}}>*</p> */}
          </div>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: w * 0.9, height: 30, marginBottom: 15}}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', width: w * 0.6}}>
              {/* <p style={{color: '#dc2828', fontSize: 18, textAlign: 'left', marginTop: 5}} >Brand:</p> */}
              <p style={{color: '#404040', fontSize: 18, textAlign: 'left', marginTop: 5}} >{item.quantity} @ ₱{formatNumberWithComma(item.brand.price, 2)}</p>
            </div>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', width: w * 0.3, height: 30, marginTop: 0}}>
              <p style={{color: '#404040', fontSize: 18, textAlign: 'right'}} >₱{formatNumberWithComma(item.brand.price * item.quantity, 2)}</p>
            </div>
          </div> 
        </div>
      {/* </SwipeToDelete> */}
    </div>
  }

  useEffect(() => {
    console.log(`showConfirm=${showConfirm}; alreadyBought=${alreadyBought}`)
    if (showConfirm && !alreadyBought) {
      _buy();
    }
  }, [showConfirm])

  // console.log(`showText= ${showText} && showUserDetails= ${showUserDetails} && showNewCart= ${showNewCart} && showFinalCost= ${showFinalCost} && showDiscount= ${showDiscount} showSms=${showSms}`)

  const _buy = async () => {
    const selected = selectedDs.details;
    console.log(`user=${JSON.stringify(user)}`)
    const param = {
      userId: user.userId,
      total: totalPrice,
      eta: new Date(date).toISOString().slice(0,-5),
      deliveryFee: Number(deliveryFee),
      taxes: 0, //Number((totalPrice - totalDiscount) * taxPercentage), 
      address: user.address,
      totalDiscount: totalDiscount,
      drugstoreId: selectedDs.id,
      details: selected.map((item) => {
        return {
          genericsId: item.generic.id,
          brandedId: item.brand.id,
          dosageId: item.dosage.id,
          quantity: item.quantity,
          price: item.brand.price
        }
      }),
      discounts: discountTypesData.filter(item => item.amount > 0).map((item) => {
        return {
          discountTypeId: item.id,
          isOneTime: item.is_one_time,
          isPercentage: item.is_percentage,
          isSeniorPwd: item.is_senior_pwd,
          amount: item.amount,
          value: item.value,
          discountValue: item.discountValue,
          subTotal: item.subTotal,
          sortOrder: item.sort_order
        }
      })
    }

    uploadBought(param)
    .then((data) => {
      setShowFinalCost(false);
      setAlreadyBought(true);
    })
    .catch(e => {
        console.warn('upload bought ERROR', e.message);
    });
  }

  const renderItemSwipeToDelete = (item, index) => {
    const maxText = 35;
    const truncate = (input) => input.length > maxText ? `${input.substring(0, maxText)}...` : input;
    let name = truncate(`${item.brand.name} ${item.dosage.name} (${item.generic.name})`);
    return <div className="list-group" style={{backgroundColor: 'white'}}>
      <div key={index} onDelete={onDelete} item={item}>
        <>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.95, height: 30, marginBottom: 5}}>
            <p style={{maxHeight: 20, left: 0, color: '#404040', fontSize: 18, width: '100%', textAlign: 'left', fontWeight: '600'}} >{name}</p>
            {/* <p style={{fontWeight: '500', fontSize: 18, width: '35%', marginLeft: 5, color: '#404040', textAlign: 'right'}}>₱{formatNumberWithComma(item.brand.price * item.quantity, 2)}</p>
            <p style={{fontWeight: '500', fontSize: 11, marginLeft: 1, color: '#404040', textAlign: 'right'}}>*</p> */}
          </div>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: w * 0.95, height: 30, marginBottom: 15}}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', width: w * 0.65}}>
              {/* <p style={{color: '#dc2828', fontSize: 18, textAlign: 'left', marginTop: 5}} >Brand:</p> */}
              <form style={{marginBottom: 5}}>
                <input type="numeric" style={{width: 50, fontSize: 16, textAlign: 'center'}} value={item.quantity} 
                  onChange={(event) => onQuantityChange(item, event.target.value)}
                  maxLength="3"
                />
              </form>
              <p style={{color: '#404040', fontSize: 18, textAlign: 'left', marginTop: 5, marginLeft: 5}} >@ ₱{formatNumberWithComma(item.brand.price, 2)}</p>
            </div>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', width: w * 0.35, height: 30, marginTop: 0}}>
              <p style={{color: '#404040', fontSize: 18, textAlign: 'right'}} >₱{formatNumberWithComma(item.brand.price * item.quantity, 2)}</p>
            </div>
          </div> 
        </>
      </div>
    </div>
  }

  return (
    <div className={classes.getOrderContainer}>
      <div className={classes.getOrderContainer}>
          <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center', border: 'solid 5px #dc2828',
            marginTop: 50, height: 800 }}>
            <div style={{ width: '100%', alignItems: 'center', justifyContent: 'center', minWidth: 400, maxWidth: 400, height: 800, border: 'solid 2px #dc2828' }}>
              <>
              <div style={{ display: "flex", backgroundColor: 'transparent', marginTop: 3, marginLeft: 2, alignItems: 'center', flexDirection: 'column', justifyContent: 'flex-start', flexDirection: 'column' }}>
                <div style={{ display: "flex", backgroundColor: 'transparent', height: w, alignItems: 'center', flexDirection: 'column', justifyContent: 'flex-start' }}>
                  <OTSession apiKey={apiKey} sessionId={sessionId} token={token} audioTrack={true} publishAudio={true} publishVideo={true} videoTrack={true}>
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', height: w, width: w - 2, marginRight: 2, marginTop: -1, top: 0,
                      borderTopLeftRadius: 0, borderTopRightRadius: 0, overflow: 'hidden', backgroundColor: 'gray'}}
                    >
                      <OTStreams>
                        <OTSubscriber  properties={{ insertMode: "append", width: w-2, height: w }}/>
                      </OTStreams>
                    </div>
                    <div style={{display: 'flex', width: 125, height: 125, justifyContent: 'center', alignItems: 'center', position: 'relative', paddingTop: 0,  bottom: 400, marginLeft: 1, borderTopRightRadius: 0, overflow: 'hidden'}}>
                      <OTPublisher properties={{
                          publishAudio: isAudioEnabled,
                          publishVideo: isVideoEnabled,
                          width: 125, height: 125,
                        }}
                      />
                    </div>
                  </OTSession>
                 
                </div>
                <div style={{flexDirection: 'column', width: w, height: h - 40, backgroundColor: 'transparent', justifyContent: 'flex-start', 
                  alignItems: 'center', borderRadius: 20, marginTop: 0}} 
                >
                  <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', minHeight: w * 0.85, width: w, top: 0,
                    borderTopLeftRadius: 0, borderTopRightRadius: 0, overflow: 'hidden'}}
                  >
                    {showText  && <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#dc2828', fontSize: 18, position: 'absolute', bottom: h * 0.38}}>{textToShow}</p>}
                    {showText  && <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#404040', fontSize: 22, position: 'absolute', bottom: h * 0.30}}>{subTextToShow}</p>}
                    {showMayWe && <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#404040', fontSize: 22, position: 'absolute', bottom: h * 0.38}}>Welcome Doc</p>}
                    {showMayWe && !showText && <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#404040', fontSize: 22, position: 'absolute', bottom: h * 0.30}}>to MDClub</p>}
                    {!showText && showNewCart && !showFinalCost && !showDiscount && !showUserDetails && <div style={{marginTop: 10, marginLeft: 0, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <div style={{textDecoration: 'none', flexDirection: 'column', width: w, justifyContent: 'center', alignItems: 'center'}} >
                          {selectedSkus.length > 0 && <div
                              style={{width: w - 2, height: h * 0.8, overflow: 'auto', backgroundColor: 'transparent', borderRadius: 20, justifyContent: 'flex-start', alignItems: 'center'}}
                            >
                              <div style={{display: "flex", width: w * 0.9, alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                                <div style={{marginTop: 20, width: w * 0.9, alignItems: 'center', marginLeft: w * 0.05}}>
                                    <div style={{minHeight: 20}}>
                                      { selectedSkus.map((item,index) => renderItem(item, index)) }
                                    </div>
                                    <div style={{marginTop: 10, display: 'flex', flexDirection: 'column', width: w * 0.9, justifyContent: 'center', alignItems: 'center', marginTop: 0}}>
                                      <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#dc2828', fontSize: 28, marginTop: 20, height: 20}} >Total Price:</p>
                                      <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#404040', fontSize: 50}} >₱{formatNumberWithComma(selectedSkus.reduce((a,v) =>  a = a + v.quantity * v.brand.price, 0 ),2)}</p>
                                    </div>
                                </div> 
                              </div>   
                            </div> 
                          }
                        </div>      
                      </div>
                    }
                    {!showText && showUserDetails && !showNewCart && !showFinalCost && !showDiscount && <div style={{marginTop: 10, marginLeft: 20, display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: 'transparent'}}>
                        <div style={{textDecoration: 'none', flexDirection: 'column', width: w, justifyContent: 'center', alignItems: 'center'}} >
                          <div style={{width: w * 0.8, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 20}}>
                            <p style={{fontSize: 16, color: '#dc2828', width: '40%', textAlign: 'left'}}>First Name:</p>
                            <p style={{fontSize: 20, color: '#404040', width: '60%', marginLeft: 5, textAlign: 'left'}}>{user.firstname}</p>
                          </div>
                          <div style={{width: w * 0.8, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                            <p style={{fontSize: 16, color: '#dc2828', width: '40%', textAlign: 'left'}}>Last Name:</p>
                            <p style={{fontSize: 20, color: '#404040', width: '60%', marginLeft: 5, textAlign: 'left'}}>{user.lastname}</p>
                          </div>
                          <div style={{width: w * 0.8, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                            <p style={{fontSize: 16, color: '#dc2828', width: '40%', textAlign: 'left'}}>Phone #:</p>
                            <p style={{fontSize: 20, color: '#404040', width: '60%', marginLeft: 5, textAlign: 'left'}}>{user.phoneNumber}</p>
                          </div>
                          {user.address && user.address !== "" && <div style={{width: w * 0.8, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start'}}>
                              <p style={{fontSize: 16, color: '#dc2828', width: '40%', textAlign: 'left'}}>Address:</p>
                              <p style={{fontSize: 20, color: '#404040', width: '60%', marginLeft: 5, textAlign: 'left'}}>{user.address}</p>
                            </div>
                          }
                          { user.scPwdId &&
                            <div style={{width: w * 0.8, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                              <p style={{fontSize: 16, color: '#dc2828', width: '40%', textAlign: 'left'}}>SC/PWD ID#:</p>
                              <p style={{fontSize: 20, color: '#404040', width: '60%', marginLeft: 5, textAlign: 'left'}}>{user.scPwdId}</p>
                            </div>
                          }
                          {typeof user.Referror !== 'undefined' && user.Referror &&
                            <div style={{width: w * 0.8, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                              <p style={{fontSize: 16, color: '#dc2828', width: '40%', textAlign: 'left'}}>Referror:</p>
                              <p style={{fontSize: 20, color: '#404040', width: '60%', marginLeft: 5, textAlign: 'left'}}>{user.Referror.firstname} {user.Referror.lastname}</p>
                            </div>
                          }
                        </div>
                      </div>
                    }
                    {!showText && showDiscount && <div style={{marginTop: 10, marginLeft: 0, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <div style={{textDecoration: 'none', flexDirection: 'column', width: w, justifyContent: 'center', alignItems: 'center'}} >
                          <div style={{marginTop: 20, width: w * 0.95, alignItems: 'center', marginLeft: w * 0.025}}>
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.95, height: 25}}>
                              <p style={{left: 10, color: '#404040', fontSize: 18, width: '65%', textAlign: 'left', fontWeight: '600'}} >TOTAL PRICE:</p>
                              <p style={{fontWeight: '600', fontSize: 20, width: '35%', marginRight: 5, color: '#404040', textAlign: 'right'}}>₱{formatNumberWithComma(totalPrice,2)}</p>
                            </div>
                            <div style={{marginTop: 40}}>
                              {discountTypesData.map((item, index) => {
                                return <>
                                { item.name.toUpperCase().includes('SENIOR') &&
                                  <div style={{height: 20}} />
                                }
                                <div key={index} style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.95, height: 35}}>
                                  { item.is_percentage == 1 && <p style={{left: 10, color: '#404040', fontSize: 14, width: '70%', textAlign: 'left', fontWeight: '500'}} >{index+1}. {item.name.toUpperCase()} ({formatNumberWithComma(item.amount,0)}%):</p> }                    
                                  { item.is_percentage == 0 && <p style={{left: 10, color: '#404040', fontSize: 14, width: '70%', textAlign: 'left', fontWeight: '500'}} >{index+1}. {item.name.toUpperCase()}:</p> }                    
                                  <p style={{fontWeight: '500', fontSize: 18, width: '30%', marginRight: 5, color: '#404040', textAlign: 'right'}}>₱{formatNumberWithComma(item.discountValue,2)}</p>
                                </div>
                                {/* { index < discountTypesData.length - 1 &&
                                  <div key={`st${index}`} style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.95, height: 25}}>
                                    <p style={{left: 10, color: '#404040', fontSize: 15, width: '65%', textAlign: 'left', fontWeight: '500'}} >Sub-Total:</p>
                                    <p style={{fontWeight: '500', fontSize: 18, width: '35%', marginRight: 5, color: '#404040', textAlign: 'right'}}>₱{formatNumberWithComma(item.subTotal,2)}</p>
                                  </div>
                                } */}
                                { item.name.toUpperCase().includes('REFERRAL') &&
                                  <div style={{height: 20}} />
                                }
                                </>
                              })}
                              <div style={{marginTop: 10, display: 'flex', flexDirection: 'column', width: w * 0.95, justifyContent: 'center', alignItems: 'center', marginTop: 0}}>
                                <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#dc2828', fontSize: 28, marginTop: 20, height: 20}} >TOTAL DISCOUNTS:</p>
                                <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#dc2828', fontSize: 50}} >₱{formatNumberWithComma(totalDiscount,2)}</p>
                              </div>
                            </div>
                          </div> 
                        </div>
                      </div>
                    }
                    {!showSms && !showText && showFinalCost && <div style={{marginTop: 0, marginLeft: 0, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <div style={{textDecoration: 'none', flexDirection: 'column', width: w - 5, justifyContent: 'center', alignItems: 'center', height: h * 0.83, overflow: 'auto'}} >
                          <div style={{display: "flex", width: w * 0.9, alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                            <div style={{marginTop: 20, width: w * 0.9, alignItems: 'center', marginLeft: w * 0.05}}>
                                { selectedDs.details.map((item,index) => renderItem(item, index)) }
                                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.9, height: 30}}>
                                  <p style={{left: 10, color: '#404040', fontSize: 17, width: '65%', textAlign: 'left', fontWeight: '500'}} >TOTAL PRICE:</p>
                                  <p style={{fontWeight: '500', fontSize: 20, width: '35%', marginRight: 5, color: '#404040', textAlign: 'right'}}>₱{formatNumberWithComma(totalPrice,2)}</p>
                                </div>
                                {totalDiscount > 0 && <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.9, height: 25}}>
                                    <p style={{left: 10, color: '#404040', fontSize: 15, width: '65%', textAlign: 'left', fontWeight: '500'}} >TOTAL DISCOUNTS:</p>
                                    <p style={{fontWeight: '500', fontSize: 18, width: '35%', marginRight: 5, fontStyle: 'italic', color: '#404040', textAlign: 'right'}}>₱{formatNumberWithComma(-totalDiscount,2)}</p>
                                  </div>
                                }
                                {totalDiscount > 0 && <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.9, height: 25, marginBottom: 10}}>
                                    <p style={{left: 10, color: '#404040', fontSize: 15, width: '65%', textAlign: 'left', fontWeight: '500'}} >NET AMOUNT:</p>
                                    <p style={{fontWeight: '500', fontSize: 20, width: '35%', marginRight: 5, color: '#dc2828', textAlign: 'right'}}>₱{formatNumberWithComma(totalPrice-totalDiscount,2)}</p>
                                  </div>
                                }
                                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.9, height: 25}}>
                                  <p style={{left: 10, color: '#404040', fontSize: 15, width: '65%', textAlign: 'left', fontWeight: '500'}} >DELIVERY FEE ({selectedDeliveryType.name}):</p>
                                  <p style={{fontWeight: '500', fontSize: 18, width: '35%', marginRight: 5, color: '#404040', textAlign: 'right'}}>₱{formatNumberWithComma(deliveryFee,2)}</p>
                                </div>
                                <div style={{marginTop: 10, display: 'flex', flexDirection: 'column', width: w * 0.9, justifyContent: 'center', alignItems: 'center', marginTop: 0}}>
                                  <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#dc2828', fontSize: 28, marginTop: 20, height: 20}} >Pay only:</p>
                                  <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#404040', fontSize: 50}} >₱{formatNumberWithComma((totalPrice - totalDiscount) + Number(deliveryFee),2)}</p>
                                  <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#dc2828', fontSize: 20, marginTop: 30}} >{selectedDs.name}</p>
                                </div>
                            </div> 
                          </div>  
                        </div>
                      </div>
                    }
                    {!showSms && !showText && showConfirm && alreadyBought && !showFinalCost && <div style={{marginTop: 10, marginLeft: 0, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <div style={{textDecoration: 'none', flexDirection: 'column', width: w - 5, justifyContent: 'center', alignItems: 'center', height: h * 0.8, overflow: 'auto',}} >
                          <div style={{marginTop: 20, width: w * 0.9, alignItems: 'center', marginLeft: w * 0.05, marginBottom: 30}}>
                            <div style={{flexDirection: 'column', alignItems: 'flex-start'}}>
                              <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: '#dc2828', fontSize: 40, height: 30}}>You saved</p>
                              <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: 'black', fontSize: 54, height: 54}}>₱{formatNumberWithComma(totalDiscount,2)}</p>
                              <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: '#dc2828', fontSize: 18}}>in your order</p>
                            </div> 
                            <div style={{flexDirection: 'column', alignItems: 'flex-start', marginTop: 60}}>
                              <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: '#dc2828', fontSize: 18, height: 14}}>Your Total Savings in RxClub:</p>
                              <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: '#404040', fontSize: 28, height: 28}}>₱{formatNumberWithComma(totalDiscount,2)}</p>
                            </div> 
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginTop: 60, width: w * 0.8, marginLeft: w * 0.1}}>
                              <p style={{textAlign: 'left', fontWeight: '500', color: '#dc2828', fontSize: 16, height: 24, width: w * 0.25}}>Name: </p>
                              <p style={{textAlign: 'left', fontWeight: '500', color: '#404040', fontSize: 16, marginLeft: 5, width: w * 0.55}}>{user.firstname} {user.lastname}</p>
                            </div> 
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', width: w * 0.8, marginLeft: w * 0.1}}>
                              <p style={{textAlign: 'left', fontWeight: '500', color: '#dc2828', fontSize: 16, height: 24, width: w * 0.25}}>Address:</p>
                              <p style={{textAlign: 'left', fontWeight: '500', color: '#404040', fontSize: 16, marginLeft: 5, width: w * 0.55}}>{user.address}</p>
                            </div>
                            {typeof user.Referror !== 'undefined' && user.Referror &&
                              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', width: w * 0.8, marginLeft: w * 0.1}}>
                                <p style={{textAlign: 'left', fontWeight: '500', color: '#dc2828', fontSize: 16, height: 24, width: w * 0.25}}>Referror:</p>
                                <p style={{textAlign: 'left', fontWeight: '500', color: '#404040', fontSize: 16, marginLeft: 5, width: w * 0.55}}>{user.Referror.firstname} {user.Referror.lastname}</p>
                              </div>
                            }
                          </div> 
                        </div>
                      </div>
                    }
                    {showSms && <>
                        <div style={{width: w * 0.95, minHeight: height * 0.4, alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                          <div style={{flexDirection: 'column', alignItems: 'flex-start', marginTop: 60}}>
                            <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: '#404040', fontSize: 18}}>Please wait for the text</p>
                            <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: '#404040', fontSize: 18}}>message giving you the</p>
                            <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: '#404040', fontSize: 18}}>link where you can track</p>
                            <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: '#404040', fontSize: 18}}>your order.</p>
                          </div> 
                          <div style={{flexDirection: 'column', alignItems: 'flex-start', marginTop: 20}}>
                            <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: '#404040', fontSize: 24, marginBottom: 10}}>Thank you!</p>
                          </div> 
                        </div> 
                      </>
                    }
                    {showActionData && actionData.length > 0 && <div style={{width: w - 5, height: height * 0.34, 
                      alignItems: 'center', justifyContent: 'center', flexDirection: 'column', overflowY: 'auto'}}>
                        {_renderActionData(true)}
                      </div>
                    }
                  </div>
                </div>
              </div>
              </>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', 
              justifyContent: 'flex-start', minWidth: 1100, maxWidth: 1100, marginTop: 0, height: 800, 
              border: 'solid 2px #dc2828' }}
            >
              <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', 
                justifyContent: 'center', minWidth: 1100, maxWidth: 1100, marginTop: 0, height: 150, backgroundColor: 'transparent' }}
              >
                <div style={{height: 180, width: w * 0.85, justifyContent: 'center', alignItems: 'center', backgroundColor: 'transparent', marginTop: 60, marginLeft: 30, marginRight: 20}}>
                  <div
                    style={{marginTop: 0, cursor: 'pointer', display: 'flex', width: w * 0.85, height: 130, 
                      backgroundColor: '#dc2828', borderRadius: 20, justifyContent: 'center', alignItems: 'center', flexDirection: 'column'
                    }}
                  >
                    <div style={{display: 'flex', flexDirection: 'row', width: w * 0.85, justifyContent: 'flex-start', alignItems: 'center', height: 60, marginTop: 0}}>
                      {user.avatarBig ? <img src={user.avatarBig} style={{width: 60, height: 60, marginLeft: 15}}/> : <div style={{width: 60, height: 60, marginLeft: 10}}/>}
                      <div style={{width: w * 0.55, height: 80, justifyContent: 'center', marginLeft: 15, backgroundColor: 'transparent'}}>
                        <p style={{textAlign: 'left', fontWeight: '500', color: 'white', fontSize: 14, width: w * 0.55, height: 15}} >{user.firstname} {user.lastname}</p>
                        <p style={{textAlign: 'left', fontWeight: '500', color: 'white', fontSize: 12, width: w * 0.55, height: 40, marginTop: 3}} >{user.address}</p>
                      </div>
                      <div style={{position: 'relative', marginLeft: -20, marginTop: -60}} onClick={() => {
                        setShowSearchMember(true)
                        setIsSearchReferror(false)
                      }}>
                        <img src={rxSearch} style={{width: 30, height: 30}}/>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', 
                    justifyContent: 'flex-start', width: 700, marginTop: 10
                  }}
                >
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', 
                      justifyContent: 'center', width: 700, overflowAnchor: 'auto' 
                    }}
                    key={level}
                  >
                    <p style={{fontSize: 20, color: '#404040'}}>{controlLabel}</p>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', 
                        justifyContent: 'flex-start', width: 700, height: 65, overflowAnchor: 'auto' 
                      }}
                      key={level}
                    >
                      {selectedData.map((item,index) => {
                          return <button
                            onClick={() => { 
                              if (item.id == 0) {
                                setLevel(level-1)
                                setScript([
                                  {
                                    id: 0,
                                    title: `Objective:`,
                                    text: ``
                                  },
                                  {
                                    id: 1,
                                    title: `Notes:`,
                                    text: ``
                                  }
                                ])
                                setActionData([]);
                                setShowActionData(false);
                                setControlLabel(level == 2 ? selectedLevel0Label : "CONCEPTS");
                                if (level == 2) {
                                  setSelectedLevel2Id(-1);
                                  setSelectedLevel2Label("")
                                }
                                else if (level == 1) {
                                  setSelectedLevel1Id(-1);
                                  setSelectedLevel1Label("")
                                }
                                else if (level == 0) {
                                  setSelectedLevel0Id(-1);
                                  setSelectedLevel0Label("")
                                }
                              }
                              else if (level == 0) {
                                setSelectedLevel0Id(item.id)
                                setSelectedLevel0Label(item.name)
                                setLevel(level + 1)
                                setScript([
                                  {
                                    id: 1,
                                    title: `Objective:`,
                                    text: item.script.objective
                                  },
                                  {
                                    id: 2,
                                    title: `Notes:`,
                                    text: item.script.notes
                                  }
                                ])
                                setControlLabel(`${item.name.toUpperCase()}`)
                              }
                              else if (level == 1) {
                                setSelectedLevel1Id(item.id)
                                setSelectedLevel1Label(item.name)
                                setLevel(level + 1)
                                setScript([
                                  {
                                    id: 1,
                                    title: `Objective:`,
                                    text: item.script.objective
                                  },
                                  {
                                    id: 2,
                                    title: `Notes:`,
                                    text: item.script.notes
                                  }
                                ])
                                setControlLabel(`${item.name.toUpperCase()}`)
                              }
                              else if (level == 2) {
                                setSelectedLevel2Id(item.id)
                                setSelectedLevel2Label(item.name)
                                setScript([
                                  {
                                    id: 1,
                                    title: `Objective:`,
                                    text: item.script.objective
                                  },
                                  {
                                    id: 2,
                                    title: `Notes:`,
                                    text: item.script.notes
                                  }
                                ])
                                setActionData(item.action);
                                setShowActionData(true);
                                setShowMayWe(false);
                              }
                            }}
                            style={{
                              padding: "0.5rem 0.75rem", width: 130,
                              color: "white", display: "flex",
                              alignItems: "center", justifyContent: "center",
                              height: 70, borderRadius: 25,
                              backgroundColor: (level == 2 && item.id == selectedLevel2Id) ? "#55b88a" : "#dc2828",
                              fontSize: 13.5, fontWeight: 'bold', 
                              border: 'solid 3px #dc2828',
                              marginRight: 10
                            }}
                          > 
                            {level == 0 ? item.name : item.id == 0 ? item.name :
                              <div className="new-line">
                                {`${item.code}\n${item.name}`}
                              </div>}
                          </button> 
                        }
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', 
                justifyContent: 'flex-start', minWidth: 1100, maxWidth: 1100, marginTop: 10, height: 0, 
                border: 'solid 1px #dc2828' }}
              />
              <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', 
                  justifyContent: 'center', minWidth: 1100, maxWidth: 1100, marginTop: 130, height: 70 
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', 
                    justifyContent: 'flex-start', minWidth: 735, maxWidth: 735, marginTop: 302, height: 630, 
                    overflowY: 'auto' 
                  }}
                >
                  {showSearchProduct && <div style={{backgroundColor: 'white', height: 630}}>
                      <div style={{height: 50, backgroundColor: 'transparent', width: 500, marginTop: 20, justifyContent: 'center'}}>
                        <form>
                          <input type="default" style={{width: '100%', fontSize: 16, textAlign: 'left'}} value={searchText} placeholder="Search"
                            onChange={(event) => onSearchChange(event.target.value)}
                          />
                        </form>   
                      </div>
                      <div style={{textDecoration: 'none', flexDirection: 'column', width: 500, height: h, justifyContent: 'center', alignItems: 'center'}} >
                        <div
                          style={{width: w, height: 620, backgroundColor: 'transparent', borderRadius: 20, justifyContent: 'flex-start', alignItems: 'center'}}
                        >
                          <div style={{display: "flex", width: 600, alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column'}}>
                            <div style={{marginTop: 20, width: 600, alignItems: 'center', marginLeft: w * 0.0, height: 400, overflowY: 'scroll'}}>
                                { products.map((item,index) => renderProductItem(item, index)) }
                                <div 
                                  style={{backgroundColor: 'transparent', width: w, alignItems: 'center', justifyContent: 'center', display: 'flex', marginTop: 0}}
                                >
                                </div>
                            </div>
                          </div>  
                        </div> 
                      </div>     
                    </div>
                  }
                  {enableSave && <div style={{backgroundColor: 'white', height: 630}}>
                      {_renderCurrentItem(currentItem, 0)}
                      <div style={{flexDirection: 'column', width: w, height: 200, backgroundColor: 'transparent', justifyContent: 'flex-start', 
                        alignItems: 'center', borderRadius: 20, marginTtop: -20}} 
                      >
                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', height: w * 0.85, width: w * 1.25, top: 0,
                          borderTopLeftRadius: 0, borderTopRightRadius: 0, overflow: 'hidden'}}
                        >
                          <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', 
                              justifyContent: 'center', marginTop: 40
                            }}
                          >
                            <button
                              onClick={() => {
                                setSelectedScriptIndex(2)
                                _onSaveButtonPress(user.id)
                              }}
                              style={{
                                padding: "0.5rem 0.75rem", width: 130,
                                color: "#404040", display: "flex",
                                alignItems: "center", justifyContent: "center",
                                height: 50, borderRadius: 25,
                                backgroundColor: "#ffcaca",
                                fontSize: 13.5, fontWeight: 'bold', 
                                border: 'solid 3px #dc2828'
                              }}
                            >
                              Save
                            </button> 
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                  {showSelectDelivery && <div className={classes.delFeeContainer}>
                      <div style={{display: 'flex', top: '50%', flexDirection: 'column', width: w * 0.8, height: 300, backgroundColor: 'white', justifyContent: 'center', 
                        alignItems: 'center', borderRadius: 20, top: 0, position: 'relative'}} 
                      >
                        <p style={{marginTop: 10, color: '#dc2828', fontSize: 18, width: w * 0.7, textAlign: 'center', fontWeight: '600'}} >Type of Delivery</p>
                        <div style={{top: 30, justifyContent: 'center'}}>
                          {deliveryTypes.map((rowData,index) => {
                            return <div key={rowData.id} style={{cursor: 'pointer', display: 'flex', flexDirection: 'column', width: w * 0.6, height: 80, justifyContent: 'center', alignItems: 'center'}} 
                            onClick={() => _onDeliveryTypePress(rowData)} 
                            >
                            <div
                              style={{display: 'flex', width: w * 0.6, height: 60, backgroundColor: '#ffcaca', borderRadius: 6, justifyContent: 'center', alignItems: 'center'}}
                            >
                              <div style={{display: 'flex', flexDirection: 'row'}}>
                                <p style={{textAlign: 'center', fontWeight: '500', color: '#dc2828', fontSize: 16, height: 12}} >{rowData.name}</p>
                                <p style={{textAlign: 'center', fontWeight: '500', color: '#404040', fontSize: 16, height: 12, marginLeft: 5}} >(₱{formatNumberWithComma(rowData.price, 2)})</p>
                              </div>
                              {rowData.isSelected && <img
                                  style={{width: 16, height: 16, position: 'absolute', marginLeft: '65%', marginBottom: '10%'}}
                                  src={CheckButton}
                                />
                              }
                            </div>
                            </div>
                          })}
                        </div>
                        <div style={{marginTop: 0}}>
                          <MyButton disabled={deliveryTypes.filter(item => item.isSelected).length == 0} label='Select' isLong={false} onPress={() => {
                            _onSelectDelFee()
                            setSelectedScriptIndex(5)
                          }}/>
                        </div>
                      </div>
                    </div>
                  } 
                  {showUpdateUserDetails && <div className={classes.cartContainer}>
                      <div style={{ width: '100%', alignItems: 'flex-start', justifyContent: 'center', minWidth: 350, maxWidth: 350, marginTop: '50px' }}>
                        <>
                        <div style={{marginTop: 30, marginLeft: 0, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                          <div style={{display: 'flex', flexDirection: 'row', width: w * 1.6, justifyContent: 'center', alignItems: 'center'}} >
                            <p style={{marginRight: 10, fontSize: 16, marginTop: 10, width: w * 0.25}}>First Name:</p>
                            <form onSubmit={(event) => event.preventDefault()}>
                              <input type="text" style={{width: w * 0.7, fontSize: 20, textAlign: 'left'}} value={user.firstname} placeholder="First Name"
                                onChange={(event) => setFirstName(event.target.value)} maxLength="20"
                              />
                            </form>
                          </div>
                          <div style={{display: 'flex', flexDirection: 'row', width: w, justifyContent: 'center', alignItems: 'center'}} >
                            <p style={{marginRight: 10, fontSize: 16, marginTop: 10, width: w * 0.25}}>Last Name:</p>
                            <form onSubmit={(event) => event.preventDefault()}>
                              <input type="text" style={{width: w * 0.7, fontSize: 20, textAlign: 'left'}} value={user.lastname} placeholder="Last Name"
                                onChange={(event) => setLastName(event.target.value)} maxLength="20"
                              />
                            </form>
                          </div>
                          <div style={{display: 'flex', flexDirection: 'row', width: w, justifyContent: 'center', alignItems: 'center'}} >
                            <p style={{marginRight: 10, fontSize: 16, marginTop: 10, width: w * 0.25}}>Phone #:</p>
                            <form onSubmit={(event) => event.preventDefault()}>
                              <input type="text" style={{width: w * 0.7, fontSize: 20, textAlign: 'left'}} value={user.phoneNumber} placeholder="Phone #"
                                onChange={(event) => setPhoneNumber(event.target.value)} maxLength="30"
                              />
                            </form>
                          </div>
                          <div style={{display: 'flex', flexDirection: 'row', width: w, justifyContent: 'center', alignItems: 'flex-start'}} >
                            <p style={{marginRight: 10, fontSize: 16, marginTop: 10, width: w * 0.25}}>Address:</p>
                            <form onSubmit={(event) => event.preventDefault()}>
                              <textarea type="textarea" style={{width: w * 0.7, fontSize: 20, textAlign: 'left', marginTop: 10, minHeight: 100, maxHeight: 150}} value={user.address} placeholder="Address"
                                onChange={(event) => setAddress(event.target.value)} maxLength="100" 
                              />
                            </form>
                          </div>
                          <div style={{display: 'flex', flexDirection: 'row', width: w, justifyContent: 'center', alignItems: 'center'}} >
                            <p style={{marginRight: 10, fontSize: 16, marginTop: 10, width: w * 0.25}}>SC/PWD ID #:</p>
                            <form onSubmit={(event) => event.preventDefault()}>
                              <input type="text" style={{width: w * 0.7, fontSize: 20, textAlign: 'left'}} value={user.scPwdId} placeholder="SC/PWD ID #"
                                onChange={(event) => setScPwdId(event.target.value)} maxLength="30"
                              />
                            </form>
                          </div>
                        </div>
                        <div 
                          style={{display: 'flex', backgroundColor: 'transparent', width: w, alignItems: 'center', justifyContent: 'center', cursor: 'pointer', marginTop: 50, flexDirection: 'row'}}
                        >
                          <MyButton label={`Save`} onPress={() => {
                              updateUser()
                            }}
                          />
                          <MyButton label={`Back`} onPress={() => {
                              updateUser()
                              setShowUpdateUserDetails(false);
                              setUpdatedUserDetails(true);
                              setShowMayWe(false);
                              setShowDiscount(false);
                              setShowUserDetails(true);
                              setShowText(false);
                              _onDrugstorePress(false);
                              setShowNewCart(false);  
                              setSelectedScriptIndex(7)
                            }}
                          />
                        </div>
                        </>
                      </div>
                    </div>
                  }
                  {showDiscount && <div className={classes.delFeeContainer}>
                      <div style={{display: 'flex', top: '50%', flexDirection: 'column', width: w * 0.8, height: 300, backgroundColor: 'white', justifyContent: 'center', 
                        alignItems: 'center', borderRadius: 20, top: 0, position: 'relative'}} 
                      >
                        <div style={{marginTop: 0}}>
                          <MyButton label='Back' isLong={false} onPress={() => {
                            setShowDiscount(false);
                            setShowFinalCost(true);
                            setSelectedScriptIndex(9)
                            _onSendSocket('Finalize', '', 'finalCart', null)
                          }}/>
                        </div>
                      </div>
                    </div>
                  } 
                  {showEditItem && <div className={classes.delFeeContainer}>
                      <div style={{display: 'flex', top: '50%', flexDirection: 'column', width: w * 0.8, height: 600, backgroundColor: 'white', justifyContent: 'center', 
                        alignItems: 'center', borderRadius: 20, top: 0, position: 'relative'}} 
                      >
                        <div style={{marginTop: 0, backgroundColor: 'white'}}>
                          { selectedSkus.map((item,index) => renderItemSwipeToDelete(item, index)) }
                          <div style={{flexDirection: 'column', width: w * 0.8, height: 200, backgroundColor: 'transparent', justifyContent: 'flex-start', 
                            alignItems: 'center', borderRadius: 20, marginTop: 50}} 
                          >
                            <div style={{ display: 'flex', flexDirection: 'row', width: w, alignItems: 'center', 
                                justifyContent: 'center', position: 'absolute', bottom: h * 0.38
                              }}
                            >
                              <button
                                onClick={() => {
                                  setShowEditItem(false)
                                  setSelectedScriptIndex(2)
                                  _onSaveButtonPress(user.id)
                                }}
                                style={{
                                  padding: "0.5rem 0.75rem", width: 130,
                                  color: "#404040", display: "flex",
                                  alignItems: "center", justifyContent: "center",
                                  height: 50, borderRadius: 25,
                                  backgroundColor: "#ffcaca",
                                  fontSize: 13.5, fontWeight: 'bold', 
                                  border: 'solid 3px #dc2828'
                                }}
                              >
                                Save
                              </button> 
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                  {/* {showCapture && <div className={classes.delFeeContainer}>
                      <div style={{display: 'flex', top: '50%', flexDirection: 'column', width: w * 0.8, height: 300, backgroundColor: 'white', justifyContent: 'center', 
                        alignItems: 'center', borderRadius: 20, top: 0, position: 'relative'}} 
                      >
                        <div style={{marginTop: 0}}>
                          <MyButton label='Capture' isLong={false} onPress={onStartCapture}/>
                        </div>
                      </div>
                    </div>
                  }  */}
                  {showScreenCapture && screenCapture && <div className={classes.delFeeContainer}>
                    <img src={screenCapture} style={{width: 710}}/>
                    </div>
                  }
                  {showActionData && actionData.length > 0 && <div className={classes.delFeeContainer}>
                      {_renderActionData(false)}
                    </div>
                  }
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', 
                  justifyContent: 'flex-start', minWidth: 0, maxWidth: 0, marginTop: 300, height: 630, 
                  border: 'solid 1px #dc2828' }}
                />
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', 
                  justifyContent: 'flex-start', minWidth: 360, maxWidth: 360, marginTop: 302, height: 630, 
                  overflowY: 'auto', overflowX: 'hidden' }}
                >
                  {/* <p style={{fontSize: 48, fontWeight: '500', color: '#dc2828', marginTop: 20}}>Script:</p> */}
                  {script.map((item,index) => _renderScriptItem(item,index))}
                </div>
              </div>
            </div>
          </div>
        <div style={{display: 'flex', position: 'absolute', top: 0, paddingLeft: 0, paddingRight: 0, backgroundColor: 'white', alignItems: 'center', justifyContent: 'flex-start', width: '100%'}}>
          <div style={{position: 'absolute', backgroundColor: 'transparent', bottom: 0, height: 30, width: '1500px', justifyContent: 'center', alignItems: 'center'}}>
            <p style={{color: '#dc2828', textAlign: 'center', fontSize: 24, fontWeight: '700'}}>MDClub</p>
          </div>
          <InnerPageHeader hasBackBtn goBackClick={() => _onBackPress()} title="" style={{position: 'absolute', left: 0}}
            // rightButtonLabel="INPUT"
            // onRightPress={() => navigate(routes.cartInput, { state: {userId} })}
          />
          <div style={{position: 'absolute', left: 50}} onClick={() => setIsRecordOn(!isRecordOn)}>
              <img className={isRecordOn ? 'blink' : ''} src={isRecordOn ? RecordOn : RecordOff} style={{width: 50, height: 50, marginTop: 10}}/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GetOrder;


// {!showSms && selectedSkus.map((item,index) => _renderItem({item,index}, w, h))}