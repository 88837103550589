import React, {useState} from 'react';
import useStyles from './styles';
import moment from "moment";

import { acceptMitApp } from '../../services/ApiService';

const DetailReceive = (props) => {
  const classes = useStyles();
  const [isAccepted, setIsAccepted] = useState(props.isAccepted);

  const onButtonClick = async () => {
    setIsAccepted(true)
    try {
      await acceptMitApp({mitappId: props.mitappId})
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <div className={classes.pageDetailContainer}>
      <p className={classes.staticLabel}>Upon completion, you will receive</p>
      <p className={classes.displayDetailData}>{props.prize}</p>
      <div className={classes.displayDetailImage}>
        <img className={classes.receiveImage} src={props.image} alt='invite' />
      </div>
      <p className={classes.displayDetailDate}>ends on {moment(props.expiryDate).format("MMM DD, yyyy")}</p>
      {isAccepted == 0 ? <button className={classes.displayDetailButton} onClick={onButtonClick}>ACCEPT</button> : <p className={classes.displayDetailText}>PENDING</p>}
    </div>
  );
};

export default DetailReceive;
