import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  scoreCounterContainer: {
    maxWidth: 250,
    minWidth: 170,
    // background: "#FAFAFA",
    border: "1px solid #13aec0",
    display: "flex",
    alignItems: "center",
    // borderRadius: 30,
    justifyContent: 'center'
  },
  scoreContainer: {
    // width: "100%",
    // maxWidth: "calc(100% - 64px)",
    // height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: 5,
    textAlign: "center",
    height: 30
    // paddingRight: 5,
  },
  scoreImage: {
    width: 32,
    height: 32,
    marginLeft: 1,
  },
  scoreLabel: {
    color: 'black',
    lineHeight: 1.3,
    fontWeight: 850,
    fontFamily: "canaroMedium",
    fontSize: 20,
    margin: 0,
    wordBreak: "break-word",
  },
}));
