import React, {useState} from 'react';
import useStyles from './styles';
// import { Pivot as Hamburger } from 'hamburger-react'
import Placeholder from '../../assets/images/common-images/avatarIcon.png';

const ProfileIcon = (props) => {
  const classes = useStyles();
  const [sidebarOpen, setSidebarOpen] = useState(props.sidebarOpen);

  const setSidebarOpenFunc = () => {
    setSidebarOpen(!sidebarOpen);
    props.setSidebarOpen(!sidebarOpen);
  }

  return (
    <div className={classes.profileContainer}>
      <img
        className={classes.profileImage}
        src={
          // 'https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500'
          props.avatar ? props.avatar : Placeholder
        }
        alt='Score'
        onClick={() => {
          if (typeof props.avatarClicked !== 'undefined') {
            props.avatarClicked()
          }
          else {
            props.setSidebarOpen(true)}
          }
        }
      />
      {props.notificationCount > 0 && <div className={classes.profileCounter}>{props.notificationCount}</div>}
      {/* <Hamburger color='white' size={40} toggled={sidebarOpen} toggle={setSidebarOpenFunc}/> */}
      {/* {!props.noCount && !sidebarOpen && <div className={classes.profileCounter}>3</div>} */}
    </div>
  );
};

export default ProfileIcon;
