import React from 'react';
import useStyles from './styles';
import ScoreCounter from '../ScoreCounter';

const PageDetail = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.pageDetailContainer}>
      <div className='float-left'>
        <img className={classes.inviteImage} src={props.avatar} alt='inite' />
      </div>
      <div className={classes.pageDetailInfoContainer}>          
        <div className={classes.inviteInfo}>
          <p>{props.description}</p><br/>
          <p>
            <i className="fa fa-clock fa-lg" aria-hidden="true" ></i>
            <span className={classes.timeValue}>{props.timeLeft}</span>
          </p>
        </div>
      </div>
      <div className={classes.scoreCounter}>
        <ScoreCounter balance={props.amount} isCrypto={props.isCrypto} cryptoImage={props.cryptoImage}/>
      </div>
    </div>
  );
};

export default PageDetail;
