import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  // homeContainer: {
  //   height: '100%',
  //   position: 'relative',
  //   display: 'flex',
  //   alignItems: 'center',
  //   [theme.breakpoints.down("xs")]: {
  //     width: "100%",
  //     height: "100%",
  //   },
  //   flexDirection: 'column'
  // },
  colcontainer: {
    height: '100%',
    maxWidth: 400, 
    minWidth: 400,
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "100%",
    },
    flexDirection: 'column'
  },
  detailContent: {
    width: '100%',
    height: '100%',
    objectFit: 'fill',
  },
  receiveImage: {
    width: 280,
    objectFit: 'contain'
  },
  displayDetailImage: {
    display: 'flex',
    justifyContent: 'center'
  },
  staticLabel: {
    marginTop: -30,
    color: '#404040',
    textAlign: 'center',
    fontSize: 18,
    fontFamily: 'CanaroBook'
  },
  divider: {
    backgroundColor: '#00acb8',
    width: '100%',
    height: 1,
    marginTop: 30
  },
  staticLabel2: {
    marginTop: 20,
    color: '#404040',
    textAlign: 'center',
    fontSize: 14,
    fontFamily: 'CanaroMedium'
  },
  address: {
    marginTop: 0,
    color: 'black',
    textAlign: 'center',
    fontSize: 18,
    fontFamily: 'CanaroBook',
    maxWidth: 280,
    wordWrap: 'break-word'
  },
  staticLabel3: {
    marginTop: 20,
    color: '#404040',
    textAlign: 'center',
    fontSize: 14,
    fontFamily: 'CanaroMedium'
  },
  link: {
    color: '#00599d',
    textDecorationLine: 'underline',
    textAlign: 'center',
    fontSize: 18,
    fontFamily: 'CanaroMedium',
    cursor: 'pointer'
  },
  claimed: {
    marginTop: -10,
    color: '#00599d',
    textAlign: 'center',
    fontSize: 14,
    fontFamily: 'CanaroBook',
  },
  staticLabel4: {
    marginTop: 0,
    color: '#00599d',
    textAlign: 'center',
    fontSize: 16,
    fontFamily: 'CanaroMedium'
  },
  description: {
    color: "black",
    fontWeight: '500',
    fontSize: 18,
    backgroundColor: "transparent",
    zIndex: 1,
    textAlign: 'center'
  },
}));
