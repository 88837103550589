import Notification from '../assets/images/sidebar-images/notification.png';
// import Boosts from '../assets/images/sidebar-images/boosts.png';
// import Broadcasts from '../assets/images/sidebar-images/broadcasts.png';
import Collection from '../assets/images/sidebar-images/collection.png';
import History from '../assets/images/sidebar-images/history.png';
import Lesson from '../assets/images/sidebar-images/lesson.png';
import Logout from '../assets/images/sidebar-images/logout.png';
import Points from '../assets/images/sidebar-images/points.png';
import Games from '../assets/images/sidebar-images/games.png';
// import SoundOn from '../assets/images/sidebar-images/sound_on.png';

export const sidebarMenu = [
  // {
  //   id: 7,
  //   label: 'Elrond Projects',
  //   icon: Lesson,
  // },
  // {
  //   id: 0,
  //   label: 'Notification',
  //   icon: Notification,
  // },
  // {
  //   id: 1,
  //   label: 'Boosts', 
  //   icon: Boosts,
  // },
  // {
  //   id: 2,
  //   label: 'Broadcasts', 
  //   icon: Broadcasts,
  // },
  // {
  //   id: 9,
  //   label: 'Points',
  //   icon: Points
  // },
  // {
  //   id: 4,
  //   label: 'Points', 
  //   icon: History,
  // },
  // {
  //   id: 10,
  //   label: 'Games',
  //   icon: Games
  // },
  // {
  //   id: 3,
  //   label: 'Earnings',
  //   icon: Collection,
  // },
  // {
  //   id: 5,
  //   label: 'Sound', 
  //   icon: SoundOn,
  // },
  // {
  //   id: 8,
  //   label: 'Transcribe', 
  //   icon: History,
  // },
  {
    id: 6,
    label: 'Logout', 
    icon: Logout,
  },
];
