import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import useStyles from './styles';
import useWindowDimensions from '../../../utils/windowDimention';
import Loader from "../../../Components/Common/Loader";
import InnerPageHeader from '../../../Components/InnerPageHeader';
import Placeholder from '../../../assets/images/common-images/user_placeholder.png';
import ReactTimeAgo from 'react-time-ago';
import {getPlaceHolder} from '../../../utils/utils';
// import Header from '../../../assets/images/header-images/Uminom_1.png';
import Monitor from '../../../assets/images/common-images/monitor.png'
import moment from 'moment';

import {
  getReseta
} from "../../../services/ApiService";
import { ids, routes } from '../../../constants';

let firstClientX = null;
let firstClientY = null;
let clientX = null;
let clientY = null;

const Delivery = ({ history}) => {
  const classes = useStyles();
  let navigate = useNavigate();
  const { height, width } = useWindowDimensions();
  const [selectedSlide, setSelectedSlide] = useState(0);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [timeId, setTimeId] = useState(1);
  const props = useLocation().state;

  const fetchData = async () => {
    try {
      setLoader(true);
      const response = await getReseta();
      // console.log(`response2=${JSON.stringify(response)}`)
      if (response) {
        setData(response.data.data.map(item => {
          return {
            ...item,
            isSelected: true
          }
        }));       
        setIsLoaded(true);
        setLoader(false);
      }

      // console.log("response", response);
    } catch (e) {
      setIsLoaded(true);
      setLoader(false);
      console.log(e);
    }
  };

  useEffect(() => {
    // fetchData();
    setData([
      { 
        id: 1,
        deliveryDate: new Date('2022-08-28T10:00:00'), //new Date(),
        details: [
          {
            id: 1,
            generic: {
              id: 1,
              name: 'Losartan'
            },
            dosage: {
              id: 1,
              name: '50mg'
            }
          },
          {
            id: 2,
            generic: {
              id: 1,
              name: 'Atorvastatin'
            },
            dosage: {
              id: 1,
              name: '20mg'
            }
          },
          // {
          //   id: 3,
          //   generic: {
          //     id: 1,
          //     name: 'Metformin'
          //   },
          //   dosage: {
          //     id: 1,
          //     name: '500mg'
          //   }
          // }
        ]
      }
    ])
  }, []);

  useEffect(() => {
    const disablePinchZoom = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault()
      }
    }
    document.addEventListener("touchmove", disablePinchZoom, { passive: false })
    return () => {
      document.removeEventListener("touchmove", disablePinchZoom)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('touchstart', touchStart);
    window.addEventListener('touchmove', preventTouch, { passive: false });
    return () => {
      window.removeEventListener('touchstart', touchStart);
      window.removeEventListener('touchmove', preventTouch, {
        passive: false,
      });
    };
  }, []);

  const touchStart = (e) => {
    firstClientX = e.touches[0].clientX;
    firstClientY = e.touches[0].clientY;
  };

  const preventTouch = (e) => {
    const minValue = 5; // threshold

    clientX = e.touches[0].clientX - firstClientX;
    clientY = e.touches[0].clientY - firstClientY;

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(clientX) > minValue) {
      e.preventDefault();
      e.returnValue = false;
      return false;
    }
  };

  const onMonitorPress = async () => {
    // const selected = data.filter(item => item.isSelected);
    // const param = selected.map((item) => {
    //   return {
    //     boughtDetailsId: item.id,
    //     umaga: timeId == 1 ? item.umaga : 0,
    //     tanghali: timeId == 2 ? item.tanghali : 0,
    //     gabi: timeId == 3 ? item.gabi : 0,
    //   }
    // })

    // await uploadTaken(param)
    
    // if (props.onBackPress) {
    //   props.onBackPress();
    // }
    // navigate(-1);

    navigate(routes.deliveryDetails, { state: {userData: props.userData, dashData: props.dashData} });
  }

  const onRowPress = (rowData) => {
    
  }
  
  return (
    <div className={classes.makatipidContainer}>
      {loader && <Loader />}
      <div className={classes.makatipidContainer}>
        <div style={{ width: '100%', alignItems: 'center', justifyContent: 'center', minWidth: 400, maxWidth: 400, marginTop: '50px' }}>
          
          <>
          <div style={{ display: "flex", backgroundColor: 'transparent', top: 30, alignItems: 'center', flexDirection: 'column' }}>
            <img src='https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1660892644173.png' style={{width: 375, height: 93, resizeMode: 'contain'}} />
          </div>
          <div style={{marginTop: 10, marginLeft: 50, minHeight: 200}}>
            {data.map((rowData,index) => {
              const w = 300;
              const h = 400;
              return <div key={rowData.id} style={{display: "flex", width: w, height: h, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center'}} >
                <div
                  style={{cursor: 'pointer', display: 'flex', marginTop: 30, width: w, height: h * 0.7, position: 'absolute', backgroundColor: rowData.isSelected ? '#B0E0E6' : '#B0E0E670', borderRadius: 6, justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}
                  onClick={() => onRowPress(rowData)} 
                >
                  <p style={{marginTop: 15, textAlign: 'center', fontWeight: '500', width: w * 0.9, color: '#00599d', fontSize: 28}} >{moment(rowData.deliveryDate).format("MMMM DD, YYYY HH:mma")}</p>
                  <div style={{top: 20, width: w * 0.9, alignItems: 'center'}}>
                      { rowData.details.map((item,index) => 
                        <p key={index} style={{textAlign: 'center', height: 20, fontWeight: '500', width: w * 0.9, color: 'black', fontSize: 22}}>{`${item.generic.name} ${item.dosage.name}`}</p>
                      ) }
                  </div>
                </div>
                <div onClick={() => onMonitorPress()} style={{position: 'absolute', bottom: 0, cursor: 'pointer'}} >
                  <img src={Monitor} style={{width: 150, height: 75, resizeMode: 'contain'}} />
                </div>
              </div>
            })}
          </div>
          </>
        </div>
        <div style={{position: 'absolute', top: 0, paddingLeft: 0, paddingRight: 0, backgroundColor: 'black', alignItems: 'center', width: '100%'}}>
          <InnerPageHeader hasBackBtn goBackClick={() => navigate(-1)} title="" style={{position: 'absolute'}}
            // rightButtonLabel="SEND"
            // onRightPress={() => navigate(routes.reseta, { state: {userData: props.userData, dashData: props.dashData} })}
          />
        </div>
      </div>
    </div>
  );
};

export default Delivery  ;