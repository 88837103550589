import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  detailJoinContainer: {
    color: 'white',
    backgroundImage: 'linear-gradient(#055258, #532C4C)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  detailJoinTitle : {
    padding: 20,
  },
  howtojoinImage: {
    borderRadius: '10%',
    marginBottom: 30,
    objectFit: 'contain'
  },
}));
