import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  homeContainer: {
    maxWidth: '480px',
    width: '100%',
    minHeight: '100vh',
    height: 'auto',
    position: 'relative',
    background: '#000',
    margin: '0 auto',
    maximumScale: 1
  },
  detailContent: {
    width: '100%',
    height: '100%',
    objectFit: 'fill',
  },
}));
