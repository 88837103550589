import React, { useEffect, useState } from "react";
import "./styles.css";
import { logout, useGetAccountInfo } from "@elrondnetwork/dapp-core";

import { checkWalletAddress, checkName, register, login } from "../../services/ApiService";
import { routes } from "../../constants";
import { useNavigate } from "react-router-dom";
import NoEffort from '../../assets/images/common-images/no_effort.png';
import { effortHelper } from "../../helpers/effortHelper";

import useStyles from './style';
import { checkNft } from "../../utils/utils";
import { nftHelper } from "../../helpers/nftHelper";

const MaiarPage = () => {
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState(false);
  const classes = useStyles();
  const [heHasNft, setHeHasNft] = useState(false);
  const ALLOWED_ADDRESSES = [
    //************** VGG
    "erd12uq7zj6lj6wqp7l9pasv60pad4f9mw73wnuzl6qhmj8szlearpxsfat0qv",
    "erd1zaravy98xy28ssf5cjujha2hsrs5vnwh50yh4vle2dqvxpn8krss38uf6f",
    "erd16u736c9uct8hrxstkc5jakj3t483neuj5pf933qj57u8crume2cq2qk3py",
    "erd1csa58uzfdk6fp20f80jmmhfkqafwfqz4n3ddulh5qfr3jm80zjxszg2lmf",
    "erd1pdxw9f9ah05zw80y9s7433ae6egl3a3z2srwenzpg0vaggzxhdyqy9svzx",
    "erd1kjvngst8ppp56f8094mj5tmvpk4g7dcyuzmxamjw5l8dsl4q3lwsckjkmw",
    "erd1q9lpjpde59qhaqc7npw5u2llqvy079rk2u8uwrxhw6udhm822g9qq6e4kd",
    "erd1kjvngst8ppp56f8094mj5tmvpk4g7dcyuzmxamjw5l8dsl4q3lwsckjkmw",
    "erd1kqep2j5ca3d8ke528ftycc6l9vl5q27ftc9vuhjy2nc2ur5r6crsgkh8dq",
    "erd1kqn45v4kn83dhdvn97u3ejmy78p995hvys6r3vypspxwggxtl3cq6mdhnd",
    "erd1543gyckcy0qh3gsgqdgn5fmezgeyhudpcsr3m7gf0en2m456r4zqdkq0kr",
    "erd1qu25t3jhcfjsnyd38c5cdmegkwdarx5sln0wvnx07rwdtl9s4xks0tcsya",
    "erd1vjmku0n8heks03907a0mgat9dgqrgu86q2mlchcelkzzly4g228sethe0a",
    "erd1qenvrzn03ddgtzq3g0qfq62l53gfjatcrldnn8p990h7x2994vlqrjt9pa",
    "erd1m3vekpwjunu9l3uyq79rztzw9eykqdgapk7sg9g5d65jfkm7s00qd46cpv",
    "erd16s4lhcacl4hwswz6cc0j0u7zm4v4x9rt84880xe8xafetv2ckpksc8605g",
    "erd1ncpqfsuxmcg77l564a0q3lf340u6g78zyv7feqszsc6ts7n4yhtsz4cdac",
    "erd1vc02vaekk5u2js7fd55puezff40qddh2gdquhwvdapa5ryzgvh2skn9kll",
    "erd14dqp4nscpyez869tghgz258qd5q2hy04ah2gt0fnvmy84cfzz4hqu0k7je",
    "erd195lymkylh5clg8ch7hk9sxlurxxvcxrz28443pma0vltua8zcd7qsafz5u",
    "erd1qjs3ggypf3ucp3vcedy30h5v2vrma2sf8ctfg9mh8ekgsthqs5mqy3zux4",
    "erd1n5jn8jvppmtuzwljsauxf9y7mwtdkg8u5jzgy4zksvduve7eyqtsu8lgyl",
    "erd1e5q2qhzpprrrcvn0u27azrcuut3zhh76cv7ejx59wlxudhvpzltscr0f9c",
    "erd1uz3sn65d6pgx36msm0xm9wc5kns2qgyhzknujxeqmakhk3at7aksmggcmp",
    "erd1ju2n7x680fktg0xd4fnqw557j03v9mpt4p6je4m75kc4g9kxta0s3nrzzz",
    "erd1z4huqn4n97nze0lqhfncd3vrqesynskeec6750cjhzmn28m4z7mqcynx0f",
    "erd1m74xssrhew2vfttj7em80lmlctgzwkpw8lfvf6zsus9ferh5x00q83jc4l",
    "erd15gup6d9pk32h98zr7fvrprrywpenprdte77pw6j7sum58xstdnrqy04edj",
    "erd1v3su0mfe7gwf94jgc9ma32y49vtp6fd9yltyx0qjt52r7hhq5e2sve2jpq",
    "erd1jf02yk4e608upm6jcfhpqq9s4u2j4dlv3d4dhkx2qhuxanfx2lyq7jddul",
    "erd1s2jdc232rrpadfem2ny9r9p5rhs5cktwpj76j0rp0ulev7tn044qjqfpve",
    "erd18q60gp9g4l23gpgjmr3egjmmu6hq9nsth5znv23euhycppcj27dqklms83",
    "erd13net7wul34u7rr8lmdz600hcpsg0xhzkqazmg295mdc0ustx45xsz5x7w5",
    "erd1543gyckcy0qh3gsgqdgn5fmezgeyhudpcsr3m7gf0en2m456r4zqdkq0kr",
    "erd1m74xssrhew2vfttj7em80lmlctgzwkpw8lfvf6zsus9ferh5x00q83jc4l",
    "erd1kqn45v4kn83dhdvn97u3ejmy78p995hvys6r3vypspxwggxtl3cq6mdhnd",
    "erd1vc02vaekk5u2js7fd55puezff40qddh2gdquhwvdapa5ryzgvh2skn9kll",
    "erd1qxpvr5vxemq5zzpygw7l54fm9xpefhejdf2lfpqca5f5t9jqa23qj9a6nm",
    "erd1ksstck6hghf7a788sxrpkvg6ffnzyuc2c5at9m64cd49c5y3yzfqr2aprt",
    "erd18mnjpljlruq9nztt566egkk0l3ydgjcr0eu6mgf2kjnqm4tmnz0salw2qw",
    "erd1vjmku0n8heks03907a0mgat9dgqrgu86q2mlchcelkzzly4g228sethe0a",
    "erd1yv3a38lqe8svl3clqcx44zeq6fztwal2lrhhr0gzyj44lxj4yvpquhsj8z",
    "erd172c9n2mry0ws74kldsxv4e9qqn7fdr7rdn0wxzajrtp2d6lxwwvqpdlarg",
    "erd1jnyk8f3dcq9ua6xtvfr6dyc7dz3xnyl6qpsltgr2fez28rlsuqmqx93jsn",
    "erd1qu25t3jhcfjsnyd38c5cdmegkwdarx5sln0wvnx07rwdtl9s4xks0tcsya",
    "erd1sjfhgjfxg068f6pn4un8v0exhhu8sd5takm66hqradvzr3ttz8qqtlzzqw",
    "erd1ncpqfsuxmcg77l564a0q3lf340u6g78zyv7feqszsc6ts7n4yhtsz4cdac",
    "erd14dqp4nscpyez869tghgz258qd5q2hy04ah2gt0fnvmy84cfzz4hqu0k7je",
    "erd1dtg4yywfg0jpy52h5qqua84jg4648kdvgppsf642nt5llgsjvlfs74cqmg",
    "erd1qaaghrat4shnvapedasdzt5740784s5fcgx8r76cgzsfg5j4388qtq7h5g",
    "erd1tgqqzjpnynnjdv3azv8xeyrq06u4hg92h999s9unw8x3ehu882pqq6w8cw",
    "erd160dn6vttje6hxsur4z72asd9fjq5hlpncmv0veunk4j4f3fcwcvqhpeqvn",
    "erd1qjs3ggypf3ucp3vcedy30h5v2vrma2sf8ctfg9mh8ekgsthqs5mqy3zux4",
    "erd1xnwj23gkwfgppkkjmkgl6v972jy2nt9expulu80khqgye03p8zcqc3x4ah",
    "erd195lymkylh5clg8ch7hk9sxlurxxvcxrz28443pma0vltua8zcd7qsafz5u",
    "erd14dqp4nscpyez869tghgz258qd5q2hy04ah2gt0fnvmy84cfzz4hqu0k7je",
    "erd1sp7pa7cy5ent2jcq97mqavkfahnujrqfyhawrge5vzktt3tv4pvq3cjhyu",
    "erd1u97jv389zknkzs7637wtf5uu0qe6tqsp2q98qwtrqwnm3wavz5aqdwgll8",
    "erd1836enz6jyxvjjj37uhqyrveqekfandx54zzp6nh0vrx6rpkeklpsmn903w",
    //********************
    //********* renzo, azer
    "erd1d43frkawmfl2hvhlrw9uklzqaw4u7ekdx0fmr0q6ctteq65tqrtstq6lm2",
    "erd1mxz0gs3hyj20jnr7wgxvntdud0gyklfge5zuf9htx0mr8xx0ugqq75qeld",
    //********* 
    //********* admin
    // "erd1fkeq6auzdgcvjmaf6h4446q5n2tkz8seh5xj6s5s8jnrhemwmqzqcvq0ul", // Mabel Canillo
    // "erd1q8gv9ydsrwxz8qlnwlhqcv56uqa3nms4rt3q346tmd6afz4lsfqs7wjxur", // Celine
    // "erd1sznrumy5vydjnyhgdcw5exn4hjs8fnaxw78gruw8e4avx3zhtgdsv56ztv", // Alennie
    // "erd1np3fnus2frdwe3gxjhrfz89qxhfdjvqsalsdge6wuq863cg77yas64lnkn", // jaQe
    // "erd1jf02yk4e608upm6jcfhpqq9s4u2j4dlv3d4dhkx2qhuxanfx2lyq7jddul", // Ron
    // "erd1kgdcnljqah80zvc3dnzsd8qluu5nvgkmxsp3wxy0hnady585hlkszjn5zf", // Feby
    //*********
    //********* bengga ph
    "erd14vvljj5x04772axflpcvwnq880shpvx0y07aa826majptsgz535skmjhjv",
    "erd1auva2rp0y8uqyjhfwcdl5v0k90qthvsmdkl256hugkqknlr0p06s9p2d4t",
    "erd1np3fnus2frdwe3gxjhrfz89qxhfdjvqsalsdge6wuq863cg77yas64lnkn",
    "erd1ekncrdtruszg3jrwplem66unnclda6ghe4dj62xmyr7xs02c79zss2tcuk",
    "erd1yr0zmsev0jef8ezvhx7m3w02lu6z4055p930e0s6qyjp5jq3fghqhmyskl",
    "erd14d87wvecvargdgyqap90urxvgqd243hx758k5km65gcjw5c9lruq9yhcqj",
    "erd19fwp78ren4z0x668vemugp8jk2d8sltmlsknvlws37qlzy6pea2sp3qzh9",
    "erd1fkeq6auzdgcvjmaf6h4446q5n2tkz8seh5xj6s5s8jnrhemwmqzqcvq0ul",
    "erd1hz79nesw90wstfh65qwnv02cnjagvcd6cuxvq99ntduc2twljhnsa393kw",
    "erd1xfqknmemeqng8d8j9v6m9fpkr7vcru2m6qgv5mw7k6vs0afdrvxsz9eryf",
    "erd1q6ljpedkw7sq6w64rdyw2zwythsj546dl4w67quh6x2h5r7rqyqqlnf528",
    "erd1xfqpf8ly5cxxx4lugscpsncjayjv9ssukc32j4h6e2h7pf5daqtqnj0vnf",
    "erd1gljh5g60dsdpl0t4sj9ek6f9ham8jt5ceg4kr3qanjs7txdgjhnsh0ay5l",
    "erd1hs4fra8e3k020r9mrtp4n65e7qtdz60s6e3mtwtvjnfdn8rnhe6saw4csx",
    "erd1mkxzudkpg6gqwl9tqvt84le4zzsdke9dytushz2ukh7kuuydvkssawywfp",
    "erd1mdhp5e0r3dr2fl59xws7p7dttvlz58rq3gff7qs42h8w664pgqqs4z4550",
    "erd1ac3scgkxk8styxqsay57xz0w7m5k3fvj97m0wdcsd57xn0ljzm7sc3pkhh",
    "erd1sznrumy5vydjnyhgdcw5exn4hjs8fnaxw78gruw8e4avx3zhtgdsv56ztv",
    "erd1f7w8rgxwzu9aa5uhz38p7l8w20w8q8s73296xlcwkf497pzzln3q4mdvfa",
    "erd184aftmk2828chamvu3684l79jhvp3075fuwn50rshd622xaxlshsmwcn0c",
    "erd18q60gp9g4l23gpgjmr3egjmmu6hq9nsth5znv23euhycppcj27dqklms83",
    "erd106gkekfkrxwt90pnatll2s39urv9p3g9phqh3j4mv8dzjke0772qv0nkks",
    "erd1h24cs588alk40uvn9v5m3590skrnsggh7l9lapshpu7a3s03hxsqnpvae6",
    "erd1jswxn5sgr99ej574wepyd36wzg05l0lnre2gfn92nqpnap3ecm4s3qxrnv",
    "erd1pggfqqa4wx4fmfdgr9jtu9xw0rn8zna45qxxhhzq2q4ca4cev7ksryqlp5",
    "erd1kp38tqlkkyynka0wcur86k3rkd32wyxujq8rwfzexwxjpvyy98eqhejcu0",
    "erd19djeuaywh6rmp7edxa62l8w38xpplk2t305hwg8am3rax3qyza9s3aw953",
    "erd1sp7y8dpmz3lvmumr8tnlpdsf2xve9gnk3uzd3t0k6ck7m9q9jw5s2evf5t",
    "erd19mqzpwh37ajxtadr45d699cyxz97qne4t0jjnt0x48y347p8wyrspwayrg",
    "erd17cadfypa5867zq4a8j77lt5xxr0dgfueh2uqq5q66p7cj4t9572qwk75yk",
    "erd167j8xs87de0k4y38qpy6vk5kcwve7ydz4qd69cl9hm36y97xcfnqepzm0y",
    "erd17dag9yq7m0gchy28qfu33jwrhtzgz8vuy4fj09ym5key294fdsjqklljud",
    "erd13mkwjej553km78l8x04urykalwx4gkwg50cw8trdgg9vx3qu9rzspljyj7",
    "erd1w968gktj047hydylfk4xz84zlnz6e0y4u6k37h5jpmcfk5agrflqk0pwlk",
    "erd1rvvzx7yrfcwsfyhdhhs9v4rh0m7696at5w9gkp045a7jz5u3d8cqct5u4d",
    "erd1sp7pa7cy5ent2jcq97mqavkfahnujrqfyhawrge5vzktt3tv4pvq3cjhyu",
    "erd15m3w6kg5dn67fcf66eltnl67eewwvr36f0zvcatyeu20dyj69ansc376c7",
    "erd1n2pny6rsc0gpa8am4u34yhcuhk06unqtxgz5sf8hmdstecclau5s398k3g",
    "erd16sta0w4u9eel7l4qse3x0ayqrusw5hj284srgr2pe52sc8aezrpsvq6na9",
    "erd1hy2j2qnhuaua07huchxagutmx66t4evtlefp848ytmwdevs873tshfu430",
    "erd1q8gv9ydsrwxz8qlnwlhqcv56uqa3nms4rt3q346tmd6afz4lsfqs7wjxur",
    "erd16pjmqjntm4dfuu4wuvzef30humddm5wh9qnwe645guah2ycacu9swhpjm7",
    "erd1trfpryzxvv8adxg39r7qp0z43h6vh8x9h7ydn64xqgukq2axe48s9scsr5",
    "erd1fyzul5wjwem6u2cu8ln6ctvnlznpz73csxmvz38yh8vytd3wmnnsmkjq9n",
    "erd1cffgrjhsg0fuxlyvk3edrqty9ruvyx08jezxa4ge5sn0vae78hzq87dgv0",
    "erd1xghphmp9lq5asvjhdjw7aptadeh7a6g72q8vhwjrlgsqzpyx025slyhwrq",
    "erd1szd4rmtu82ylvla784s4dyjrdjr3xrqkatfmqc7vkwyedwur8apqwr5ztn",
    "erd19rzx9apds48sq50knsdtx4m6wa6zc066r23hqp70rsq3e3j3z4js6hfanq",
    "erd1et7at8dgrls7ye2kv5sz07vq4v493m4532p4u6sekl548l3x9hlq0l8pna",
    "erd1m66acdzfxufz9uzqvhzm3j22mq7mmssk7656ap8jnalalxd8u5vqq3ewpj",
    "erd1cnx5hk005fmr2sv6j92vcwdpad456eptpfdeh0wy8kh5cdfecfhs5qe076",
    "erd1lvxruh7a0n3prryjzgrh8p57redgz3ej3e60v9znnynuxjsvtd8q5kmzcr",
    "erd1wpyvrxn399tc2h7z4j3wtqrdguw2j6su5g6enp3nxtcuf6yxd8xqdky63y",
    "erd1r4yqwa68ra44ftj9yyzsyka7qvs4k6fjvkc5veta8qzu5fz5c8gskpy9tx",
    "erd1qkzvmwpetyf8wjlh43drwd7hyx8ce29m3q73lmvayqnhumz5repsldqyfd",
    "erd1h5j7ysz9kfrmhmm5as02hm753v7awaf5lmluekj560zzs0u04xcs8eymsz",
    "erd1wvlnek6wqkmyfy8vwaxphmlzaazxqadvzj2vhpj54ftccak4hkwqk38yfg",
    "erd1jw3lg245fl845z9pe9hqnmyskceqstghpvxt54k2s2nc0vz6sfkqxhwjea",
    "erd1wl0533r3f0xq0097tammmmjfsp077w6h2nptqz7madepsp5544zs2feh0q",
    "erd1fzsndqsh0ftl03h4qvavhf6tfxu4smjdmlrrn3up48axr6durvvqd7hnuc",
    "erd1lmsrcz975gmvu9du4k6kmvvfmrq305gkcag6st4l8gy2fzwtegpqcsm9p5",
    "erd1jf02yk4e608upm6jcfhpqq9s4u2j4dlv3d4dhkx2qhuxanfx2lyq7jddul",
    "erd1u20jevmea4y9xyjsyvf9gw7cn73ccgvwzfjwq048fvhhw69f0ryqdw6809",
    "erd189aa4zlte8c6u3jn54vmqhvx4092puc6ga34jarn86m30rm3vy0qujwz02",
    "erd1uxg50zptjntq9n6jwhxmyly2mcsm4emt5parayuyjfavapzjanus9xwyw2",
    "erd1htnqcdvhu58aqrvl5mdu9rmnl2m0guwpdp397prx60jf243dh9zqguhasj",
    "erd1syz8mydxn3u2fwjvwm583lrpa6fua37dcj2h3gq50wuqcw8rwals7ppth4",
    "erd1kgdcnljqah80zvc3dnzsd8qluu5nvgkmxsp3wxy0hnady585hlkszjn5zf",
    "erd1fe4vgc2fd3qr0rg9jsdmsezj2cqtcxdfskndjxxgatzl563dew5qwjh5zd",
    "erd1jxses4m4mtkyp4rkkwzedxr3sgetaw98wycym7t39zpx8tfl9fushy9let",
    "erd1n76nls48ujy9259jcfduq23ndt58ck9x006mk7r2tenmtrx7sd8s5f7373",
    "erd1j3y8jqqxm376hyp6qvv2jpx400855egmwsdplqpyxej8fr35xzgsehwumx",
    "erd12ch0klg7p309dnddclwexd7589g0dvz52he7c3tweh0mtz49pa7q3ed4sh",
    "erd1zt7htyskmal870n2ugc4j2par3qrryqk9ek7jr2vmzlxqqpx5mwsmq7r2r",
    "erd1tahlvte4fwpkavxyxf4awf8ah5rrzqkvp05hh4kw0hs7ce8p4mmsll5jck",
    "erd179psnevej02xqxmuam9rd0w9zpvezrynrluv6m5tasxlm007vc6s2f0mp5",
    "erd14hz0fjh4jyh7whfdavk6lxc54arxtzwgzy0z80yzdhkys5zluufskltvej",
    "erd1f42vt7rs5vcwthy66jzntf46hkt7yges6e22304ddpwduu5vddlq7e48gm",
    "erd1fck7fx47n6sx82ah5dguyqjnxt4nx9s04wjck2ju2xlvj6gpefcqr4pylz",
    "erd1gu856d9jd80wdx52u3t33cz6wyc9ssqkqkpfd25u0yfkj5cqlxsspe7fzp",
    "erd14q8uuvv6sfzfuns9jxku4uuncuvuf6dlf3ms3k9tfgq3m7gxfahsdhlv07",
    "erd1rx4tdegh9shxft9nc5v9u50y8spfch8p60536ft6rpn9zujnhz7slj0sge",
    "erd1nfmejaetgmz85txtz9jdr5953u2ftg3u7sc6pst0w04vrl278jasfvu97j",
    "erd19cssm9h9nj5er2hfueec2vvcsdrz46fjghszjnh75lrty2vyt3fsszarh2",
    "erd15l4sf3fqpmny5xu9cj9zx39tgs8qzkfxakg6wfzem5t3r7hchgzqaw0xqn",
    "erd1h5tptem8xxcw6zgqnat8rm740anwf7tcseclvacrfkmdg0h5dqjs7xts8e",
    "erd16s4lhcacl4hwswz6cc0j0u7zm4v4x9rt84880xe8xafetv2ckpksc8605g",
    "erd1jnt8z2aycemym8m42cquxypxtqkg2yspujgv066p397rcfnyvy7sxe80r6",
    "erd1p5fx5ay6vg07vl28hujjmjhc2dak2tvpdkx93mfxvft9r06r2kysm203ry",
    "erd1gx6yw77d9d672qk2guzud275hpe909ur7smpg6yuyg8rp9fj8hqqm5kfax",
    "erd1tvgly4xmqef94ufey8ah5xxreuzz95q0q4c4klq9ytu059qsdgnqux0rqq",
    "erd1ca3azeampl0tkhnt3la42w425rk2r3emet5htf0ngpftylj69efsgx5gpd",
    "erd1qnzspdwxu0egp7ar8rr8s2elxwsalvyfcnhra6t0l0t5rcqp0taszwgnv3",
    "erd1tw98f33ltx7lxhlteazs8993gfavhuk9qnmlpdsql7pyr0vgl7csz6l9zx",
    "erd1puat899rnr5ugwev8cvst3ymr00qf7h04dtk4ajeac6ywymvkknsqerw2a",
    "erd1xkl4u83u3rplx55h83nlnqex4z90yzgelxsfs26684ku6m4eumxsjz7uw0",
    "erd1ddmkvdfeq6dz2pn45h25fepuj4c7vgndjva5kx3y5pkvkw4mwuwqae094z",
    "erd1csslwvuqnuyt7guf863ugzqmhrqftwu2mzw7alszq7adynz0qcgse290u2",
    "erd14jagwduyaasgrtcpuge408773c8tgyt7em3r5wqkn56u2p22urgq6yn5f5",
    "erd1gpfys0z5v2xnjjpess4qduwj7jch6ww0lves9f6hwkqlwpeawngqqhsn9s",
    "erd14caq8a8z0msafwvthkln9wftzazccku2kn8gf6pq5vf04wdfv2wsuwafzz",
    "erd1pwa4gmge5t0lsr6xeu7yv55jn8uk3ftef4cs6a44skmdc7kxq8vqfn47hc",
    //*********
    //********* 20220624 mae team
    "erd1a49qe096kjs90hum9cxr8hwxgn5xtxfgnnzw53k7kgury8drvl0q42hess",
    "erd1km6uc898fhc8jmxltjhx30pgxx0zkvtmycwuqp8qpzyn0ngxjdjsr3zsr3",
    "erd1hmrfkcvx4p7z89e9hc4e8cq2e6emxh9a6dja0r2eq5rg7mejawrs89tllm",
    //*********
    //********* bengga ph
    "erd1864g3xw806ghp56xsun5d77ukvegd5jjhv66xmpklkey824t7n0seu0kjj",
    "erd108mpxcymwnez5jpfg4ajxpdjlptkq435ydtk77x8x2y3ucw2zpgs5vs356",
    "erd1m2xe97cfkuamfa3rtpykqx0f76puv207scgasskh6pfujqywkazqxuvsxc",
    "erd1ws0j3c9kh8zjm5ppt30tt05r7p0v6qjkycpt9wnlfzh9ysfd4uzqzqg40h",
    "erd1n9stsr6d8qpxzcf3mtldszd7pxwk7namfptswa28r2qahe9qnkdslw4ded",
    "erd17euc67c7j5p02gjac54735lzm42cy803p3c2leztwukxu04mm8dsel63am",
    "erd1qywz9fe9j2tyzyd8fmwke7ep034jw50s96a3y45h3edq73ms66ls4p09s9",
    "erd15sdwt608p4ygr52a4rx430ydxwg2wyqqup9wh69cvuszj64d96sqv0z0n0",
    "erd1ypl5pmtg8krtcc6kumejf207yltghcyrjq574d4s4fyflu57qj8qhmguu7",
    "erd10dz4whga26jepd6wcjf8mhhye72k0me7r9ttax0f52c7ywlffucqakjd8h",
    "erd16l2exqy5c2mfzqw28ugwnaptp8xj9g7uf7mxzdetls8zqre3px8s8x9vhq",
    "erd1cyst9qlnhcux9zj8tqhfgnrtcqjl8n2v4a7xar3c5rxqxg47rwks5yyyy7",
    "erd10famf8d94uw7v8v5re49kjd482e6sxjt2xnw8t5q0sphzj47p3jqarsrhh",
    "erd1g2s8sch0n0nwfp9np8t8tv6s0p9ket6fqfwerhyp9thasd92a9jqgmy5ye",
    "erd13483cxewejt32uq2rl9drc586tt450vceg5k4242zc7n409sprxs6c405n",
    "erd1dpndvcgdg3nfxgelv5znrsghkwy7zqw53u23zrf9097axgumfjcs3ly0kk",
    "erd1hp8a9etfpeg55mujfqnr66fykht9sxvghgcex2fk76f8z5kve62qhu7czq",
    "erd1wwrxh70fp7kx8sm4fzlu9wdjllcr2jk2st4ztz9kf8t82a7ldvjq48gtt7",
    "erd1nx558mqauprr2g30vg8ck9sunapffxfvjl7yc3pzj3l89qj4pnmq848pzt",
    "erd1mpxtj43adky0pe2ak4c2jpjh9q0t0tst99sc4zut4qkr3gj2yqss255jzq",
    "erd1u009mlh7adueuzryjdxjkc8h3yatt2k2m7s6grf2srky50qwt68qfzqesq",
    "erd1lczcsx5mm9g3f93tf4g6zu0zv5swd6mfuweyu8plq2n70tr4t7fqjacqdj",
    "erd1tkuwyd0h7w6gxun8j9xf20tf7muxtsfzu3lmq4nn7656rn3u62gsfacu8n",
    "erd10swmlnqtkpul7sdm23pa9yrsa2q22ql3a0059tc48djhxwwut88sf2hyp3",
    "erd17c657q7ptdrm5hdjlkj8s6sq7zlwz4qhn0untvp6hn8svghyr0jqglsdg5",
    "erd1tpd7r3lphzjxzrn4mftz7lswrmuj5z7c8xud9w98rsqrhc79xhtskefpde",
    "erd1kvwvfpn3ncvt5dn8e0rnm8s7qv0u4yz3sl7dqcwuc0vq8533mevqnvvawh",
    "erd1x6rxn54w93cffy9eau2g8je0r3wc5l0ycjgq07xz0u70h9q9khmq2q2a3q",
    "erd14n48kzar9sqv8j5nnu99az2yz08lys2gr04zha2ua8dmymh65ykskay2j2",
    "erd1auf0r0r9mx4zxeclgqu2259cluxlt2n60z2afx2szvxk5l0amnsqsnr6qy",
    "erd15gylnezdvd9wf77flr4cs52cuxhpu8ugpvgg2ntrpulqmalc9ysqcyepyq",
    "erd122rumma0qly68cn7twdj646v49kqqqavsl2zwn3ypuaxpajmqdrspdlduj",
    "erd1ldkrr8v7p2ed7hld2zhg5p0pqryqfk5h87ppcujpzzk6a7yalqtqdwrg87",
    "erd12aafpum683r9yj2puqx8y8uju7fh0mtu5u7yccf9yq5j556uuexsrnc4d6",
    "erd1f8fxd0gxtjtv36um7s3j527477sxmv2xvmk39zyws7azzskjaapq5d2vkf",
    "erd13y32c5e084j0rjvxs2k6s4068te7j7fl5qu749v0eegvtjxg9rqqmpnlax",
    "erd1nezxj6rt8lzxd358cm6d6kv0p86xsnhyudr2hv83rxmy58p68u3q3hz8cl",
    "erd108jfvpy7gd03g4yqnc9tsryyu7zp45zy6305q8y89jztqghzlavsvsl2eu",
    "erd1hdn7ztjcky7jft5vc2lk4r3awzs9t0frg2wln336zxemw759qpuspavhth",
    "erd1mx7hlwejec4cd85878w53e84rzq737nf30yk324h96u0h7s56k9s8s7cc6",
    "erd1lykug8ta057lfl4sc7ugvyxw4u0zl45axkx9euk6ecvv3qcxmpxsy8azft",
    "erd1tngxwa2uzfsfs3rvv0nma3vhjh8wfkte79tlqxylqdxz9d5yzzasmgg9l2",
    "erd1nlpuxsjnm9z27tnyf0ylp2wlww7g5e5fgkda7umhp55qglxlyafs8j7rdm",
    "erd1m6z9fmjuum0sx344uhu3w90wruru5msa7rfqfs0rsptq7amn0qnq2dmrcw",
    "erd16vq9peym7eeya8849dghzl8w3ug6k9m58wgjvzrydugvlsz8ueys5ascxe",
    "erd1gvdzzfma4xg4an3pulzgw3grks7er4mv7jdd4fne40nl7gn5dhgs475m7m",
    "erd1hsjklzdehay8h5rhc9flmtg5a30uwsztvv39087htfx7mvr08pzskep6pn",
    "erd1vhrnu9ygvgvvx5w9gauhczvvfegnkfj29pvnhn3fjphznyyvv2uq4qlpvy",
    "erd1cyxjwm783eeg7pg0vzl4e9xze57fgzpm7dghwcmsn9ljunhac3eqpf3252",
    "erd1nvcgwlskezgv7tfa07tptq83zu077sxct7n4h9kswylsf95wfzzq3uek2v",
    "erd1795rwl98x5n5dm5f5czk7uwq3dp48xkfc3pnvy2n92m3a3x52dys2f5tde",
    "erd127fxah5xal2w88j8cq4tjf0mmc4svn807ardcxy8tjf7j8zswajsgg6k74",
    "erd1msz4cvjh07res3mqs2psdfmm0crfym7f7d2ygnky7khvjep9gguqpg9w5c",
    "erd17uvwevy4qa9wd8tcju05kzmzjqvnzd0w084sf7xjflnyvjusf0vqcqu227",
    "erd1eyvjvhagq79xgjszp6ktjf48czt3fjh4gqnkd06y73vcuz34lx9s9xcjqq",
    "erd12dlamupjynxjk79cjvc0k025le0hau740w433jxvqnlw7aw8q0lswqppnp",
    "erd13je3vdcuvez775r3s4zrtp6quf07vy6tta99z7rahpesjwlq3lns25s3zt",
    "erd1sh253efk8urwws87f9n0ca4g2gm8w6l3ehy4r5rz88mh344k6saspqmsh3",
    "erd1c0ugnu67x7ezapk8s8r6rg2e2stxzzmc3eac66aeaav4tph6tu3qldgkft",
    "erd1d0swk6f877j7245r4dwsycx4hvm02g0cu2m4qeule58q46mfn83sr5qh45",
    "erd1p2qa7n2ndau8vqm76wwxsesx9tvd78r5arqkmt57dknt3er4g5vqumrwma",
    "erd1pnpcs44kc8xx5sfuhka3wyx5h8gf75kypxg7rx7dd85ufhlgej3qrn6mll",
    "erd1rn3j66spxnftrxcdkx0vggqquyal573jd2vmga04wsvafa54qn2q4qxt9w",
    "erd1kgdcnljqah80zvc3dnzsd8qluu5nvgkmxsp3wxy0hnady585hlkszjn5zf",
    "erd1fe4vgc2fd3qr0rg9jsdmsezj2cqtcxdfskndjxxgatzl563dew5qwjh5zd",
    "erd1jxses4m4mtkyp4rkkwzedxr3sgetaw98wycym7t39zpx8tfl9fushy9let",
    "erd1n76nls48ujy9259jcfduq23ndt58ck9x006mk7r2tenmtrx7sd8s5f7373",
    "erd1j3y8jqqxm376hyp6qvv2jpx400855egmwsdplqpyxej8fr35xzgsehwumx",
    "erd12ch0klg7p309dnddclwexd7589g0dvz52he7c3tweh0mtz49pa7q3ed4sh",
    "erd1zt7htyskmal870n2ugc4j2par3qrryqk9ek7jr2vmzlxqqpx5mwsmq7r2r",
    "erd1tahlvte4fwpkavxyxf4awf8ah5rrzqkvp05hh4kw0hs7ce8p4mmsll5jck",
    "erd179psnevej02xqxmuam9rd0w9zpvezrynrluv6m5tasxlm007vc6s2f0mp5",
    "erd14hz0fjh4jyh7whfdavk6lxc54arxtzwgzy0z80yzdhkys5zluufskltvej",
    "erd1f42vt7rs5vcwthy66jzntf46hkt7yges6e22304ddpwduu5vddlq7e48gm",
    "erd1fck7fx47n6sx82ah5dguyqjnxt4nx9s04wjck2ju2xlvj6gpefcqr4pylz",
    "erd1gu856d9jd80wdx52u3t33cz6wyc9ssqkqkpfd25u0yfkj5cqlxsspe7fzp",
    "erd14q8uuvv6sfzfuns9jxku4uuncuvuf6dlf3ms3k9tfgq3m7gxfahsdhlv07",
    "erd1rx4tdegh9shxft9nc5v9u50y8spfch8p60536ft6rpn9zujnhz7slj0sge",
    "erd1nfmejaetgmz85txtz9jdr5953u2ftg3u7sc6pst0w04vrl278jasfvu97j",
    "erd19cssm9h9nj5er2hfueec2vvcsdrz46fjghszjnh75lrty2vyt3fsszarh2",
    "erd15l4sf3fqpmny5xu9cj9zx39tgs8qzkfxakg6wfzem5t3r7hchgzqaw0xqn",
    "erd1h5tptem8xxcw6zgqnat8rm740anwf7tcseclvacrfkmdg0h5dqjs7xts8e",
    "erd16s4lhcacl4hwswz6cc0j0u7zm4v4x9rt84880xe8xafetv2ckpksc8605g",
    "erd1jnt8z2aycemym8m42cquxypxtqkg2yspujgv066p397rcfnyvy7sxe80r6",
    "erd1p5fx5ay6vg07vl28hujjmjhc2dak2tvpdkx93mfxvft9r06r2kysm203ry",
    "erd1gx6yw77d9d672qk2guzud275hpe909ur7smpg6yuyg8rp9fj8hqqm5kfax",
    "erd1tvgly4xmqef94ufey8ah5xxreuzz95q0q4c4klq9ytu059qsdgnqux0rqq",
    "erd1ca3azeampl0tkhnt3la42w425rk2r3emet5htf0ngpftylj69efsgx5gpd",
    "erd1qnzspdwxu0egp7ar8rr8s2elxwsalvyfcnhra6t0l0t5rcqp0taszwgnv3",
    "erd1tw98f33ltx7lxhlteazs8993gfavhuk9qnmlpdsql7pyr0vgl7csz6l9zx",
    "erd1puat899rnr5ugwev8cvst3ymr00qf7h04dtk4ajeac6ywymvkknsqerw2a",
    "erd1xkl4u83u3rplx55h83nlnqex4z90yzgelxsfs26684ku6m4eumxsjz7uw0",
    "erd1ddmkvdfeq6dz2pn45h25fepuj4c7vgndjva5kx3y5pkvkw4mwuwqae094z",
    "erd1csslwvuqnuyt7guf863ugzqmhrqftwu2mzw7alszq7adynz0qcgse290u2",
    "erd14jagwduyaasgrtcpuge408773c8tgyt7em3r5wqkn56u2p22urgq6yn5f5",
    "erd1gpfys0z5v2xnjjpess4qduwj7jch6ww0lves9f6hwkqlwpeawngqqhsn9s",
    "erd14caq8a8z0msafwvthkln9wftzazccku2kn8gf6pq5vf04wdfv2wsuwafzz",
    "erd1pwa4gmge5t0lsr6xeu7yv55jn8uk3ftef4cs6a44skmdc7kxq8vqfn47hc",
    "erd1864g3xw806ghp56xsun5d77ukvegd5jjhv66xmpklkey824t7n0seu0kjj",
    "erd108mpxcymwnez5jpfg4ajxpdjlptkq435ydtk77x8x2y3ucw2zpgs5vs356",
    "erd1m2xe97cfkuamfa3rtpykqx0f76puv207scgasskh6pfujqywkazqxuvsxc",
    "erd1ws0j3c9kh8zjm5ppt30tt05r7p0v6qjkycpt9wnlfzh9ysfd4uzqzqg40h",
    "erd1n9stsr6d8qpxzcf3mtldszd7pxwk7namfptswa28r2qahe9qnkdslw4ded",
    "erd17euc67c7j5p02gjac54735lzm42cy803p3c2leztwukxu04mm8dsel63am",
    "erd1qywz9fe9j2tyzyd8fmwke7ep034jw50s96a3y45h3edq73ms66ls4p09s9",
    "erd15sdwt608p4ygr52a4rx430ydxwg2wyqqup9wh69cvuszj64d96sqv0z0n0",
    "erd1ypl5pmtg8krtcc6kumejf207yltghcyrjq574d4s4fyflu57qj8qhmguu7",
    "erd10dz4whga26jepd6wcjf8mhhye72k0me7r9ttax0f52c7ywlffucqakjd8h",
    "erd16l2exqy5c2mfzqw28ugwnaptp8xj9g7uf7mxzdetls8zqre3px8s8x9vhq",
    "erd1nssuv68swjerkuk9k50vhl6jmkgw0t82dq7rtdvwfzls4wq5eefqjnugzq",
    "erd1d53dj2ul6zgczztyyhhxuw32dyx9g8qw0rjgf5gfm0wz7542wwxqhtme4j",
    "erd1vr926n6svpqpwa5y3xapjvqjus5je3kkz5w3c229pm85vfd2rjvs7w7rnq",
    "erd1qamzrpf7mrye0l4vqk0u94xxtfy24wksupctmpuykya4p7vcuq6srszx5t",
    "erd1r0rckcv92494eqnzqrymfp47l2jmqch2usv8uue97xqr695tgmkqc8gzlh",
    "erd1zeg5us8w8kqey7emfzx87aydhatu99xuknn24speft66d28uz3vs8nelwq",
    "erd144y40ksmjupdl42c5zn6lm0y55v92h4xsrtxjpgcwpsrfe47mdas5y5lu8",
    "erd1rj27gcdx09fzer95dcr8trds344gxpkrasl55kzhpntvgzq5pyhsmdauze",
    "erd1434lyqrkzn70ylglxe80qjrpmq7u3v47zy6mpnnt3kx8xlvydvvsau8hwd",
    "erd13vxjjydhyuxvw5tvkyvgr9ht05wjjt25mrd4yd8wkna5gvzx4gmqwfrgdd",
    "erd19qpnkhhnrznapx7q5y36hpjegszm8ln02qx870rmk357suwuu9qqdhw6f9",
    "erd1yzraa90mj7tdxayvcnje04lu8rdq6tsnh4hy5zjhf5jslpk4gj9scg0q9v",
    "erd16rjtw5rrxcm2guxrt7fltspnssk50ltvvh48c967m8ghhchu6sdqgh8x0a",
    "erd1xajlvc9jty7j06n5ygjhkp3wfuye0k8q3ssntw06fce5zmfmh7ashln39y",
    "erd1jm4ggclf8h7leh47jd3dawkvwfklyyh3wuz0qr467ndf0m8zkqdqml9tt3",
    "erd1dpad60mw9st3hv8pqzhs5zf2ccsrrfwcrrgd07czql8zv94k085qz54p4p",
    "erd109kldhaaq0v8d5s8f6ukjgs9nn5yx294cmv6ck0f5a7utdf7jg4qge9x4p",
    "erd1etq669ups4xxvzm9wj6w3gzf9dnureqqergw8hpya2eznf39gszqe2azm2",
    "erd1dqhr4hus8rnn5q27mu67t94fdyy9q955789k5eshs7umyvgwy7kqpye320",
    "erd1av4vnenw5w249ze55au8sygkeu93f8jw9kz5rwqt00anq33h0f0q0y2656",
    "erd127dtqlj3u5kuermrq2htq4h26hwajv0pckgxp2e92034nuyevj7s8d3fkl",
    "erd1r6jcqjxqwascwjywrwrckyt5855wzl3y33j3pp8jxsgw5gxszknqz7yhh4",
    "erd1278xkel28hyy0wwyz2rtfmuydg0wuhm72hnxd7rv5c42rcfw3mhqanyqam",
    "erd1r63lc3k0rdzwt36pz9qjpvztxkxdhr6pwe6tyg95qgze4ghfuw6supej80",
    "erd1hy2j2qnhuaua07huchxagutmx66t4evtlefp848ytmwdevs873tshfu430",
    "erd1ftqad6zujz2q4twafthh3q6am4g8v4wsqr8dz66hd97t9mxlc5csmna98k",
    "erd1y86fv40x826fawwmca2y5c9plmlycttvfxnf7wrzgp5zn7ywqyjs3rvdgx",
    "erd1vcemsumhjyl0309ykthjvanpjr7dtd4zvzwt730s48ghymdxfsvssr8quw",
    "erd1d0swk6f877j7245r4dwsycx4hvm02g0cu2m4qeule58q46mfn83sr5qh45",
    "erd1f9482s7ple6w4szy8wvldkqpyjuxyjtsj62ag3yrlqrfv80ys7lst7g520",
    "erd1zrpszf5k7djrsgf8ktzzl09ju0xtzmgafu95n8r084vd6rmmywhs6q5see",
    "erd1p0wzkys98097t62v0cqhe0jkpe7m3xqafedh2tdxtch8ax7uk7dsgwxfyj",
    "erd1s6plzljd3lcy2e3zz8m4qgg54u29ccwz8usjrrpkqx4zu8kz4adsh8s0gg",
    "erd1zmd6a72xy7urxf2zrc2sg8y3sqreg3zk63wpfp8ejddf5jsdn3dqsad85m",
    "erd1e2mk4qg8e68elmn0l22v4dx6kafh88008lu9uxgg5fp73whglv9sa003d8",
    "erd1auf0r0r9mx4zxeclgqu2259cluxlt2n60z2afx2szvxk5l0amnsqsnr6qy",
    "erd1mtsja8m0trkp4030ajpuemdvvwd8tscxjmtrs95vpejjj99zm3as5t95wv",
    "erd1hs07ctzduwt4r97gzx3g4ncsr4txp4yjglek8tzwjqegrnhv3kmqcyus9f",
    "erd1vu83x95q2vxjm0xndcynzg3af2d3e2eqkq0setdh0e2segjxwd4szfugjn",
    "erd1hug44ggwg7rgds36rtv5qvlmlhmxtatssauedyfysarzzk4he5zs3k0ryy",
    "erd1lu6ga5rnft7c52slap8c3l45xv2ypaf4883usm2c7dxcm4g4dfcqw8adkx",
    "erd1l36ua6xl7657f65kks8catdne5360lr8nyrm0ery9f37xd79etzq7ac63k",
    //*********
    //********* sir noel
    "erd16etqd7aup5tamgshyuxd08awpmas2fphjfps45y424q5383euhcsu9gveh",
    //*********
  ]

  const loginHandler = async (oldId, apiKey, address, isNewUser) => {
    try {
      const response = await login({
        oldId,
        apiKey,
      });
      if (response) {
        // console.log("Login Response => ", response.data);
        localStorage.setItem(
          "token",
          JSON.stringify(response.headers.authorization)
        );
        localStorage.setItem("wefftWalletAddress", address);
        localStorage.setItem("footerIndex", 0);
        localStorage.setItem("itemIndex", 0);
        localStorage.setItem("oldId", oldId);
        localStorage.setItem("isNewUser", isNewUser ? "1" : "0");
        localStorage.setItem("autoJoin", "0");
        if (localStorage.getItem("token") && JSON.parse(localStorage.getItem("token"))) {
          navigate(routes.home);
        }
        else {
          // navigate(routes.login);
        }
      }
    } catch (e) {
      console.log(e);
      // navigate(routes.login);
    }
  };

  useEffect(async () => {
    const address = localStorage.getItem("maiarAddress");
    // console.log("Maiar Address", address);
    const creators = [ //check in homepage
      "erd1qf9et8d4swd09unnv0rdzezpkyxg5f2tk00tlr039nhjyz8xrm5qfj53ww", //elva
      "erd1jpr49da65vae0wjl73e3ghv443swxqaqvacu2f6uelvcnuxxw5jqktqsyh", //elrond lion
      "erd1qqqqqqqqqqqqqpgqeys5lt360l342m4dk2edehykktwn34djys5s46rk5f", //evoluzion
      "erd14edl4fvr8wc2sczrz5af6tfmfucgqzsh4u73hnxw96l07cekdg6schwtsh", //maiarpunks
      "erd1qqqqqqqqqqqqqpgqf6qde2f9dt8u943la9ehgvemzldx7rccxjhs3nqucu", //superrarebears
      "erd18e7t3fquqjm9rs6385tqu0pk543e43e44rlkrhtdzfd64rgu7jrq6jrza9", //whaley
      "erd1qqqqqqqqqqqqqpgqy2elehk25f6kp3zvf4wtd96sax6z78gqejeqq4l6n7", //Elrond Mutant Eheadz 
      "erd1qqqqqqqqqqqqqpgqy2jfag2jfhre8aem7kvz3ncrcq6ta52zys5s27cge2", //orcpunks
      "erd1qqqqqqqqqqqqqpgqfewmp7u7ea2p6pmu9sgv8hn8d3kgtje4ys5sjen2lu", //maka-bros
      "erd1qqqqqqqqqqqqqpgqw763s7ea3jksp6gr98kmj6psehtu3flndswsswu7hk", //mr ghost
    ];
    let hasNft = false;
    creators.every(async (creator) => {
      const data = {
        user_wallet: address,
        creator_wallet: creator,
      };
      const nft = await nftHelper(data);
      // console.log(`nft=${JSON.stringify(nft)}`)
      if (nft.length > 0) {
        hasNft = true;
        setHeHasNft(true);
        try {
          const response = await checkWalletAddress({walletAddress: address});
          if (response) {
            if (response.data.result) {
              loginHandler(response.data.data.oldId, response.data.data.apiKey, address, false)
              try {
                const response = await checkWalletAddress({walletAddress: address});
                if (response) {
                  if (response.data.result) {
                    loginHandler(response.data.data.oldId, response.data.data.apiKey, address, false)
                    return;
                  }
                  else {
                    const min = 4;
                    const max = 10;
                    const rand = min + Math.random() * (max - min);
                    let name = address.slice(address.length - rand, address.length)
                    const response1 = await checkName({displayName: name});
                    if (response1) {
                      if (response1.data.result) {
                        name = address.slice(address.length - rand - 1, address.length)
                      }
                      const response2 = await register({displayName: name, walletAddress: address});
                      if (response2) {
                        loginHandler(response2.data.data.oldId, response2.data.data.apiKey, address, true)
                      }
                      else {
                        console.log('3');
                        // navigate(routes.login);
                      }
                    }
                    else {
                      console.log('4');
                      // navigate(routes.login);
                    }
                  }
                }
                else {
                  console.log('5');
                  // navigate(routes.login);
                }
              } catch (e) {
                console.log(e);
                // navigate(routes.login);
              }
            }
            else {
              const min = 4;
              const max = 10;
              const rand = min + Math.random() * (max - min);
              let name = address.slice(address.length - rand, address.length)
              const response1 = await checkName({displayName: name});
              if (response1) {
                if (response1.data.result) {
                  name = address.slice(address.length - rand - 1, address.length)
                }
                const response2 = await register({displayName: name, walletAddress: address});
                if (response2) {
                  loginHandler(response2.data.data.oldId, response2.data.data.apiKey, address, true)
                  return;
                }
                else {
                  console.log('3');
                  // navigate(routes.login);
                }
              }
              else {
                console.log('4');
                // navigate(routes.login);
              }
            }
          }
          else {
            console.log('5');
            // navigate(routes.login);
          }
        } catch (e) {
          console.log(e);
          // navigate(routes.login);
        }
      }
      return true;
    });
    const EFFORT_CHECK = 0;
    const effort = await effortHelper({
      user_wallet: address,
    });
    // console.log(`effort=${effort}; hasNft=${hasNft}`)
    if (effort < Number(process.env.REACT_APP_MINIMUM_EFFORT) && !hasNft) {
      if (ALLOWED_ADDRESSES.filter((a) => a === address).length > 0) {
        trueLogin(address)
      }
      else {
        setShowDialog(true);
      }
    }
    else {
      if (localStorage.getItem("token") && JSON.parse(localStorage.getItem("token"))) {
        //do nothing
      }
      else {
        trueLogin(address);
      }
    }
  }, [])

  const trueLogin = async (address) => {
    try {
      const response = await checkWalletAddress({walletAddress: address});
      if (response) {
        if (response.data.result) {
          loginHandler(response.data.data.oldId, response.data.data.apiKey, address, false)
        }
        else {
          const min = 4;
          const max = 10;
          const rand = min + Math.random() * (max - min);
          let name = address.slice(address.length - rand, address.length)
          const response1 = await checkName({displayName: name});
          if (response1) {
            if (response1.data.result) {
              name = address.slice(address.length - rand - 1, address.length)
            }
            const response2 = await register({displayName: name, walletAddress: address});
            if (response2) {
              loginHandler(response2.data.data.oldId, response2.data.data.apiKey, address, true)
            }
            else {
              console.log('3');
              // navigate(routes.login);
            }
          }
          else {
            console.log('4');
            // navigate(routes.login);
          }
        }
      }
      else {
        console.log('5');
        // navigate(routes.login);
      }
    } catch (e) {
      console.log(e);
      // navigate(routes.login);
    }
  }

  const addressMaiar = localStorage.getItem("maiarAddress");
  console.log("Maiar Address", addressMaiar);
  const { address } = useGetAccountInfo();

  // useEffect(() => {
  //   if (address) {
  //     setTimeout(() => {
  //       logout(`${window.location.origin}/maiar`);
  //     }, 2000);
  //     // localStorage.removeItem("walletconnect");
  //   }
  // }, []);

  return (
    <>
      <div className="container">
        <div style={{ color: "#ffffff" }}>
          {/* <h2>Maiar Address</h2>
          {addressMaiar && addressMaiar} */}
        </div>
        { (showDialog && !heHasNft) && 
          <div className={classes.tutorialContainer} onClick={() => {navigate(routes.login)}}>
            <img src={NoEffort} className={classes.tutorialImage}/> 
          </div>
        }
      </div>
    </>
  );
};

export default MaiarPage;
