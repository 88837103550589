import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useStyles from "./styles";
import useWindowDimensions from "../../utils/windowDimention";
import Loader from "../../Components/Common/Loader";
import InnerPageHeader from "../../Components/InnerPageHeader";
import SoldOut from '../../assets/images/common-images/store_sold_out.png';
import { Grid } from "@mui/material";
import { ids, routes } from "../../constants";
import ScoreCounter from '../../Components/ScoreCounter';
import checkButton from '../../assets/images/common-images/checkIcon.png';

import { getStoreItems, getUserBalance } from "../../services/ApiService";
import { formatNumberWithComma } from "../../utils/utils";

let firstClientX = null;
let firstClientY = null;
let clientX = null;
let clientY = null;

const Store = ({ history }) => {
  const classes = useStyles();
  let navigate = useNavigate();
  const props = useLocation().state;
  const { height, width } = useWindowDimensions();
  const [selectedSlide, setSelectedSlide] = useState(0);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [balance, setBalance] = useState(0);
  const STORE_ID = 1;
  
  const fetchData = async () => {
    try {
      setLoader(true);
      const response = await getStoreItems({storeId: STORE_ID, storeCategoryId: ids.storeCategoryGC});
      // console.log(`response2=${JSON.stringify(response)}`)
      if (response) {
        setData(response.data.data);
        setLoader(false);
      }

      // console.log("response", response);
    } catch (e) {
      setLoader(false);
      console.log(e);
    }
  };

  useEffect(() => {
    fetchData();
    fetchGetBalance();
  }, []);

  const fetchGetBalance = async () => {
    try {
      const response = await getUserBalance();

      if (response) {
        // var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(response.data.data), process.env.REACT_APP_ENCRYPT_KEY).toString();
        localStorage.setItem(
          "balance",
          JSON.stringify(response.data.data)
        );
        setBalance(response.data.data.coinAmount);
        // setTickets(response.data.data.ticketQuantity);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    window.addEventListener("touchstart", touchStart);
    window.addEventListener("touchmove", preventTouch, { passive: false });
    return () => {
      window.removeEventListener("touchstart", touchStart);
      window.removeEventListener("touchmove", preventTouch, {
        passive: false,
      });
    };
  }, []);

  useEffect(() => {
    const disablePinchZoom = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault();
      }
    };
    document.addEventListener("touchmove", disablePinchZoom, {
      passive: false,
    });
    return () => {
      document.removeEventListener("touchmove", disablePinchZoom);
    };
  }, []);

  const touchStart = (e) => {
    firstClientX = e.touches[0].clientX;
    firstClientY = e.touches[0].clientY;
  };

  const preventTouch = (e) => {
    const minValue = 5; // threshold

    clientX = e.touches[0].clientX - firstClientX;
    clientY = e.touches[0].clientY - firstClientY;

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(clientX) > minValue) {
      e.preventDefault();
      e.returnValue = false;
      return false;
    }
  };

  const onImageClick = (item) => {
    navigate(routes.storeDetails, { state: { storeItemData: item, balance, storeId: STORE_ID } });
  };

  return (
    <div className={classes.homeContainer}>
      {loader && <Loader />}
      <div className={classes.homeContainer}>
        <div
          style={{
            minWidth: 400,
            justifyContent: "center",
            maxWidth: 400,
            marginTop: 50,
          }}
        >
          <Grid
            container
            style={{ marginLeft: 0, maxWidth: 400 }}
            rowSpacing={0}
            columnSpacing={0}
            columns={{ xs: 2}}
          >
            {data.length > 0 &&
              data.map((item, index) => (
                <Grid item xs={1} key={index}>
                  <div className={index % 2 == 0 ? classes.itemLeftContainer : classes.itemRightContainer}>
                    <p className={classes.amount}>{formatNumberWithComma(item.bmValue, 0)} Points</p>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                      <img
                        src={item.imageBig}
                        className={classes.image}
                        onClick={() => onImageClick(item)}
                      />
                      {item.isBought == 1 && <img
                          style={{width: 40, height: 40, position: 'absolute'}}
                          src={checkButton}
                          onClick={() => onImageClick(item)}
                        />
                      }
                    </div>
                    <p className={classes.prodName}>{item.name}</p>
                  </div>
                </Grid>
              ))}
          </Grid>
          {/* {data.length > 0 && data.map((item,index) => {
              return (<div className={classes.historyContainer} key={item.lastModifiedDate} >
                <img src={item.playerAvatar ? item.playerAvatar : Placeholder} className={classes.profileImage} />
              </div>)}
            )
            } */}
        </div>
        <div
          style={{
            position: "absolute",
            top: 0,
            paddingLeft: 0,
            paddingRight: 0,
            backgroundColor: "black",
            alignItems: "center",
            width: "100%",
          }}
        >
          <InnerPageHeader
            hasBackBtn
            goBackClick={() => navigate(-1)}
            title={``}
            style={{ position: "absolute" }}
          />
          <div className={classes.balanceContainer}>
            <ScoreCounter balance={balance}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Store;
