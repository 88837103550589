import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  headerContainer: {
    backgroundColor: '#b0e0e6',
    position: 'absolute',    
    boxSizing: 'border-box',
    width: '100%',    
    zIndex: 1,
    marginTop: 40
  },
  ticketProfileContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 'auto',
    marginBottom: 'auto',
    width: 140,
    justifyContent: 'space-between',
  },
}));
